import { withRouter } from "react-router-dom"
import React, {useGlobal, useState,useEffect} from "reactn"
import PageLayout from "../../../components/page-layout/page-layout.component"
import Button from "../../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import { Row,Col } from "react-bootstrap";
import AdvancedButton from "../../../components/advanced-button/advanced-button.component";
import queryString from "query-string"
import SelectSubjectsTab from "./tabs/select-subjects.tab";
import CheckoutTab from "./tabs/checkout.tab";
import Icons from "../../../assets/icons";

const ProgramPage =(props)=>{
    const [currentUser] = useGlobal("currentUser");
    const [programs]=useGlobal("programs")
    const [,setCurrentProgram]=useGlobal("currentProgram")
    const params=queryString.parse(window.location?.search)
    const tag=props.match.params.tag
    const [program,setProgram]=useState(null)
    const [,setMxx]=useGlobal("mxx")
    const [,setDxx]=useGlobal("dxx")
    
    const [state, setState] = useState({
        query: "",
        status: "",
        tab:"",
        subject: null,
        mediaFolder:""
    });
    

    useEffect(() => {
        setState({...state,tab: params?.tab || ""})
        let program=programs?.find((p)=>(p.tag== tag))
        if(!program)props.history.push(`/home`)
        setProgram(program)
    }, [window.location.search,props.history,tag])

    const { data: sittingData, error: sittingError}=useSWR(program?.id &&
        `/tuition/sitting/student?programID=${program?.id}&active=false`,{
        errorRetryCount:0,
        onError:(error)=>{
            console.log(error?.message)
        }
    })

    const studentSitting=sittingData?.studentSitting
    
    const [subject,setSubject]=useState(null)

    useEffect(()=>{
        if(studentSitting){
            const  subjects =  []
            if(studentSitting?.subjects) subjects.push(...studentSitting?.subjects)
            if(studentSitting?.intervention?.subjects) subjects.push(...studentSitting?.intervention?.subjects)
            
            const sub= subjects?.find((s)=>(s.subject.id == params?.subjectID))
            setSubject(sub)
        }
       
    },[window.location.search,props.history, studentSitting?.id , params?.note, params?.subject])
    
    const { data: documentsData, error: documentsError, isValidating: validatingDocument}=useSWR(
        (studentSitting?.id && params?.subjectID && params?.note) &&
        `/tuition/document?subjectID=${params?.subjectID}&documentFolder=${params?.note}`)

    const canFecthMedia=()=>(studentSitting?.id && params?.subjectID && params?.mediaType  && params?.mediaFolder)
    
    const { data: mediaData, error: mediaError, isValidating: validatingMedia}=useSWR(
        canFecthMedia() &&
        `/tuition/media?subjectID=${params?.subjectID}&mediaType=${params?.mediaType}&mediaFolder=${params?.mediaFolder}`)
    
       
    return(
        <PageLayout activeLink="programs"
        parentMenu="programs"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}>
            
           {(program)  &&  <div>
                <div style={{maxWidth: "620px"}}>
                    {!state.tab &&<div>
                    <div className="text-30 font-500">{program.name}</div>
                    <div className="text-20 text-grey mb-20">{program.description}</div>
                    {(!sittingData) && <LoaderPane loading={!sittingData && !sittingError}/>}
                    {(sittingData || sittingError) &&<div className="row">
                        <Col md={12}>
                            <AdvancedButton title="Tuition"
                             onClick={()=>{
                                 if(sittingError){
                                     setCurrentProgram(program)
                                     props.history.push(`/program-registration/${program.tag}`)
                                }else{
                                    props.history.push(`/programs/${program.tag}?tab=tuition`)
                                }
                                 
                             }} 
                            />
                            <AdvancedButton title="Intervention" 
                                onClick={()=>{
                                    if(studentSitting?.intervention){
                                        props.history.push(`/programs/${program.tag}?tab=intervention`)
                                    }else{
                                        //register for intervention
                                        setCurrentProgram(program)
                                        props.history.push(`/program-registration/${program.tag}?isIntervention=true&tab=subjects`)
                                    }
                                    
                                }} 
                                disabled={sittingError}
                            />
                            <AdvancedButton title="Bookstore" onClick={()=>props.history.push(`/book-store?program=${program.tag}`)}/>
                        </Col>
                    </div>}
                    </div>
                    }

                    {(state?.tab==="tuition" || state?.tab==="intervention") &&<div>
                    <div className="text-30 font-500">{program.tag} {state?.tab}</div>
                    <div className="text-20 text-grey mb-20">Select a subject to begin studying what you need</div>
                    <div className="row">
                        {state?.tab==="tuition" &&<Col md={12}>
                            {
                                studentSitting?.subjects?.map((s,i)=>(
                                    <AdvancedButton title={s.subject.name} key={i}
                                     onClick={()=>{
                                        props.history.push(`/programs/${program.tag}?tab=resources&subject=${s.subject.name}&subjectID=${s.subject?.id}`)
                                    }}/>)
                                )
                            }
                        </Col>}
                        {state?.tab==="intervention" && <Col md={12}> 
                            {
                                studentSitting?.intervention?.subjects?.map((s,i)=>(
                                    <AdvancedButton title={s.subject.name} key={i}
                                     onClick={()=>{
                                        props.history.push(`/programs/${program.tag}?tab=resources&subject=${s.subject.name}&subjectID=${s.subject?.id}`)
                                    }}/>)
                                )
                            }
                        </Col>}
                    </div>
                    </div>
                    }

                    {state?.tab==="resources" &&<div>
                        <div className="text-30 font-500">{params?.subject}</div>
                        <div className="text-20 text-grey mb-20">Select studying material to begin studying what you <br/> need</div>
                        <div className="row">
                            <Col md={12}>
                                <AdvancedButton title="Lecture notes" onClick={()=>props.history.push(`/programs/${program.tag}?tab=lecture&subject=${params.subject}&subjectID=${params?.subjectID}`)}/>
                                <AdvancedButton title="Media" onClick={()=>props.history.push(`/programs/${program.tag}?tab=media&subject=${params.subject}&subjectID=${params?.subjectID}`)}/>
                            </Col>
                        </div>
                    </div>}

                    {state.tab === "media" &&<div>
                        <div className="text-30 font-500">Media</div>
                        <div className="text-20 text-grey mb-20">Select material to begin studying what you need</div>
                        <div className="row">
                            <Col md={12}>
                                <AdvancedButton title="Audios" onClick={()=>props.history.push(`/programs/${program.tag}?tab=audios&subject=${params.subject}&subjectID=${params?.subjectID}&mediaType=Audio&mediaFolder=Lecture Audios`)}/>
                                <AdvancedButton title="Videos" 
                                    onClick={()=>{
                                        setState({...state, mediaType: state.mediaType=== "Videos" ?"":"Videos"})
                                       // props.history.push(`/programs/${program.tag}?tab=videos&subject=${params.subject}&subjectID=${params?.subjectID}&mediaType=Video`)
                                    }}
                                />
                                <div style={{marginLeft:"20px"}}>
                                {state.mediaType==="Videos" && <div>
                                    <AdvancedButton hideRightButton title="Lecture Videos" onClick={()=>props.history.push(`/programs/${program.tag}?tab=videos&subject=${params.subject}&subjectID=${params?.subjectID}&mediaType=Video&mediaFolder=Lecture Videos`)}/>
                                    <AdvancedButton hideRightButton title="Revision Videos" onClick={()=>props.history.push(`/programs/${program.tag}?tab=videos&subject=${params.subject}&subjectID=${params?.subjectID}&mediaType=Video&mediaFolder=Revision Videos`)}/>
                                    </div>}
                                </div>
                            </Col>
                        </div>
                    </div>}

                    {state.tab === "videos" &&<div>
                        <div className="text-30 font-500">{params?.subject} Videos</div>
                        <div className="text-20 text-grey mb-20">Select preferred lecture video to start watching</div>
                        <LoaderPane loading={false} 
                            noRecordsMessage={"No videos"} 
                            noRecords={mediaData && mediaData?.medias?.length===0}
                            error={!mediaData && mediaError?.message}
                        />
                        <div className="row">
                            <Col md={12}>
                                {
                                    mediaData?.medias?.map((m,i)=>(
                                    <AdvancedButton key={i} title={`${m.description}`} titleBold leftIcon="Video" hideRightButton
                                    onClick={()=>{
                                        setMxx(Buffer.from(m.mediaURL).toString('base64'))
                                        props.history.push(`/media?type=video&title=${m.subject.name}&description=${m.description}`)
                                    }}/>))
                                }
                            </Col>
                        </div>
                    </div>}

                    {state.tab === "audios" &&<div>
                        <div className="text-30 font-500">{params?.subject} Audios</div>
                        <div className="text-20 text-grey mb-20">Select preferred lecture audio to start watching</div>
                        <LoaderPane loading={!mediaData && !mediaError} 
                            noRecordsMessage={"No audio files"} 
                            noRecords={mediaData && mediaData?.medias?.length===0}
                            error={!mediaData && mediaError?.message}
                        />
                        <div className="row">
                            <Col md={12}>
                                {
                                    mediaData?.medias?.map((m,i)=>(
                                    <AdvancedButton key={i} title={`${m.description}`} titleBold leftIcon="Audio" hideRightButton
                                    onClick={()=>{
                                        setMxx(Buffer.from(m.mediaURL).toString('base64'))
                                        props.history.push(`/media?type=audio&title=${m.subject.name}&description=${m.description}`) }} 
                                    />))
                                    
                                }
                            </Col>
                        </div>
                    </div>}

                    {state.tab === "lecture" &&<div>
                        <div className="text-30 font-500">{program?.tag} Lecture notes</div>
                        <div className="text-20 text-grey mb-20">Select preferred lecture note to start reading</div>
                        <div className="row">
                        <Col md={12}>
                                <AdvancedButton title="Summary Notes" onClick={()=>props.history.push(`/programs/${program.tag}?tab=notes&subject=${params.subject}&note=Summary Notes&subjectID=${params?.subjectID}`)}/>
                                <AdvancedButton title="Special Notes" onClick={()=>props.history.push(`/programs/${program.tag}?tab=notes&subject=${params.subject}&note=Special Notes&subjectID=${params?.subjectID}`)}/>
                                <AdvancedButton title="Questions Bank" onClick={()=>props.history.push(`/programs/${program.tag}?tab=notes&subject=${params.subject}&note=Questions Bank&subjectID=${params?.subjectID}`)}/>
                                <AdvancedButton title={`${program?.tag} Past Questions`} onClick={()=>props.history.push(`/programs/${program.tag}?tab=notes&subject=${params.subject}&note=${program.tag} Past Questions&subjectID=${params?.subjectID}`)}/>
                            </Col>
                        </div>
                    </div>}

                    {state.tab === "notes" &&<div>
                        <div className="text-30 font-500">{params?.note} </div>
                        <div className="text-20 text-grey mb-20">Select preferred lecture note to start reading</div>
                        <div className="row">
                            <LoaderPane loading={(!documentsData && !documentsError)}
                              noRecordsMessage={`No notes for [${params?.note}]`}
                              noRecords={!documentsError && documentsData?.documents?.length===0}
                              error={!documentsData && documentsError?.message}
                            />
                            {
                                documentsData?.documents?.map((d,i)=>(
                                    <AdvancedButton key={i} title={d.description} titleBold leftIcon="File" hideRightButton
                                    onClick={()=>{
                                        setDxx(Buffer.from(d?.documentURL).toString('base64'))
                                        props.history.push(`/document?type=${d.documentType}&title=${d.subject.name}&description=${d.description}`)
                                    }}/>
                                ))
                            }
                        </div>
                    </div>}
                </div>

                {state?.tab==="select-subjects"&& <SelectSubjectsTab onBack={()=>{window.location.reload()}} onNext={()=>setState({...state,tab:"checkout"})}/>}
                
                {state?.tab==="checkout"&& <CheckoutTab program={program} onBack={()=>window.location.reload()} 
                onSuccess={()=>setState({...state,tab: params.tab})}
                onMakeChanges={()=>{}}
                onSuccessClose={()=>setState({...state,tab: params.tab})}/>}
            </div>}
        </PageLayout>
    )
}


export default withRouter(ProgramPage)