import { useState, useGlobal } from "reactn";
import "./sidebar.styles.scss";
import { withRouter } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/icons/phone.svg";
//import { CanReadOrWrite } from "../../access-control.jsx";
import { ReactComponent as StocksIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg";
import { ReactComponent as PaymentIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as InvoiceIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as EmployeesIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as BriefcaseIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as SalesIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as MainLogo } from "../../assets/icons/main-logo.svg";
import Icons from "../../assets/icons";
import useAccessLevels from "../../hooks/useAccessLevels";
const Sidebar = (props) => {
  const [active, setActive] = useState(props.active);
  const [activeMenu, setActiveMenu] = useState(props.parentMenu);
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [programs] = useGlobal("programs");

  const { accessLevels, hasAccess } = useAccessLevels(
    currentUser?.user?.accessLevels
  );

  return (
    <div
      className={`sidebar ${props.toggle} scrollbar`}
      onContextMenu={(e) => {
        if (process.env.NODE_ENV === "production") {
          e.preventDefault();
        }
      }}
    >
      <div className="side-top">
        <div className="d-flex align-items-center justify-content-between mt-40">
          <div
            className="logo pointer"
            onClick={() => props.history.push(`/home`)}
          >
            <MainLogo />
          </div>
          <div
            className="close-menu pointer align-items-center "
            onClick={() => props?.onToggle()}
          >
            <div>
              <Icons.Chevron variant="active" />
            </div>
          </div>
        </div>
      </div>
      <div className="side-middle">
        <div
          className={`side-nav-tab ${
            props.active === "home" || activeMenu === "home"
          }`}
          onClick={() => {
            props.history.push(`/home`);
          }}
        >
          <span className="side-nav-icon">
            <Icons.Home
              variant={props.active === "home" ? "active" : "default"}
            />
          </span>
          <span>Home</span>
          <span className="dropdown-icon"> {/*  <ChevronDown /> */}</span>
        </div>
        <div hidden={currentUser?.user?.isSuperUser}>
          <div
            className={`side-nav-tab ${props.parentMenu === "programs"}`}
            onClick={() => {
              setActiveMenu(activeMenu === "programs" ? "" : "programs");
            }}
          >
            <span className="side-nav-icon">
              <Icons.Programs
                variant={activeMenu === "programs" ? "active" : "default"}
              />
            </span>
            <span>Programs</span>
            <span className={`dropdown-icon ${activeMenu === "programs"}`}>
              {" "}
              <Icons.Chevron
                variant={activeMenu === "programs" ? "active" : "default"}
              />
            </span>
          </div>

          {activeMenu === "programs" && (
            <div>
              <div
                className={`sub-nav  ${props.active.includes(`All`)}`}
                onClick={() => props.history.push(`/programs`)}
              >
                All
              </div>
              {programs?.map((p, i) => (
                <div
                  key={i}
                  className={`sub-nav  ${props.active.includes(`${p.name}`)}`}
                  onClick={() => {
                    if (p.tag === "ExecutiveTraining") return;
                    props.history.push(`/programs/${p.tag}`);
                    props?.onToggle();
                  }}
                >
                  {p.name}
                </div>
              ))}
            </div>
          )}
        </div>
        {/**saved */}
        <div hidden>
          <div
            className={`side-nav-tab ${props.parentMenu === "saved"}`}
            onClick={() => {
              setActiveMenu(activeMenu === "saved" ? "" : "saved");
            }}
          >
            <span className="side-nav-icon">
              <Icons.Save
                variant={activeMenu === "saved" ? "active" : "default"}
              />
            </span>
            <span>Saved</span>
            <span className={`dropdown-icon ${activeMenu === "saved"}`}>
              {" "}
              <Icons.Chevron
                variant={activeMenu === "saved" ? "active" : "default"}
              />
            </span>
          </div>
        </div>

        {/**settings */}
        <div hidden>
          <div
            className={`side-nav-tab ${
              props.active === "settings" || activeMenu === "settings"
            }`}
            onClick={() => {
              setActiveMenu(activeMenu === "settings" ? "" : "settings");
            }}
          >
            <span className="side-nav-icon">
              <SettingsIcon />
            </span>
            <span>Settings</span>
            <span className={`dropdown-icon ${activeMenu === "settings"}`}>
              {" "}
              <ChevronDown />
            </span>
          </div>
          {activeMenu === "settings" && (
            <div>
              <div
                className={`sub-nav  ${active === "department"}`}
                onClick={() => props.history.push(`/settings/department`)}
              >
                Department
              </div>

              {/* <div
                className={`sub-nav  ${active === "cities"}`}
                onClick={() => props.history.push(`/settings/cities`)}
              >
                Cities
              </div>
              <div
                className={`sub-nav  ${active === "towns"}`}
                onClick={() => props.history.push(`/settings/towns`)}
              >
                Towns
              </div> */}
            </div>
          )}
        </div>
        {/**admin */}
        {/*  {currentUser?.user?.isSuperUser && (
          <div>
            <div
              className={`sub-nav  ${props.active.includes(`students`)}`}
              onClick={() => props.history.push(`/admin/students`)}
            >
              Students
            </div>
            <div
              className={`sub-nav  ${props.active.includes(`sittings`)}`}
              onClick={() => props.history.push(`/admin/sittings`)}
            >
              Sittings
            </div>
            <div
              className={`sub-nav  ${props.active.includes(`subjects`)}`}
              onClick={() => props.history.push(`/admin/subjects`)}
            >
              Subjects
            </div>
            <div
              className={`sub-nav  ${props.active.includes(`books`)}`}
              onClick={() => props.history.push(`/admin/book-store`)}
            >
              Bookstore
            </div>
            <div
              className={`sub-nav  ${props.active.includes(`complaints`)}`}
              onClick={() => props.history.push(`/admin/complaints`)}
            >
              Complaints
            </div>
            <div
              className={`sub-nav  ${props.active.includes(`job-portal`)}`}
              onClick={() => props.history.push(`/admin/job-portal`)}
            >
              Job-portal
            </div>
            <div
              className={`sub-nav  ${props.active.includes(`users`)}`}
              onClick={() => props.history.push(`/admin/users`)}
            >
              Users
            </div>
            <div
              className={`sub-nav  ${props.active.includes(`settings`)}`}
              onClick={() => props.history.push(`/admin/settings`)}
            >
              Settings
            </div>
          </div>
        )} */}

        {currentUser?.user?.isSuperUser &&
          accessLevels?.map((nav, i) => {
            const access = hasAccess(nav);
            return access.read ? (
              <div
                key={i}
                className={`sub-nav  ${props.active.includes(
                  `${nav.toLowerCase()}`
                )}`}
                onClick={() =>
                  props.history.push(`/admin/${nav.toLowerCase()}`)
                }
              >
                {nav}
              </div>
            ) : null;
          })}
      </div>

      <div className="side-bottom">
        {!currentUser?.user?.isSuperUser && (
          <div className="download-pane">
            <div className="d-flex">
              <div className="">
                <Icons.AppStore />
              </div>
              <div className="mx-2">
                <Icons.PlayStore />
              </div>
            </div>
            <div className="text-20  mt-20 font-500 d-flex justify-content-between">
              <div>Download our mobile app</div>
              <div className="m-3">
                <Icons.ArrowRight large />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
