import React, { useState } from "react";

import { Wrapper, RigthButton,TopContent,Title, InnerContent } from "./expandable-pane.styles";
import Icons from "../../assets/icons";
const ExpandablePane = ({
  children,
  title,
  open=false,
  onExpand=()=>{},
  hideButton=false,
  flat=false,
  disabled=false,
  buttonIcon
}) => {

  const [expand,setExpand]=useState(open)
  
  return (

    <Wrapper>
      <TopContent onClick={()=>{ if(disabled)return; setExpand(!expand); onExpand(!expand)}}>
        <Title>{title}</Title>
        <RigthButton>
          <div>
             <Icons.Chevron /> 
          </div>
        </RigthButton>
      </TopContent>
      {expand && <InnerContent>
        {children}
      </InnerContent>}
    </Wrapper>
    
 
  );
};

export default ExpandablePane;
