import React,{useState, useEffect} from "reactn"
import Button from "../../../../components/button/button.component";

import { Col, Row } from "react-bootstrap"
import InputField from "../../../../components/form/input-field/input-field.component"
import Select from "../../../../components/form/select/select.component"
import { useGlobal } from "reactn";
import ExpandablePane from "../../../../components/expandable-pane/expandable-pane.component"
import SuccessErrorModal from "../../../../components/modal/success-error-modal/success-error-modal.component"
import { withRouter } from "react-router-dom"

const CheckoutTab=(props)=>{
    //const [selectedSub,setSelectedSub]=useGlobal("selectedSub")
    const selectedSub=[
    {name:"Financial Accounting", level:" 1", amount:"100"},
    {name:"Advanced Audit", level:" 1", amount:"100"},
    {name:"Corporate Report", level:" 1", amount:"100"},
    {name:"Financial Reporting", level:" 1", amount:"100"}];

    const [selectedPayment,setSelectedPayment]=useState()
    const [registeredPrograms,setRegisteredPrograms]=useGlobal("registeredPrograms")
    
    const [payment,setPayment]=useState({
        type:"",//card or momo
        momo_number:"",
        momo_provider:"",
        expiry_date:"",
        cvv:"",
        card_number:""
    })

    const [state,setState]=useState({
        openModal:false,
        modalSuccess:null,
        modalError: null,
        error: null,
        processing: false,
    })

    const handleChange=(e)=>{
        const {name,value} = e.target
        setPayment({...payment,[name]: value})
    }

    const total=()=>{
        let t=0
        selectedSub?.forEach((item)=>(t+= parseFloat( item.amount)))
        return t
    }

    let _timeout

    const onPay=()=>{
        setState({...state, processing: true,error: null})
        _timeout=setTimeout(() => {
            setState({...state, processing:false,openModal:true, 
                 modalSuccess:{
                     description:"Payment Successful!", 
                     message:`You have successfully registered for the ${props.program} program`, 
                     buttonText: "Goto program",
                     buttonAction:()=>{ props.onSuccessClose() } 
                }  
            })
            //setRegisteredPrograms([...registeredPrograms,{name:props?.program }])
        }, 2000);
    }

    useEffect(() => {
        
        return () => {
           clearTimeout(_timeout)  
        }
    }, [])

    return(
          <div>
              <SuccessErrorModal 
              open={state.openModal} 
              success={state.modalSuccess} 
              onHide={()=>{
                  setState({...state,openModal: false})
                  
                  if(state?.modalSuccess)props?.onSuccessClose() 
                }}
              />
                <div className="text-30 mb-30 font-500">Checkout</div>
                <Row>
                    <Col md={6}>
                        <div className="text-25 font-500 text-grey mb-50">Purchase details</div>
                        {
                            selectedSub?.map((sub,i)=>(
                                 <div className="mb-20">
                                <div className="d-flex align-content-center justify-content-between">
                                    <div>
                                        <div className="text-20 font-500">{i+1} &nbsp; {sub.name}</div>
                                        <div className="text-15">
                                            <span>Level {sub.level}</span> &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>GHC {sub.amount}</span>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                            ))
                        }
                        <div className="d-flex  justify-content-between">
                            <div/>
                            <div className="text-23 font-500">Total</div>
                            <div className="text-23 font-500 px-3">GHC {total()}</div>
                        </div>
                        <Row>
                            <Col md={8} className="mt-30">
                                <span className="text-grey pointer " onClick={()=>props.onBack()}>Previous step</span>
                            </Col>
                            <Col hidden>
                            <Button classes="f-width danger mt-20" onClick={()=>props?.onNext()}>Next</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <div className="content-pane mt-20">
                            <div className="font-500 text-25 text-grey mb-25">Select payment method</div>
                            <ExpandablePane title="Mobile Money" onExpand={()=>{setPayment({...payment, type:"momo"})}}>
                                <div>
                                    <hr className="mb-30"/>
                                    <InputField name="momo_number" value={payment?.momo_number} classes="small mb-20" placeholder="MoMo number" type="tel"
                                     onChange={handleChange}/>
                                    <Select small placeholder="Network Provider" 
                                    options={[{label:"MTN",value:"MTN"},{label:"Vodafone",value:"Vodafone"},{label:"AirtelTigo",value:"AirtelTigo"}]}
                                    onChange={(selected)=>{ setPayment({...payment, momo_provider: selected.value })}}
                                    />
                                </div>
                            </ExpandablePane>
                            <ExpandablePane title="Card" onExpand={()=>{setPayment({...payment,type:"card"})}}>
                                <div>
                                    <hr className="mb-30"/>
                                    <InputField name="card_number" value={payment.card_number} classes="small"  placeholder="Card number" onChange={handleChange}/>
                                    <Row className="mt-20">
                                        <Col xs={6}>
                                            <InputField name="expiry_date"  classes="small" value={payment?.expiry_date}  placeholder="Expiry date" type="number" onChange={handleChange}/>
                                        </Col>
                                        <Col xs={6}>
                                            <InputField name="cvv" value={payment?.cvv} classes="small" placeholder="CVV" type="number" onChange={handleChange}/>
                                        </Col>
                                    </Row>
                                </div>
                            </ExpandablePane>

                            <div className="mt-20"> 
                                <Button classes="danger f-width" loading={state.processing} onClick={()=>{onPay()}}> Pay GHC {total()}</Button>
                                <div className="text-23 font-500 text-grey mt-20 text-center pointer" onClick={props.onMakeChanges}>Make Changes</div>
                            </div>
                        
                        </div>
                    </Col>
                </Row>
            </div>
    )
}

export default withRouter(CheckoutTab)