import React, { useGlobal, createContext, setGlobal } from "reactn";

import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import initReactnPersist from "reactn-persist";

initReactnPersist({
  storage: localStorage,
  whitelist: ["currentUser", "programs", "reisteredPrograms", "amountPaid"],
  key: `@aspire-${process.env.NODE_ENV}`,
  initialValue: {
    currentUser: null,
    reisteredPrograms: null,
    programs: [],
    currentProgram: null,
    amountPaid: null,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
