import { useState, useGlobal } from "reactn";
import { Modal, Row, Col } from "react-bootstrap";
import { useEffect } from "reactn";
import { deleteData, postData, putData } from "../../../api/api";
import Button from "../../button/button.component";
import SwitchButton from "../../form/toggle-button/toggle-button.component";
import InputField from "../../form/input-field/input-field.component";
import Select from "../../form/select/select.component";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { toast } from "react-toastify";

const SubjectModal = ({
  open,
  currentUser,
  subject,
  onHide,
  type = "add",
  onBlurHide = false,
  onEdit = () => {},
  onDelete = () => {},
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    action: type,
    processing: false,
    error: null,
    success: null,
  });
  const [programs] = useGlobal("programs");

  const [_subject, _setSubject] = useState({
    name: "",
    level: null,
    price: "",
    isIntervention: false,
    isModular: false,
    isOffline: false,
    programID: "",
  });

  const sittinTypes = {
    "": "Tuition",
    isIntervention: "Intervention",
    isModular: "Modular",
    isOffline: "Offline",
  };

  const programOptions = programs?.map((p) => ({ value: p.id, label: p.name }));

  const reset = () => {
    setState({
      action: type,
      processing: false,
      error: null,
      success: null,
    });
  };

  const processsubject = (action) => {
    setState({
      ...state,
      processing: true,
      error: null,
    });

    let data = { ..._subject, price: parseFloat(_subject.price) };

    const request =
      type === "edit" || _subject?.id
        ? putData(`tuition/subject/${_subject?.id}`, data, currentUser?.token)
        : postData(`tuition/subject`, data, currentUser?.token);

    request.then((resp) => {
      if (resp.success) {
        setState({ ...state, processing: false, error: null });
        onHide();
        onUpdated(resp.data.subject);
        toast.success("Subject updated.");
        return;
      }
      setState({
        ...state,
        processing: false,
        error:
          resp?.message ||
          `Unable to update Subject, Please fill all fields and check your internet connection.`,
      });
    });
  };

  const handleChange = (e) => {
    _setSubject({
      ..._subject,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setState({ ...state, error: null });
    if (subject) {
      _setSubject({ ...subject });
    } else {
      _setSubject(null);
    }
  }, [subject, open, type]);

  return (
    <div>
      <Modal
        //className="custom-modal"
        show={open}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <div className="f-width d-flex space-between">
            <div className="title">Subject</div>
            <div
              onClick={() => {
                if (state.processing) return;
                reset();
                onHide();
              }}
            >
              <Close />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          <div className="row mb-50 mt-20" hidden={type === "view"}>
            <div className="col-md-12 mb-20">
              <InputField
                classes="small"
                value={_subject?.name}
                label="Name"
                name="name"
                placeholder="Subject name"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-20">
              <Select
                placeholder="Select Program"
                small
                label={"Program"}
                options={[
                  { label: "ICAG", value: 1 },
                  { label: "CTIG", value: 2 },
                  { label: "Executive Training", value: 3 },
                  { label: "CIMA", value: 4 },
                ]}
                value={
                  _subject?.programID
                    ? {
                        label:
                          _subject?.programID === 1
                            ? "ICAG"
                            : _subject?.programID === 2
                            ? "CITG"
                            : _subject?.programID === 3
                            ? "Executive Training"
                            : _subject?.programID === 4
                            ? "CIMA"
                            : "",
                        value: _subject.programID,
                      }
                    : null
                }
                onChange={(selected) => {
                  _setSubject({ ..._subject, programID: selected.value });
                }}
              />
            </div>
            <div className="col-md-6 mb-20">
              <Select
                placeholder="Select sitting type"
                small
                label={"Sitting type"}
                options={[
                  { label: "Tuition", value: "" },
                  { label: "Intervention", value: "isIntervention" },
                  { label: "Modular", value: "isModular" },
                  { label: "Offline", value: "isOffline" },
                ]}
                value={{
                  label: _subject?.isIntervention
                    ? "Intervention"
                    : _subject?.isModular
                    ? "Modular"
                    : _subject?.isOffline
                    ? "Offline"
                    : "Tuition",
                  value: _subject?.isIntervention
                    ? "isIntervention"
                    : _subject?.isModular
                    ? "isModular"
                    : _subject?.isOffline
                    ? "isOffline"
                    : null,
                }}
                onChange={(selected) => {
                  let sub = {
                    ..._subject,
                    isIntervention: false,
                    isModular: false,
                    isOffline: false,
                  };
                  if (selected.value) sub[[selected.value]] = true;
                  _setSubject({ ...sub });
                }}
              />
            </div>
            <div className="col-md-6 mb-20">
              <Select
                placeholder="Select Level"
                small
                label={"Level"}
                options={[
                  { label: "1", value: 1 },
                  { label: "2", value: 2 },
                  { label: "3", value: 3 },
                ]}
                value={{ label: _subject?.level, value: _subject?.level }}
                onChange={(selected) => {
                  _setSubject({ ..._subject, level: selected.value });
                }}
              />
            </div>
            <div className="col-md-6 mb-20">
              <InputField
                classes="small"
                type="number"
                value={_subject?.price}
                min={1}
                label="Price"
                name="price"
                placeholder="Enter price"
                onChange={handleChange}
              />
            </div>
          </div>

          {/**view */}

          {type === "view" && (
            <div className="row">
              <div className="col-md-6">
                <div className="text-15 text-grey-light">ID</div>
                <div className="text-15 text-grey mb-10">
                  {_subject?.unique_id}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Name</div>
                <div className="text-15 text-grey mb-10">{_subject?.name}</div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Location</div>
                <div className="text-15 text-grey  mb-10">
                  {_subject?.location}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Phone</div>
                <div className="text-15 text-grey mb-10">
                  {_subject?.telephone}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Email</div>
                <div className="text-15 text-grey mb-10">{_subject?.email}</div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Status</div>
                <div className="text-15 text-grey  mb-10">
                  {_subject?.status}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Assigned to</div>
                <div className="text-15 text-grey mb-10">
                  {_subject?.assigned_to?.first_name}{" "}
                  {_subject?.assigned_to?.last_name}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          {state.error && (
            <div className="text-danger text-right mb-20">{state?.error}</div>
          )}
          <div className="flex flex-end">
            {type !== "view" && (
              <Button
                classes="small danger"
                loading={state.processing}
                disabled={
                  state.processing ||
                  _subject?.lastName?.length === 0 ||
                  _subject?.firstName?.length === 0
                }
                onClick={processsubject}
              >
                Save
              </Button>
            )}

            {/*   {type == "view" && (
              <Button
                classes="small"
                disabled={state.processing}
                onClick={onEdit}
              >
                Edit
              </Button>
            )} */}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SubjectModal;
