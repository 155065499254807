import { withRouter } from "react-router-dom"
import React, {useGlobal, useState,useEffect} from "reactn"
import PageLayout from "../../components/page-layout/page-layout.component"
import Button from "../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import { Row,Col } from "react-bootstrap";
import AdvancedButton from "../../components/advanced-button/advanced-button.component";
import queryString from "query-string"

const ProgramsPage =(props)=>{
    const [currentUser] = useGlobal("currentUser");
    const [programs]=useGlobal("programs")
    const params=queryString.parse(window.location?.search)
    
    const [state, setState] = useState({
        query: "",
        status: "",
        tab:""
    });

    useEffect(() => {
        setState({...state, tab: params?.tab || ""})
        
    }, [window.location.search,props.history])

    
    return(
        <PageLayout activeLink="all"
        parentMenu="programs"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}>

            <div style={{maxWidth: "620px"}}>
            {state.tab?.length===0 &&<div>
                <div className="text-30 font-500">Programs</div>
                <div className="text-20 text-grey mb-20">Select a program to begin studying</div>
                <div className="row">
                    <Col md={12}>
                        {
                            programs?.map((p,i)=>(
                                <AdvancedButton key={i} title={p.name} onClick={()=>props.history.push(`/programs/${p.tag}`)}/>
                            ))
                        }
                        {/* <AdvancedButton title="ICAG" onClick={()=>props.history.push(`/programs/ICAG`)}/>
                        <AdvancedButton title="CITG" onClick={()=>props.history.push(`/programs/CITG`)}/>
                        <AdvancedButton title="Executive Training" onClick={()=>props.history.push(`/programs/executive-training`)}/>
                     */}
                    </Col>
                </div>
            </div>}

            
            </div>
        </PageLayout>
    )
}


export default withRouter(ProgramsPage)