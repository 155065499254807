import { withRouter } from "react-router-dom"
import React, {useGlobal, useState,useEffect} from "reactn"
import PageLayout from "../../components/page-layout/page-layout.component"
import Button from "../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import { Row,Col } from "react-bootstrap";
import AdvancedButton from "../../components/advanced-button/advanced-button.component";
import queryString from "query-string"
import InputField from "../../components/form/input-field/input-field.component";
import ProfileModal from "../../components/modal/profile-modal/profile-modal.component";
import { postData } from "../../api/api";
import { toast } from "react-toastify";


const AccountPage =(props)=>{
    const [currentUser,setCurrentUser] = useGlobal("currentUser");
    const [,setPrograms]=useGlobal("programs")
    const [,setAmountPaid]=useGlobal("amountPaid")

    const params=queryString.parse(window.location?.search)
    
    const [state, setState] = useState({
        query: "",
        status: "",
        tab:"", 
        openProfile: false,
        processLogout: false,
    });

    useEffect(() => {
        setState({...state, tab: params?.tab || ""})
        
    }, [window.location.search,props.history])

    const [student,setStudent]=useState({})
    useEffect(()=>{
        setStudent(currentUser?.user?.student)
    },[currentUser?.user?.student])

    const onLogout = () => {
        setState({ ...state, processLogout: true });
        const t=toast.loading("Please wait...")
        postData(`user/logout`, {}, currentUser?.token).then((resp) => {
            toast.dismiss(t)
        setState({ ...state, processLogout: false });
        if (resp.success) {
            setCurrentUser(null,()=>{ props.history.push(`/login`); console.log(currentUser)});
            setPrograms([])
            return;
        }
        toast.error("Failed to logout, Please try again")
        });
    };


    const onProcessReset = () => {
    setState({ ...state, processing: true });
    const t= toast.loading("Please wait...")
    postData(
      `user/password/reset`,
      { emailAddress: currentUser?.user?.username },
      currentUser?.token
    ).then((res) => {
      toast.dismiss(t)
      setState({ ...state, processing: false });
      if (res.success) {
        toast.success( "Reset password link has been sent to email, Please follow that link to complete the process.")
        return;
      }
      toast.error("Failed to request password reset, \n Please check your email and try again");
    });
  };

    
    return(
        <div>
        {currentUser && <div>
            <ProfileModal type="edit"
                open={state.openProfile} 
                student={currentUser?.user?.student}
                currentUser={currentUser}
                onHide={()=>{
                    setState({...state, openProfile: false})
                }}
                onUpdated={(data)=>{
                    setCurrentUser({...currentUser,user:{...currentUser.user, student: data}})
                }}
            />
            <PageLayout activeLink="home"
            parentMenu="home"
            inputProps={{
            value: state.query,
            onChange: (e) => {
                setState({ ...state, query: e.target.value });
            },
            }}>
                <div className="mt-50">
                <Row>
                    <div className="mt-50"/>
                    <Col md={7} style={{paddingRight:"30px"}}>
                        <div className="mt-50"/>
                        <AdvancedButton title="Account" titleBold  hideRightButton
                            onClick={()=>{setState({...state,tab:"account-info"})}}/>
                        <AdvancedButton disabled={state.processing} title={state.processing?"Please wait..." :"Password Reset" }titleBold  hideRightButton
                            onClick={()=>{
                                onProcessReset()
                                //props.history.push('/reset-password')
                                //setState({...state,tab:"password-reset"})
                            }}/>
                        <AdvancedButton disabled={state.processLogout} title={<div className="text-danger">{state.processLogout ? "Please wait...":"Logout"}</div>} titleBold hideRightButton
                            onClick={()=>{
                               onLogout()
                               /* setCurrentUser(null)
                                setAmountPaid(null)
                                setPrograms([])
                                props.history.push(`/login`) */
                            }}/>
                    </Col>
                    <Col md={5} style={{borderLeft:"solid 1px #C4C4C4",paddingLeft: "30px"}} >
                        <div className="mt-50"/>
                        {(!state?.tab || state.tab==="account-info") &&<div>
                            <div className="text-23">Account info</div>

                            <div className="text-grey mt-10">
                                 <div>
                                    <div className="text-18 font500">Registration Number</div>
                                    <div className="text-18 font500">{student?.registrationNumber}</div>
                                    <hr/>
                                </div>
                                <div>
                                    <div className="text-18 font500">Name</div>
                                    <div className="text-18 font500">{student?.firstName} {student?.lastName}</div>
                                    <hr/>
                                </div>
                                <div>
                                    <div className="text-18 font500">Email</div>
                                    <div className="text-18 font500">{student?.emailAddress}</div>
                                    <hr/>
                                </div>

                                <div>
                                    <div className="text-18 font500">Phone</div>
                                    <div className="text-18 font500">{student?.phoneNumber}</div>
                                    <hr/>
                                </div>
                                <div>
                                    <div className="text-18 font500">WhatsApp </div>
                                    <div className="text-18 font500">{student?.whatsAppNumber}</div>
                                    <hr/>
                                </div>
                                <div>
                                    <div className="text-18 font500">Address </div>
                                    <div className="text-18 font500">{student?.address}</div>
                                    <hr/>
                                </div>

                                <div>
                                    <div className="text-18 font500">Workplace </div>
                                    <div className="text-18 font500">{student?.workplace}</div>
                                    <hr/>
                                </div>
                               {/*  <div className="mb-40"> 
                                    <Button classes="danger f-width"
                                    onClick={()=>{setState({...state,openProfile: true})}}>
                                        UPDATE
                                    </Button>
                                </div> */}
                                
                            </div>
                        </div>}

{/*                         {state.tab==="password-reset"&&<div>
                            <div className="text-23 mb-20">Reset Paaswword</div>

                            <InputField classes="mb-20" placeholder="Old password"/>
                            <InputField classes="mb-20"  placeholder="New password"/>
                            <InputField  classes="mb-20"  placeholder="Confirm password"/>

                            <div className="row mt-20">
                                <Col md={6}>
                                    <div className="text-grey mt-20 text-center">Discard</div>
                                </Col>
                                <Col>
                                    <Button classes="danger f-width">Reset</Button>
                                </Col>
                            </div>
                        
                            </div>} */}
                    
                    </Col>

                </Row>
                </div>
            </PageLayout>
        </div>}
        </div>
    )
}


export default withRouter(AccountPage)