import React, { useGlobal, useState, useEffect } from "reactn";
import { withRouter } from "react-router-dom";
import PageLayout from "../../../../components/page-layout/page-layout.component";
import ConfirmModal from "../../../../components/modal/confirm-modal/confirm-modal.component";
import Button from "../../../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../../../components/loader-pane/loader-pane.component";
import { deleteData } from "../../../../api/api";
import { Row, Col, Table, Badge } from "react-bootstrap";
import ResourceModal from "../../../../components/modal/resource-modal/resource-modal.component";
import Select from "../../../../components/form/select/select.component";
import CopyResourceModal from "../../../../components/modal/copy-resource-modal/copy-resource-modal.component";
import moment from "moment";
import "./view-subject.styles.scss";
import SittingResourceModal from "../../../../components/modal/sitting-resource-modal/sitting-resource-modal.component";
import useAccessLevels from "../../../../hooks/useAccessLevels";

const ViewSubjectsPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [selectedSubjects] = useGlobal("selectedSubjects");
  const [currentSubject] = useGlobal("currentSubject");
  const [state, setState] = useState({
    query: "",
    status: "",
    loading: true,
    openModal: false,
    openCopyModal: false,
    activeResource: null,
    activeTab: 0,
    videoFolder: "Lecture Videos",
    documentFolder: "Summary Notes",
    audioFolder: "Lecture Audios",
    sittingID: "",
    openSittingResourceModal: false,
    selectedResources: [],
  });

  const documentFolderOptions = [
    { label: "Summary Notes", value: "Summary Notes" },
    { label: "Special Notes", value: "Special Notes" },
    { label: "Questions Bank", value: "Questions Bank" },
    { label: "ICAG Past Questions", value: "ICAG Past Questions" },
  ];
  const videoFolderOptions = [
    { label: "Lecture Videos", value: "Lecture Videos" },
    { label: "Revision Videos", value: "Revision Videos" },
  ];
  const audioFolderOptions = [
    { label: "Lecture Audios", value: "Lecture Audios" },
  ];

  const [confirm, setConfirm] = useState({
    title: "Confirm",
    open: false,
  });

  const canCopy = selectedSubjects?.length > 0;
  const [sittings, setSittings] = useState([]);
  const { sittingsData, sittingError, mutateSittings, isValdatingSittings } =
    useSWR(`tuition/sitting`, {
      onSuccess: (d) => {
        setSittings([...d?.sittings]);
      },
    });

  const { data, error, mutate, isValdating } = useSWR(
    `tuition/document?subjectID=${currentSubject?.id}&documentFolder=${
      state.documentFolder
    }&query=${(state.activeTab === 0 && state?.query) || ""}`
  );

  const {
    data: videos,
    error: videosError,
    mutate: mutateVideos,
    isValidating: isValidatingVideos,
  } = useSWR(
    `tuition/media?subjectID=${
      currentSubject?.id
    }&mediaType=Video&mediaFolder=${state.videoFolder}&query=${
      (state.activeTab === 1 && state?.query) || ""
    }`
  );

  const {
    data: audios,
    error: audiosError,
    mutate: mutateAudios,
    isValidating: isValidatingAudios,
  } = useSWR(
    `tuition/media?subjectID=${
      currentSubject?.id
    }&mediaType=Audio&mediaFolder=${state.audioFolder}&query=${
      (state.activeTab === 2 && state?.query) || ""
    }`
  );

  const sittingName = (s) =>
    `${moment(s.startAt).format("MMM, YYYY")} - ${moment(s.endAt).format(
      "MMM, YYYY"
    )} (${s?.program?.tag})`;

  const sittingOptions = () => {
    return sittings?.map((s) => ({
      label: sittingName(s),
      value: s.id,
    }));
  };

  const { hasAccess } = useAccessLevels(currentUser?.user?.accessLevels);
  const access = hasAccess("Subjects");

  useEffect(() => {
    if (!currentSubject) props.history.goBack();
  }, []);

  const [selectedResources, setSelectedResources] = useState([]);
  const isChecked = (id) => {
    if (selectedResources?.find((item) => item.id === id)) return true;
    return false;
  };

  const onCheckChange = (item) => {
    console.log(item);
    let c = isChecked(item.id);
    if (c) {
      let n = selectedResources?.filter((s) => s.id !== item.id);
      setSelectedResources([...n]);
    } else {
      setSelectedResources([...selectedResources, item]);
    }
  };

  return (
    <div className="view-subject-page">
      <ResourceModal
        open={state?.openModal}
        currentUser={currentUser}
        subject={currentSubject}
        resource={state?.activeResource}
        onHide={() => {
          setState({ ...state, openModal: false, activeResource: null });
        }}
        onUpdated={(type) => {
          if (type === "PDF") {
            mutate();
          } else if (type === "Video") {
            mutateVideos();
          } else {
            mutateAudios();
          }
        }}
      />

      <CopyResourceModal
        open={state?.openCopyModal}
        currentUser={currentUser}
        resource={state?.activeResource}
        onHide={() => {
          setState({ ...state, openCopyModal: false, activeResource: null });
        }}
      />

      <SittingResourceModal
        open={state?.openSittingResourceModal}
        currentUser={currentUser}
        resourceType={state.activeTab == 0 ? "document" : "media"}
        selectedResources={selectedResources}
        onHide={() => {
          setState({ ...state, openSittingResourceModal: false });
          setSelectedResources([]);
        }}
        onUpdated={() => {
          setSelectedResources([]);
          if (state?.activeTab == 0) {
            mutate();
          } else if (state?.activeTab == 1) {
            mutateVideos();
          } else {
            mutateAudios();
          }
        }}
      />

      <PageLayout
        activeLink="subjects"
        parentMenu="subjects"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}
      >
        <div className="mt-3">
          <div className="text-24">
            {" "}
            Subject: {currentSubject?.name}{" "}
            <span className="text-20">
              [Level: {currentSubject?.level}, Program:{" "}
              {currentSubject?.programID === 1 ? "ICAG" : "CTIG"}{" "}
              {currentSubject?.isIntervention && "Intervention"}]
            </span>
          </div>

          <div className="text-20 mt-20">
            {" "}
            Resources
            {access?.write && (
              <span
                className="text-danger mx-3 pointer text-18 font-500"
                onClick={() => {
                  setState({ ...state, openModal: true });
                }}
              >
                Add new Resource
              </span>
            )}
            {access?.write && (
              <span
                className="text-danger mx-3 pointer text-18 font-500"
                onClick={() => {
                  setState({ ...state, openSittingResourceModal: true });
                }}
              >
                Add/Remove Sittings
              </span>
            )}
          </div>

          <div className="d-flex mt-20">
            <span
              className={`text-20 pointer ${
                state.activeTab === 0 && "text-danger font-bold"
              }`}
              onClick={() => {
                setState({ ...state, activeTab: 0 });
                setSelectedResources([]);
              }}
            >
              Documents
            </span>
            <span
              className={`text-20 pointer mx-3 ${
                state.activeTab === 1 && "text-danger font-bold"
              }`}
              onClick={() => {
                setState({ ...state, activeTab: 1 });
                setSelectedResources([]);
              }}
            >
              Videos
            </span>
            <span
              className={`text-20 pointer ${
                state.activeTab === 2 && "text-danger font-bold"
              }`}
              onClick={() => {
                setState({ ...state, activeTab: 2 });
                setSelectedResources([]);
              }}
            >
              Audios
            </span>
          </div>
          <div className="line mt-10 mb-20"></div>
          <div className="row">
            {state.activeTab == 0 && (
              <div className="col-md-3">
                <Select
                  placeholder="Select folder"
                  small
                  label={"Document Folder"}
                  options={documentFolderOptions}
                  value={{
                    label: state?.documentFolder,
                    value: state?.documentFolder,
                  }}
                  onChange={(selected) => {
                    setState({ ...state, documentFolder: selected.value });
                  }}
                />
              </div>
            )}

            {state.activeTab == 1 && (
              <div className="col-md-3">
                <Select
                  placeholder="Select folder"
                  small
                  label={"Video Folder"}
                  options={videoFolderOptions}
                  value={{
                    label: state?.videoFolder,
                    value: state?.videoFolder,
                  }}
                  onChange={(selected) => {
                    setState({ ...state, videoFolder: selected.value });
                  }}
                />
              </div>
            )}
            {state.activeTab == 2 && (
              <div className="col-md-3">
                <Select
                  placeholder="Select folder"
                  small
                  label={"Document Folder"}
                  options={audioFolderOptions}
                  value={{
                    label: state?.audioFolder,
                    value: state?.audioFolder,
                  }}
                  onChange={(selected) => {
                    setState({ ...state, audioFolder: selected.value });
                  }}
                />
              </div>
            )}

            {false && (
              <div className="col-md-4">
                <Select
                  placeholder="Select sitting"
                  small
                  label={"Sitting"}
                  options={sittings?.length > 0 ? [...sittingOptions()] : null}
                  /* value={{
                    label: state?.documentFolder,
                    value: state?.documentFolder,
                  }} */
                  onChange={(selected) => {
                    setState({ ...state, sittingID: selected.value });
                  }}
                />
              </div>
            )}
          </div>

          <div hidden={state.activeTab !== 0}>
            <div className="row"></div>
            <LoaderPane
              loading={!data && !error}
              noRecords={data && data?.documents?.length === 0}
              onReload={() => {
                mutate();
              }}
              error={!data && error?.message}
            />

            {data?.documents?.length > 0 && (
              <div>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type={"checkbox"}
                          value={
                            data?.documents?.length > 0 &&
                            data?.documents?.length == selectedResources.length
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedResources([...data?.documents]);
                              console.log(data?.documents?.length);
                            } else {
                              setSelectedResources([]);
                            }
                          }}
                        />
                      </th>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Folder</th>
                      <th> URL</th>
                      <th>Sitting</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.documents?.map((s, i) => (
                      <tr key={i}>
                        <td>
                          <input
                            type={"checkbox"}
                            checked={selectedResources?.find(
                              (item) => item.id == s.id
                            )}
                            onChange={() => {
                              onCheckChange(s);
                            }}
                          />
                        </td>
                        <td>{new Date(s.createdAt).toDateString()}</td>
                        <td>{s.description}</td>
                        <td>{s.documentFolder}</td>
                        <td>
                          <a
                            href={s.documentURL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            {s.documentURL}
                          </a>
                        </td>
                        <td>
                          <td>
                            {s?.sittingDocuments?.map((si) => (
                              <Badge className="badge-primary">
                                {sittingName(si.sitting)}
                              </Badge>
                            ))}
                          </td>
                        </td>
                        <td>
                          <span
                            className="text-danger font-500 pointer"
                            onClick={() => {
                              setState({
                                ...state,
                                activeResource: s,
                                openModal: true,
                              });
                            }}
                          >
                            Edit
                          </span>
                          {canCopy && access?.write && (
                            <span
                              className="text-danger mx-2 font-500 pointer"
                              onClick={() => {
                                setState({
                                  ...state,
                                  activeResource: s,
                                  openCopyModal: true,
                                });
                              }}
                            >
                              Copy
                            </span>
                          )}
                          {access?.write && (
                            <span
                              className="text-danger mx-2 font-500 pointer"
                              onClick={() => {
                                //setState({...state, activeResource: s, openModal: true})
                                //setConfirm({...open: true, })
                              }}
                            >
                              Delete
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>

          <div hidden={state.activeTab !== 1}>
            <div className="row"></div>
            <LoaderPane
              loading={(!videos && !videosError) || isValidatingVideos}
              noRecords={videos && videos?.medias?.length === 0}
              onReload={() => {
                mutateVideos();
              }}
              error={!videos && videosError?.message}
            />
            {videos?.medias?.length > 0 && (
              <div>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type={"checkbox"}
                          value={
                            state.activeTab == 1 &&
                            selectedResources.length > 0 &&
                            videos?.medias?.length == selectedResources.length
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedResources([...videos?.medias]);
                            } else {
                              setSelectedResources([]);
                            }
                          }}
                        />
                      </th>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Folder</th>
                      <th> URL</th>
                      <th>Sitting</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {videos?.medias?.map((s, i) => (
                      <tr key={i}>
                        <td>
                          <input
                            type={"checkbox"}
                            checked={selectedResources?.find(
                              (item) => item.id === s.id
                            )}
                            onChange={() => {
                              onCheckChange(s);
                            }}
                          />
                        </td>
                        <td>{new Date(s.createdAt).toDateString()}</td>
                        <td>{s.description}</td>
                        <td>{s.mediaFolder}</td>
                        <td>
                          <div className="media-link">
                            <a
                              href={s.mediaURL}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              {s.mediaURL}
                            </a>
                          </div>
                        </td>
                        <td>
                          {s?.sittingMedias?.map((si) => (
                            <Badge className="badge-primary">
                              {sittingName(si.sitting)}
                            </Badge>
                          ))}
                        </td>
                        <td>
                          {access?.write && (
                            <span
                              className="text-danger font-500 pointer"
                              onClick={() => {
                                if (isValidatingVideos) return;
                                setState({
                                  ...state,
                                  activeResource: s,
                                  openModal: true,
                                });
                              }}
                            >
                              Edit
                            </span>
                          )}
                          {canCopy && access?.write && (
                            <span
                              className="text-danger mx-2 font-500 pointer"
                              onClick={() => {
                                setState({
                                  ...state,
                                  activeResource: s,
                                  openCopyModal: true,
                                });
                              }}
                            >
                              Copy
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>

          <div hidden={state.activeTab !== 2}>
            <div className="row"></div>
            <LoaderPane
              loading={(!audios && !audiosError) || isValidatingAudios}
              noRecords={audios && audios?.medias?.length === 0}
              onReload={() => {
                mutateAudios();
              }}
              error={!audios && audiosError?.message}
            />

            {audios?.medias?.length > 0 && (
              <div>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type={"checkbox"}
                          value={
                            state.activeTab == 2 &&
                            selectedResources.length > 0 &&
                            audios?.medias?.length == selectedResources.length
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedResources([...audios?.medias]);
                            } else {
                              setSelectedResources([]);
                            }
                          }}
                        />
                      </th>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Folder</th>
                      <th> URL</th>
                      <th>Sitting</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {audios?.medias?.map((s, i) => (
                      <tr key={i}>
                        <td>
                          <input
                            type={"checkbox"}
                            checked={selectedResources?.find(
                              (item) => item.id === s.id
                            )}
                            onChange={() => {
                              onCheckChange(s);
                            }}
                          />
                        </td>
                        <td>{new Date(s.createdAt).toDateString()}</td>
                        <td>{s.description}</td>
                        <td>{s.mediaFolder}</td>
                        <td>
                          <div className="media-link">
                            <a
                              href={s.mediaURL}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              {s.mediaURL}
                            </a>
                          </div>
                        </td>
                        <td>
                          {s?.sittingMedias?.map((si) => (
                            <Badge className="badge-primary">
                              {sittingName(si.sitting)}
                            </Badge>
                          ))}
                        </td>
                        <td>
                          {access?.write && (
                            <span
                              className="text-danger font-500 pointer"
                              onClick={() => {
                                if (isValidatingAudios) return;
                                setState({
                                  ...state,
                                  activeResource: s,
                                  openModal: true,
                                });
                              }}
                            >
                              Edit
                            </span>
                          )}
                          {canCopy && access?.write && (
                            <span
                              className="text-danger mx-2 font-500 pointer"
                              onClick={() => {
                                setState({
                                  ...state,
                                  activeResource: s,
                                  openCopyModal: true,
                                });
                              }}
                            >
                              Copy
                            </span>
                          )}
                          <span
                            hidden
                            className="text-danger mx-2 font-500 pointer"
                            onClick={() => {
                              //setState({...state, activeResource: s, openModal: true})
                              //setConfirm({...open: true, })
                            }}
                          >
                            Delete
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
      </PageLayout>
    </div>
  );
};

export default withRouter(ViewSubjectsPage);
