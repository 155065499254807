import React ,{useState,useGlobal} from "reactn"
import {ReactComponent as DownloadIcon} from "../../assets/icons/download.svg"
import fileDownload from "js-file-download";
import {apiBaseUrl, handleError,fetchFile } from "../../api/api";
//import axios from "axios";

const ExportButton=({
    url=null,
    baseUrl=apiBaseUrl,
    label="Export file",
    fileName="exported-file",
    extension=".csv",
})=>{

    const [currentUser,setCurrentUser] = useGlobal("currentUser");
    const [exporting,setExporting]=useState({
        exporting: false, 
        error: null
    })
    const onExport = async () => {
        if(!url)return;
        
        setExporting({
            exporting: true, 
            error: null
        })

        let authToken = currentUser.token;

        fetchFile(url,authToken, {baseURL: baseUrl } ).then(response=>{
            if (response.success) {
                setExporting({
                    exporting: false,
                    error: null
                })
                fileDownload(response.data.data, `${fileName}${extension}`);
                return;
            }

            setExporting({
                exporting: false,
                error: response.message
            })

        })   
    };


    return(
        <div>
            <div className="d-flex align-center pointer"
             onClick={()=>{
                 if(url)
                onExport()
            }}>
                <div className="text-16 font-400 text-grey mr-1">
                {
                    !exporting.exporting &&(
                        <span>{label}</span>
                    )
                }
                {
                    exporting.exporting &&(
                        <span>Exporting...</span>
                    )
                }
                     
                </div>
                <DownloadIcon/>
            </div>
            {exporting.error && (
                <div className="text-danger text-14">
                {exporting.error}
                </div>
            )}
            
        </div>

    )
}

export default ExportButton;