import { withRouter } from "react-router-dom"
import React, {useGlobal, useState, useEffect} from "reactn"
import PageLayout from "../../../components/page-layout/page-layout.component"
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import Button from "../../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import { deleteData } from "../../../api/api";
import { Row,Col } from "react-bootstrap";
import queryString from "query-string"

import InputField from "../../../components/form/input-field/input-field.component";
import Select from "../../../components/form/select/select.component"
import InformationTab from "./tabs/information.tab";
import CheckoutTab from "./tabs/checkout.tab";


const ExecutiveProgramRegistrationPage =(props)=>{
    const [currentUser] = useGlobal("currentUser");
    const [selectedSub,setSelectedSub]=useGlobal("selectedSub")
    const [state, setState] = useState({
        query: "",
        status: "",
        tab:"information",
        program: null
    });

    const params=queryString.parse(window.location?.search)
    //console.log(queryString.parse(window.location?.search))

    useEffect(() => {
       if(params.tab || params?.program)setState({...state,tab: params?.tab || "information", program: params?.program })
        
       return () => {
           
        }
    }, [window.location?.search,props.history])

    return(
        <PageLayout activeLink="home"
        parentMenu="home"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}>
           <div>
               {<div hidden={state.tab!=="information"}>
               <InformationTab 
                    program={state.program} 
                    onNext={()=>props.history.replace(`/executive-program-registration?program=${state.program}&tab=checkout`)} 
               />
               </div>}
               <div hidden={state.tab!=="checkout"}>
                <CheckoutTab program={state.program}  
                onBack={()=>{props.history.replace(`/executive-program-registration?program=${state.program}&tab=information`)}} 
                onNext={()=>{props.history.replace(`/executive-program-registration?program=${state.program}&tab=checkout`)}} 
                hidden={state.tab!=="checkout"}/>
                </div>
           </div>
        </PageLayout>
    )
}



export default withRouter(ExecutiveProgramRegistrationPage)