import React, { useGlobal, useState, useEffect } from "reactn";
import { withRouter } from "react-router-dom";
import PageLayout from "../../../components/page-layout/page-layout.component";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import Button from "../../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import { deleteData, fetchData } from "../../../api/api";
import ProgramCard from "../../../components/cards/program-card/program-card.component";
import { Row, Col, Table } from "react-bootstrap";
import { Facebook } from "react-content-loader";
import ProfileModal from "../../../components/modal/profile-modal/profile-modal.component";
import SittingModal from "../../../components/modal/sitting-modal/sitting-modal.component";
import moment from "moment";
import useAccessLevels from "../../../hooks/useAccessLevels";

const StudentSittingsPage = (props) => {
  const [currentUser] = useGlobal("currentUser");

  const [loadData, setLoadData] = useState(false);
  const [state, setState] = useState({
    query: "",
    status: "",
    loading: true,
    openModal: false,
    activeSitting: null,
  });

  const [studentSittings, setStudenSittings] = useState([]);

  const { data, error, mutate, isValdating } = useSWR(
    loadData &&
      `tuition/sitting/student/${state?.query}/list?email=${state?.query}`,
    {
      onSuccess: (data) => {
        console.log(data?.studentSittings);
        setStudenSittings([...data?.studentSittings]);
        setLoadData(false);
      },
    }
  );
  useEffect(() => {
    if (loadData) {
      mutate();
    }
  }, []);

  const { hasAccess } = useAccessLevels(currentUser?.user?.accessLevels);
  const access = hasAccess("Student-Registrations");

  return (
    <div>
      <SittingModal
        open={state.openModal}
        sitting={state?.activeSitting}
        currentUser={currentUser}
        onUpdated={() => {
          mutate();
        }}
        onHide={() => {
          setState({ ...state, openModal: false, activeSitting: null });
        }}
      />

      <PageLayout
        activeLink="student-registrations"
        parentMenu="student-registrations"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}
      >
        <div className="mt-3">
          <div className="text-24 d-flex gap-3">
            Student Sittings{" "}
            <div>
              {state.query?.length > 0 && (
                <span
                  className="font-bold text-18 text-danger pointer"
                  onClick={() => {
                    setLoadData(true);
                  }}
                >
                  Load Sittings
                </span>
              )}
            </div>{" "}
          </div>
          {loadData && (
            <LoaderPane
              loading={isValdating || (!data && !error)}
              noRecords={!isValdating && studentSittings?.length === 0}
              error={error?.message}
            />
          )}

          {studentSittings?.length > 0 && (
            <div>
              <div className="mt-2 font-bold">
                <div>
                  Phone number: {studentSittings[0]?.student?.phoneNumber}
                </div>
                <div>
                  WhatsApp number: {studentSittings[0]?.student?.whatsAppNumber}
                </div>
              </div>
              <Table className="responsive">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Tuition</th>
                    <th>Intervention</th>
                    <th>Modular</th>
                    <th>Offline</th>
                    {/* <th>Program</th> */}
                  </tr>
                </thead>
                <tbody>
                  {studentSittings?.map((s, i) => (
                    <tr key={i}>
                      <td>
                        {s?.sitting?.description ||
                          `${moment(s.startAt).format("MMMM, YYYY")} - ${moment(
                            s.endAt
                          ).format("MMMM, YYYY")}`}
                      </td>

                      <td>
                        <div className="mb-2">
                          {s?.accessGranted ? "Access granted" : "No Access"}{" "}
                        </div>{" "}
                        <div className="d-flex flex-wrap gap-2">
                          {s?.subjects?.map((sub, i) => (
                            <span
                              key={i}
                              className={`badge ${
                                sub?.accessGranted
                                  ? "bg-success"
                                  : " bg-primary "
                              } `}
                            >
                              {sub?.subject?.name}
                            </span>
                          ))}
                        </div>
                      </td>
                      <td>
                        {s?.intervention && (
                          <>
                            <div className="mb-2">
                              {s?.intervention?.accessGranted
                                ? "Access granted"
                                : "No Access"}{" "}
                            </div>
                            <div className="d-flex flex-wrap gap-2">
                              {s?.intervention?.subjects?.map((sub, i) => (
                                <span
                                  key={i}
                                  className={`badge ${
                                    sub?.accessGranted
                                      ? "bg-success"
                                      : " bg-primary "
                                  } `}
                                >
                                  {sub?.subject?.name}
                                </span>
                              ))}
                            </div>
                          </>
                        )}
                      </td>

                      <td>
                        {s?.modular && (
                          <>
                            <div className="mb-2">
                              {s?.modular?.accessGranted
                                ? "Access granted"
                                : "No Access"}{" "}
                            </div>
                            <div className="d-flex flex-wrap gap-2">
                              {s?.modular?.subjects?.map((sub, i) => (
                                <span
                                  key={i}
                                  className={`badge ${
                                    sub?.accessGranted
                                      ? "bg-success"
                                      : " bg-primary "
                                  } `}
                                >
                                  {sub?.subject?.name}
                                </span>
                              ))}
                            </div>
                          </>
                        )}
                      </td>
                      <td>
                        {s?.offline && (
                          <>
                            <div className="mb-2">
                              {s?.offline?.accessGranted
                                ? "Access granted"
                                : "No Access"}{" "}
                            </div>
                            <div className="d-flex flex-wrap gap-2">
                              {s?.offline?.subjects?.map((sub, i) => (
                                <span
                                  key={i}
                                  className={`badge ${
                                    sub?.accessGranted
                                      ? "bg-success"
                                      : " bg-primary "
                                  } `}
                                >
                                  {sub?.subject?.name}
                                </span>
                              ))}
                            </div>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </PageLayout>
    </div>
  );
};

export default withRouter(StudentSittingsPage);
