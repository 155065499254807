import { withRouter } from "react-router-dom"
import React, {useGlobal, useState} from "reactn"
import PageLayout from "../../../components/page-layout/page-layout.component"
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import Button from "../../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import { Row,Col } from "react-bootstrap";

const JobPage =(props)=>{
    const [currentUser] = useGlobal("currentUser");
    const [state, setState] = useState({
        query: "",
        status: "",
    });

    const jobs=[
        {title:"Accountant" ,company:"B/B company", location:"Tema , Accra",posted_at:"11 min ago"},
        {title:"Manager" ,company:"B/B company", location:"Tema , Accra",posted_at:"11 min ago"},
        {title:"Designer" ,company:"B/B company", location:"Tema , Accra",posted_at:"11 min ago"},
        {title:"Marketing Manager" ,company:"B/B company", location:"Tema , Accra",posted_at:"11 min ago"},
        {title:"Security Guard" ,company:"B/B company", location:"Tema , Accra",posted_at:"11 min ago"}
    ]
    return(
        <PageLayout activeLink="home"
        parentMenu="home"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}>
            <Row>
                <Col md={7} style={{borderRight: "solid 1px #C4C4C4"}}>
                    <div className="text-30 font-500 mb-25">Job portal</div>
                    <div className="text-danger mb-20"> Fulltime . Remote . Contract .  Accra</div>
                    <div className="d-flex mb-30">
                        <div>
                            <div className="text-light">Salary</div>
                            <div className="text-grey font-500">GHS 400/m</div>
                        </div>
                        <div className="mx-2">
                            <div className="text-light">Expiry date</div>
                            <div className="text-grey font-500">1 Jan, 2022</div>
                        </div>
                    </div>
                    <div className="text-20 font-500 mb-10">Job description</div>
                    <div className="text-20 text-light font-500">
                        Creating user-centered designs by understanding business requirements, and user feedback. Creating user flows, wireframes, prototypes and mockups. Translating requirements into style guides, design systems, design patterns and attractive user interfaces
                    </div>
                    
                    <div className="text-20 font-500 mt-20 mb-15">Responsibilities</div>
                    <div className="text-20 text-light font-500">
                        <ul>
                            <li>Office management</li>
                             <li>Docummenting</li>
                        </ul>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="mt-40 text-23 font-500 mb-10 text-center">The Company limited</div>
                    <Button classes="danger f-width">Apply now</Button>
                </Col>
            </Row>
          
        </PageLayout>
    )
}


export default withRouter(JobPage)