import { withRouter } from "react-router-dom"
import React, {useGlobal, useState, useEffect} from "reactn"
import PageLayout from "../../components/page-layout/page-layout.component"
import ConfirmModal from "../../components/modal/confirm-modal/confirm-modal.component";
import Button from "../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import { deleteData, postData } from "../../api/api";
import { Row,Col } from "react-bootstrap";
import queryString from "query-string"

import InputField from "../../components/form/input-field/input-field.component";
import Select from "../../components/form/select/select.component"
import InformationTab from "./tabs/information.tab";
import SubjectsTab from "./tabs/subjects.tab";
import CheckoutTab from "./tabs/checkout.tab";
import { toast } from "react-toastify";

const ProgramRegistrationPage =(props)=>{
    const [currentUser] = useGlobal("currentUser");
    const [currentProgram,setCurrentProgram]=useGlobal("currentProgram")
    const [,setAmountPaid]=useGlobal("amountPaid")
    const [state, setState] = useState({
        query: "",
        status: "",
        tab:"information",
        processing: false,
    });

    const [info,setInfo]=useState({
        "level": 1,
        "lectureProgram": "",
        "lectureVenue": "N/A",
        "totalFee": 0,
        "sittingID": 0,
        "subjectIDs": []
    })

    const [selectedSubjects,setSelectedSubjects]=useState([])

    const [proceed, setProceed]=useState(false)
    
    const params=queryString.parse(window.location?.search)

    //check if already registered 
    const {data: studentSittingData, error}=useSWR(`tuition/sitting/student?programID=${currentProgram?.id}&active=false`,{
        onSuccess:(resp)=>{
            if(resp.studentSitting){
                //check if registering for intervention
                if(!params?.isIntervention){
                    props.history.push(`programs/${currentProgram?.tag}`)
                }
            }else{
                setProceed(true)
            }
        }
    })

    //get sitting data
    const {data: sittingData, error: sittingError}=useSWR(`tuition/sitting?programID=${currentProgram?.id}`)

    const {data: subjectsData, error: subjectsError}=useSWR(`tuition/subject?programID=${currentProgram?.id}&isIntervention=${params?.isIntervention || false}`)

    useEffect(() => {
        if(!currentProgram) props.history.goBack();
        if(params.tab) setState({...state, tab: params?.tab || "information" })
        
    }, [window.location?.search,props.history])

    
    const onRegister=(amountPaid)=>{

        const t=toast.loading("Processing...")
        setState({...state,processing: true})
        
        let subIDs=[]
        let totalFee=0
        selectedSubjects?.forEach((s)=>{
            subIDs.push(s.id)
            totalFee+=parseFloat(s.price)
        })
        
        let payload=params?.isIntervention ? 
        {
            studentSittingID: studentSittingData?.studentSitting?.id,
            subjectIDs: subIDs,
            totalFee: totalFee,
            amountPaid: amountPaid,
        } : 
        {
            ...info,
            subjectIDs: subIDs,
            totalFee: totalFee,
            amountPaid: amountPaid,
        }
        
        postData(`tuition/sitting/student${params?.isIntervention ? "/intervention" : ""}`,payload, currentUser?.token).then((resp)=>{
            setState({...state,processing: false})
            toast.dismiss(t)
            if(resp.success){
                toast.success("Registration successful.")
                props.history.push(`/programs/${currentProgram?.tag}`)
                setCurrentProgram(null)
                setAmountPaid(null)
                return;
            }
            toast.error(resp?.data || "Registration failed.")
        })
    }
    

    return(
        <PageLayout activeLink="home"
        parentMenu="home"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}>
          
           <div>
                <LoaderPane loading={!studentSittingData && !error} />
               {(studentSittingData || error )&&  <div>
                    <div hidden={state?.tab!=="information"  }>
                        <InformationTab 
                            program={currentProgram}
                            sittings={sittingData?.sittings}
                            onNext={(data)=>{
                                setInfo({...info,...data})
                                setState({...state, tab: "subjects"})
                            }} 
                        />
                    </div>
                    {state.tab =="subjects" &&<div>
                        <SubjectsTab program={currentProgram} 
                            loader={
                                <LoaderPane loading={!subjectsData && !subjectsError} 
                                noRecords={subjectsData && !subjectsError && subjectsData?.subjects.length===0}
                                noRecordsMessage={`No subjects for the selected level [${info?.level}]`}/>
                            }
                            info={info}
                            subjects={subjectsData?.subjects}
                            selectedSubjects={selectedSubjects}
                            onBack={()=>{
                                if(params.isIntervention) { 
                                    props.history.goBack()
                                } else { 
                                    setSelectedSubjects([])
                                    setState({...state, tab:"information"}) 
                               }
                            }}
                            onNext={(subjects)=>{
                                setState({...state, tab:"checkout"})
                                setSelectedSubjects(subjects)
                            }} 
                            /> 
                    </div>}

                    {state.tab==="checkout" &&<div>
                            <CheckoutTab 
                                program={currentProgram?.tag}  
                               /*  registerButton={(text)=>
                                    <Button classes="f-width danger mt-20" 
                                    loading={state.processing}
                                    disabled={state.processing}
                                     onClick={()=>onRegister()}> 
                                        {text}
                                    </Button>
                                } */
                                processing={state?.processing}
                                onRegister={(amountPaid)=>onRegister(amountPaid)}
                                subjects={selectedSubjects}
                                onBack={()=>{setState({...state, tab:"subjects"}) }} 
                                onMakeChanges={()=>{setState({...state, tab: params?.isIntervention ? "subjects" : "information"}) }} 
                                //onNext={()=>{props.history.replace(`/program-registration/${currentProgram?.tag}?tab=checkout`)}} 
                            />
                    </div>}
                </div>}
            </div>
        </PageLayout>
    )
}



export default withRouter(ProgramRegistrationPage)