import React, {useGlobal, useState, useEffect} from "reactn"
import { withRouter} from "react-router-dom"
import PageLayout from "../../components/page-layout/page-layout.component"
import ConfirmModal from "../../components/modal/confirm-modal/confirm-modal.component";
import Button from "../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import { deleteData, } from "../../api/api";
import ProgramCard from "../../components/cards/program-card/program-card.component";
import { Row,Col } from "react-bootstrap";
import { Facebook } from "react-content-loader";
import ProfileModal from "../../components/modal/profile-modal/profile-modal.component";


const HomePage =(props)=>{
    const [currentUser,setCurrentUser] = useGlobal("currentUser");
    const [,setCurrentProgram]=useGlobal("currentProgram")
    const [programs,setPrograms]=useGlobal("programs")
    const [state,setState]=useState({
        query: "",
        status: "",
        loading: true,
        openProfile: false,
    })

    const {
        data,error,
        mutate: mutatePrograms,
        isValdating
    } = useSWR((!programs || programs?.length===0) && `tuition/program`, {
        revalidateOnFocus: false,
        onSuccess: (data) => {
            setPrograms(data.programs);
        },
    });

    useEffect(()=>{
        if(programs?.length===0 || !programs){
            mutatePrograms()
        }

    },[])

    
    return(
        <div>
            <ProfileModal type="add"
                open={state.openProfile || !currentUser?.user.student} 
                student={currentUser?.user.student}
                currentUser={currentUser}
                onHide={()=>{
                    setState({...state, openProfile: false})
                }}
                onUpdated={(data)=>{
                    setCurrentUser({...currentUser,user:{...currentUser.user, student: data}})
                }}
            />
            <PageLayout activeLink="home"
            parentMenu="home"
            inputProps={{
            value: state.query,
            onChange: (e) => {
                setState({ ...state, query: e.target.value });
            },
            }}>
                <div className="mt-3">
                    
                   {programs?.length===0 && <div className="mx-3">
                        <LoaderPane loading={isValdating || (!data && !error) } noRecords={!isValdating && programs?.length===0} error={ error?.message}/>
                    </div>
}
                    {programs?.length >0 &&<div>
                        <div className="text-30 font-500">Hello {currentUser?.user?.student?.firstName}</div>
                        <div className="text-20 text-grey mb-20">What do you want to do?</div>
                        <div className="row">
                            {
                                programs?.map((program,i)=>(
                                    <Col md={6} key={i}>
                                        <ProgramCard 
                                            program={program}
                                            title={program.name}
                                            tag={program.tag}
                                            description={program.description}
                                            onClick={()=>{
                                                setCurrentProgram(program)
                                                if(program.tag==="ExecutiveTraining"){
                                                    //props.history.push(`/executive-program-registration?program=${program.tag}`)
                                                }else{
                                                    props.history.push(`/programs/${program.tag}`)
                                                }
                                            }}
                                        />
                                </Col>
                                ))
                            }
                            
                            {/* <Col md={6}>
                                <ProgramCard title="CITG Program" type="citg" description={<span>Requirements for Registration Studentship <br/> Registration</span>}
                                onClick={()=>{
                                    setCurrentProgram(programs?.find(p=>p.tag==="CITG"))
                                    props.history.push("/program-registration?program=CITG")
                                }}/>
                            </Col>
                            <Col md={6}>
                                <ProgramCard title="Executive Training Program"
                                type="training" 
                                description={<span>Requirements for Registration Studentship <br/> Registration</span> }
                                onClick={()=>{
                                    setCurrentProgram(programs?.find(p=>p.tag==="ExecutiveTraining"))
                                    props.history.push("/executive-program-registration?program=Executive Training")
                                }}/>
                            </Col> */}
                            <Col md={6}>
                                <ProgramCard title="Job Portal" tag="jp" description={<span>Check our job listing. Apply for as many jobs <br/> that suit you as possible.</span>}
                                onClick={()=>props.history.push(`/job-portal`)}/>
                            </Col>
                        </div>
                    </div>}
                </div>
            </PageLayout>
        </div>
    )
}


export default withRouter(HomePage)