import React, { Fragment } from "react";

const ChevronIcon = ({
  variant = "default", //active
  color = "white",
  size = 0,
  viewBox = "0 0 17 9",
}) => {
  const _color =
    variant === "default"
      ? "#3F3F3F"
      : variant === "active"
      ? "#FFFFFF"
      : color;
  return (
    <svg
      width={size || "17"}
      height={size || "9"}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8841 7.32496L16.0479 2.16121C16.2574 1.95043 16.375 1.66529 16.375 1.36808C16.375 1.07088 16.2574 0.785743 16.0479 0.57496C15.9433 0.469516 15.8188 0.385823 15.6818 0.328708C15.5447 0.271593 15.3976 0.242188 15.2491 0.242188C15.1006 0.242188 14.9536 0.271593 14.8165 0.328708C14.6794 0.385823 14.5549 0.469516 14.4504 0.57496L9.29785 5.74996C9.19327 5.8554 9.06884 5.93909 8.93175 5.99621C8.79466 6.05332 8.64762 6.08273 8.4991 6.08273C8.35059 6.08273 8.20355 6.05332 8.06645 5.99621C7.92936 5.93909 7.80494 5.8554 7.70035 5.74996L2.54785 0.57496C2.3375 0.363119 2.05161 0.243515 1.75308 0.24246C1.45454 0.241405 1.16782 0.358987 0.955973 0.569336C0.744131 0.779686 0.624526 1.06557 0.623471 1.36411C0.622416 1.66264 0.739999 1.94937 0.950349 2.16121L6.1141 7.32496C6.74692 7.95698 7.60472 8.31199 8.4991 8.31199C9.39348 8.31199 10.2513 7.95698 10.8841 7.32496Z"
        fill={_color}
      />
    </svg>
  );
};

export default ChevronIcon;
