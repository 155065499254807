import React from "react"
import   "./book.styles.scss"
import Icons from "../../../assets/icons"

const Book=(props)=>{
    return<div className="book" onClick={props.onClick}>
        <div className="book-image pointer" 
        style={{backgroundImage: `url(${props?.coverPhotoUrl})`}}
        ></div>
        <div className="bottom" hidden={props.hideBottom}>
            <div className="text-20 font-600 mt-20 text-truncate">{props.title || "Title"}</div>
            <div className="d-flex justify-content-between align-items-center">
            <div className="text-18 font-500">GHC {props.price || "0.00"}</div>
            <div className="book-icon" hidden={props.hideCart}><Icons.Busket/></div>
        </div>
        </div>
    </div>
}

export default  Book