import { withRouter } from "react-router-dom"
import React, {useGlobal, useState} from "reactn"
import PageLayout from "../../components/page-layout/page-layout.component"
import Button from "../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import { Row,Col } from "react-bootstrap";

const UpdatesPage =(props)=>{
    const [currentUser] = useGlobal("currentUser");
    const [state, setState] = useState({
        query: "",
        status: "",
    });

    const {data,error}=useSWR(`/notification`)

  
    return(
        <PageLayout activeLink="home"
        parentMenu="home"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}>
            <div className="text-30 font-500">Updates</div>
            <div className="text-20 text-grey mb-20"></div>
            <div className="row">
                <Col md={10}>
                    <LoaderPane loading={!data && !error} 
                    noRecordsMessage={"No updates."}
                    noRecords={!error && data?.notifications?.length === 0}/>
                    {
                        data?.notifications?.map((j,i)=>(
                            <div className="content-pane secondary mb-30" key={i}>
                                <Row>
                                    <Col md={12}>
                                        <div className="text-26 font-500">{j.heading}</div>
                                        <div className="text-18 font-500">{j.message}</div>
                                        <span className="text-light text-18 font-500 mt-10">{new Date(j.createdAt).toDateString()}</span>
                                    </Col>
                                </Row>
                            </div>
                        ))
                    }
                    
                </Col>
               
            </div>

        </PageLayout>
    )
}


export default withRouter(UpdatesPage)