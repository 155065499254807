import React,{useState, useEffect} from "reactn"
import Button from "../../../components/button/button.component";

import { Col, Row } from "react-bootstrap"
import InputField from "../../../components/form/input-field/input-field.component"
import Select from "../../../components/form/select/select.component"
import { useGlobal } from "reactn";
import ExpandablePane from "../../../components/expandable-pane/expandable-pane.component"
import SuccessErrorModal from "../../../components/modal/success-error-modal/success-error-modal.component"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify";


const CheckoutTab=(props)=>{
    const [currentUser]=useGlobal("currentUser")
    const [amountPaid,setAmountPaid]=useGlobal("amountPaid")
    const [selectedPayment,setSelectedPayment]=useState()

    
    const [payment,setPayment]=useState({
        type:"",//card or momo
        momo_number:"",
        momo_provider:"",
        expiry_date:"",
        cvv:"",
        card_number:"",
        amount: 0,
    })

    const [state,setState]=useState({
        openModal:false,
        modalSuccess:null,
        modalError: null,
        error: null,
        processing: false,
    })

    const handleChange=(e)=>{
        const {name,value} = e.target
        setPayment({...payment,[name]: value})
    }

    const total=()=>{
        let t=0
        props?.subjects?.forEach((item)=>(t+= parseFloat( item.price)))
        return t
    }


    const onPay=({amount, callback})=>{
        let handler = window.PaystackPop.setup({
            key: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
            email: currentUser?.user?.student?.emailAddress ,
            amount: amount * 100,
            currency: "GHS",
            ref: new Date().getTime(),
            callback: function (response) {
                toast.success("Payment successful.")
                setAmountPaid(amount)
                // this happens after the payment is completed successfully
                // var reference = response.reference;
                callback(amount);
                // Make an AJAX call to your server with the reference to verify the transaction
            },
            onClose: function () {
            //alert("Transaction was not completed, window closed.");
            toast.error("Transaction was not completed.")
            },
        });

        handler.openIframe();
    } 

   /*  const payProps={
        email,
        amount,
        publicKey,
        text: "Pay Now",
        onSuccess: () =>{
            toast.success("Payment successful.")
        }
       ,
        onClose: () => { toast.error("Transaction was not completed.")},
    } */

 

    return(
          <div>
            <SuccessErrorModal 
              open={state.openModal} 
              success={state.modalSuccess} 
              onHide={()=>{
                  setState({...state,openModal: false})
                  if(state.modalSuccess)props.history.push(`/home`)
                }}
              />
                <div className="text-30 mb-30 font-500">Checkout</div>
                <Row>
                    <Col md={6}>
                        <div className="text-25 font-500 text-grey mb-50">Purchase details</div>
                        {
                            props?.subjects?.map((sub,i)=>(
                                 <div className="mb-20" key={i}>
                                <div className="d-flex align-content-center justify-content-between">
                                    <div>
                                        <div className="text-20 font-500"> {sub.name}</div>
                                        <div className="text-15">
                                            <span>Level {sub.level}</span> &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>GHC {sub.price}</span>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                            ))
                        }
                        <div className="d-flex justify-content-between">
                            <div/>
                            <div className="text-23 font-500">Total</div>
                            <div className="text-23 font-500 px-3">{total()}</div>
                        </div>
                        <Row>
                            <Col md={6} className="mt-30">
                                <span className="text-grey pointer " onClick={()=>props.onBack()}> Previous step </span>
                            </Col>
                            <Col>
                                
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <div className="content-pane mt-20">     
                            <div className="mt-20"> 
                               
                                <Button classes="danger f-width"
                                disabled={props?.processing} loading={props?.processing}
                                 onClick={()=>{
                                    if(amountPaid){
                                         //skip payment and register
                                         props?.onRegister(amountPaid)
                                    }else{
                                        onPay({
                                            amount: total(),
                                            callback: props?.onRegister
                                        })  
                                    }
                                      
                                }}> Pay GHC {total()}</Button> 
                                <div className="text-23 font-500 text-grey mt-20 text-center pointer" onClick={props.onMakeChanges}>Make Changes</div>
                            </div>
                        
                        </div>
                    </Col>
                </Row>
            </div>
    )
}

export default withRouter(CheckoutTab)