import React from "react";
import { Fragment } from "react";

const BusketIcon = ({
  variant = "default", //active
  color = "white",
  size = 24,
  viewBox = "0 0 24 27",
}) => {
  const _color =
    variant === "default"
      ? "#3F3F3F"
      : variant === "active"
      ? "#FFFFFF"
      : color;
  return (
    <svg
      width={size}
      height={size + 3}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Fragment>
        <path
          d="M23 21.375H21V19.125C21 18.8266 20.8946 18.5405 20.7071 18.3295C20.5196 18.1185 20.2652 18 20 18C19.7348 18 19.4804 18.1185 19.2929 18.3295C19.1054 18.5405 19 18.8266 19 19.125V21.375H17C16.7348 21.375 16.4804 21.4935 16.2929 21.7045C16.1054 21.9155 16 22.2016 16 22.5C16 22.7984 16.1054 23.0845 16.2929 23.2955C16.4804 23.5065 16.7348 23.625 17 23.625H19V25.875C19 26.1734 19.1054 26.4595 19.2929 26.6705C19.4804 26.8815 19.7348 27 20 27C20.2652 27 20.5196 26.8815 20.7071 26.6705C20.8946 26.4595 21 26.1734 21 25.875V23.625H23C23.2652 23.625 23.5196 23.5065 23.7071 23.2955C23.8946 23.0845 24 22.7984 24 22.5C24 22.2016 23.8946 21.9155 23.7071 21.7045C23.5196 21.4935 23.2652 21.375 23 21.375Z"
          fill={_color}
        />
        <path
          d="M21 6.75H18C18 4.95979 17.3679 3.2429 16.2426 1.97703C15.1174 0.711159 13.5913 0 12 0C10.4087 0 8.88258 0.711159 7.75736 1.97703C6.63214 3.2429 6 4.95979 6 6.75H3C2.20435 6.75 1.44129 7.10558 0.87868 7.73851C0.31607 8.37145 0 9.22989 0 10.125L0 21.375C0.00158786 22.8663 0.528882 24.296 1.46622 25.3505C2.40356 26.405 3.67441 26.9982 5 27H14C14.2652 27 14.5196 26.8815 14.7071 26.6705C14.8946 26.4595 15 26.1734 15 25.875C15 25.5766 14.8946 25.2905 14.7071 25.0795C14.5196 24.8685 14.2652 24.75 14 24.75H5C4.20435 24.75 3.44129 24.3944 2.87868 23.7615C2.31607 23.1286 2 22.2701 2 21.375V10.125C2 9.82663 2.10536 9.54048 2.29289 9.3295C2.48043 9.11853 2.73478 9 3 9H6V11.25C6 11.5484 6.10536 11.8345 6.29289 12.0455C6.48043 12.2565 6.73478 12.375 7 12.375C7.26522 12.375 7.51957 12.2565 7.70711 12.0455C7.89464 11.8345 8 11.5484 8 11.25V9H16V11.25C16 11.5484 16.1054 11.8345 16.2929 12.0455C16.4804 12.2565 16.7348 12.375 17 12.375C17.2652 12.375 17.5196 12.2565 17.7071 12.0455C17.8946 11.8345 18 11.5484 18 11.25V9H21C21.2652 9 21.5196 9.11853 21.7071 9.3295C21.8946 9.54048 22 9.82663 22 10.125V15.75C22 16.0484 22.1054 16.3345 22.2929 16.5455C22.4804 16.7565 22.7348 16.875 23 16.875C23.2652 16.875 23.5196 16.7565 23.7071 16.5455C23.8946 16.3345 24 16.0484 24 15.75V10.125C24 9.22989 23.6839 8.37145 23.1213 7.73851C22.5587 7.10558 21.7956 6.75 21 6.75ZM8 6.75C8 5.55653 8.42143 4.41193 9.17157 3.56802C9.92172 2.72411 10.9391 2.25 12 2.25C13.0609 2.25 14.0783 2.72411 14.8284 3.56802C15.5786 4.41193 16 5.55653 16 6.75H8Z"
          fill={_color}
        />
      </Fragment>
    </svg>
  );
};

export default BusketIcon;
