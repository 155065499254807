import React, { useGlobal, useState, useEffect } from "reactn";
import { withRouter } from "react-router-dom";
import PageLayout from "../../../components/page-layout/page-layout.component";
import Button from "../../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import ProgramCard from "../../../components/cards/program-card/program-card.component";
import { Row, Col, Table } from "react-bootstrap";
import ProfileModal from "../../../components/modal/profile-modal/profile-modal.component";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import ExportButton from "../../../components/export-button/export-button.component";
import useAccessLevels from "../../../hooks/useAccessLevels";

const StudentsPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [, setCurrentProgram] = useGlobal("currentProgram");
  const [programs, setPrograms] = useGlobal("programs");
  const [state, setState] = useState({
    query: "",
    status: "",
    loading: true,
    openProfile: false,
    activeStudent: null,
  });

  const [paginate, setPaginate] = useState({
    page: 0, // index 0, add +1
    pageSize: 25,
  });

  const { data, error, mutate, isValdating } = useSWR(
    `student?query=${state?.query}&offset=${
      paginate.page * paginate.pageSize
    }&limit=${paginate.pageSize}`
  );

  const { hasAccess } = useAccessLevels(currentUser?.user?.accessLevels);
  const access = hasAccess("Students");

  return (
    <div>
      <ProfileModal
        open={state.openProfile}
        currentUser={currentUser}
        student={state?.activeStudent}
        onUpdated={() => {
          mutate();
        }}
        onHide={() => {
          setState({ ...state, openProfile: false, activeStudent: null });
        }}
        access={access}
      />
      <PageLayout
        activeLink="students"
        parentMenu="students"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            if (paginate.page > 0) {
              setPaginate({ ...paginate, page: 0 });
            }
            setState({ ...state, query: e.target.value });
          },
        }}
      >
        <div className="mt-3">
          <div className="d-flex space-between">
            <div className="text-24"> Students</div>
            {access?.write && (
              <div className="mt-1">
                <ExportButton
                  url={`student/export?query=${state.query}`}
                  fileName={`students-list_${new Date().toUTCString()}`}
                />
              </div>
            )}
          </div>

          <LoaderPane
            loading={isValdating || (!data && !error)}
            noRecords={!isValdating && data?.students?.length === 0}
            error={error?.message}
          />

          {data && (
            <div>
              <Table>
                <thead>
                  <tr>
                    <th>Reg. Number</th>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.students?.map((s) => (
                    <tr>
                      <td>{s.registrationNumber}</td>
                      <td>
                        {" "}
                        {s.firstName} {s.lastName}
                      </td>
                      <td>{s.phoneNumber}</td>
                      <td>{s.emailAddress}</td>
                      <td>
                        {" "}
                        <span
                          className="text-danger pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              openProfile: true,
                              activeStudent: s,
                            });
                          }}
                        >
                          View
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={5}>
                      <PaginationBar
                        page={paginate.page}
                        pageSize={paginate.pageSize}
                        totalItems={data?.count}
                        onChangePage={(newPage) => {
                          setPaginate({
                            ...paginate,
                            page: newPage,
                          });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setPaginate({
                            pageSize: size,
                            page: 0,
                          });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </PageLayout>
    </div>
  );
};

export default withRouter(StudentsPage);
