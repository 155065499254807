import React from "react";
import { Fragment } from "reactn";

const ArrowRightIcon = ({
  variant = "default", //active
  color = "white",
  size,
  viewBox = "0 0 37 37",
  large = false,
}) => {
  const _color =
    variant === "default"
      ? "#3F3F3F"
      : variant === "active"
      ? "#FFFFFF"
      : variant === "danger"
      ? "#D82427"
      : color;
  return (
    <Fragment>
      {large ? (
        <svg
          width={size || 37}
          height={size || 37}
          viewBox={viewBox}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M35.6433 15.2779L29.6771 9.24997C29.5338 9.10547 29.3633 8.99078 29.1754 8.91251C28.9875 8.83424 28.786 8.79395 28.5825 8.79395C28.379 8.79395 28.1775 8.83424 27.9896 8.91251C27.8017 8.99078 27.6312 9.10547 27.4879 9.24997C27.2008 9.53882 27.0396 9.92956 27.0396 10.3368C27.0396 10.7441 27.2008 11.1349 27.4879 11.4237L32.9762 16.9583H1.54167C1.13279 16.9583 0.740662 17.1207 0.451544 17.4098C0.162425 17.699 0 18.0911 0 18.5H0C0 18.9088 0.162425 19.301 0.451544 19.5901C0.740662 19.8792 1.13279 20.0416 1.54167 20.0416H33.0687L27.4879 25.6071C27.3434 25.7504 27.2287 25.9209 27.1505 26.1088C27.0722 26.2966 27.0319 26.4981 27.0319 26.7016C27.0319 26.9052 27.0722 27.1067 27.1505 27.2945C27.2287 27.4824 27.3434 27.6529 27.4879 27.7962C27.6312 27.9407 27.8017 28.0554 27.9896 28.1337C28.1775 28.212 28.379 28.2522 28.5825 28.2522C28.786 28.2522 28.9875 28.212 29.1754 28.1337C29.3633 28.0554 29.5338 27.9407 29.6771 27.7962L35.6433 21.8146C36.5094 20.9474 36.9959 19.7719 36.9959 18.5462C36.9959 17.3206 36.5094 16.1451 35.6433 15.2779Z"
            fill={_color}
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="14"
          viewBox="0 0 24 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.12 4.91L19.25 0.999999C19.157 0.906271 19.0464 0.831876 18.9246 0.781107C18.8027 0.730339 18.672 0.704201 18.54 0.704201C18.408 0.704201 18.2773 0.730339 18.1554 0.781107C18.0336 0.831876 17.923 0.906271 17.83 0.999999C17.6437 1.18736 17.5392 1.44081 17.5392 1.705C17.5392 1.96918 17.6437 2.22264 17.83 2.41L21.39 6H1C0.734784 6 0.48043 6.10536 0.292893 6.29289C0.105357 6.48043 0 6.73478 0 7H0C0 7.26522 0.105357 7.51957 0.292893 7.70711C0.48043 7.89464 0.734784 8 1 8H21.45L17.83 11.61C17.7363 11.703 17.6619 11.8136 17.6111 11.9354C17.5603 12.0573 17.5342 12.188 17.5342 12.32C17.5342 12.452 17.5603 12.5827 17.6111 12.7046C17.6619 12.8264 17.7363 12.937 17.83 13.03C17.923 13.1237 18.0336 13.1981 18.1554 13.2489C18.2773 13.2997 18.408 13.3258 18.54 13.3258C18.672 13.3258 18.8027 13.2997 18.9246 13.2489C19.0464 13.1981 19.157 13.1237 19.25 13.03L23.12 9.15C23.6818 8.5875 23.9974 7.825 23.9974 7.03C23.9974 6.235 23.6818 5.4725 23.12 4.91Z"
            fill={_color}
          />
        </svg>
      )}
    </Fragment>
  );
};

export default ArrowRightIcon;
