import React, { Fragment } from "react";

const AudioIcon = ({
  variant = "default", //active
  color = "white",
  size = 48,
  viewBox = "0 0 49 48",
}) => {
  const _color =
    variant === "default"
      ? "#3F3F3F"
      : variant === "active"
      ? "#FFFFFF"
      : color;
  return (
    <svg
      width={size + 1}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Fragment>
        <rect
          x="1"
          y="1"
          width="47"
          height="46"
          rx="3"
          stroke={_color}
          stroke-width="2"
        />
        <g clip-path="url(#clip0_266_5857)">
          <path
            d="M17.7031 38C20.0455 38 21.9512 36.0944 21.9512 33.752V20.6337L33.8457 16.1732V25.2583C33.1353 24.7235 32.2525 24.4063 31.2969 24.4063C28.9545 24.4063 27.0488 26.3119 27.0488 28.6543C27.0488 30.9967 28.9545 32.9024 31.2969 32.9024C33.6393 32.9024 35.5449 30.9967 35.5449 28.6543V9.84964C35.5449 9.57108 35.4084 9.31 35.1792 9.15136C34.9502 8.9925 34.6579 8.95622 34.3971 9.05423L20.8033 14.1519C20.4717 14.2762 20.252 14.5931 20.252 14.9473V30.356C19.5415 29.8212 18.6587 29.5039 17.7031 29.5039C15.3607 29.5039 13.4551 31.4096 13.4551 33.752C13.4551 36.0944 15.3607 38 17.7031 38ZM31.2969 31.2031C29.8915 31.2031 28.748 30.0597 28.748 28.6543C28.748 27.2489 29.8915 26.1055 31.2969 26.1055C32.7023 26.1055 33.8457 27.2489 33.8457 28.6543C33.8457 30.0597 32.7023 31.2031 31.2969 31.2031ZM21.9512 15.536L33.8457 11.0756V14.3585L21.9512 18.819V15.536ZM17.7031 31.2031C19.1085 31.2031 20.252 32.3466 20.252 33.752C20.252 35.1574 19.1085 36.3008 17.7031 36.3008C16.2977 36.3008 15.1543 35.1574 15.1543 33.752C15.1543 32.3466 16.2977 31.2031 17.7031 31.2031Z"
            fill={_color}
          />
        </g>
        <defs>
          <clipPath id="clip0_266_5857">
            <rect
              width="29"
              height="29"
              fill="white"
              transform="translate(10 9)"
            />
          </clipPath>
        </defs>
      </Fragment>
    </svg>
  );
};

export default AudioIcon;
