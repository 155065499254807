import { useState, useGlobal } from "reactn";
import { Modal, Row, Col, Badge } from "react-bootstrap";
import { useEffect } from "reactn";
import { deleteData, deleteData2, postData, putData } from "../../../api/api";
import Button from "../../button/button.component";
import SwitchButton from "../../form/toggle-button/toggle-button.component";
import InputField from "../../form/input-field/input-field.component";
import Select from "../../form/select/select.component";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { toast } from "react-toastify";
import useSWR from "swr";
import moment from "moment";

const SittingResourceModal = ({
  open,
  currentUser,
  selectedResources = [],
  resourceType = "document", //media or document
  onHide,
  onBlurHide = false,
  onEdit = () => {},
  onDelete = () => {},
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    processing: false,
    processingType: "",
    error: null,
    success: null,
    selectedSitting: null,
    selectedResources: [],
  });

  const reset = () => {
    setState({
      processing: false,
      processingType: "",
      error: null,
      success: null,
      selectedSitting: null,
      selectedResources: [],
    });
  };

  const addSittings = async (action = "add") => {
    setState({
      ...state,
      processing: true,
      processingType: action,
      error: null,
    });

    let endpoint = `tuition/sitting/${resourceType}/add`;
    for (var i = 0; i < state?.selectedResources.length; i++) {
      let data = {};
      if (resourceType == "document") {
        data = {
          documentID: state?.selectedResources[i].id,
          sittingID: state?.selectedSitting?.value,
        };
      } else {
        data = {
          mediaID: state?.selectedResources[i].id,
          sittingID: state?.selectedSitting?.value,
        };
      }
      const resp = await postData(`${endpoint}`, data, currentUser?.token);
      if (resp.success) {
        toast.success("Resource updated.");
      } else {
        toast.error("Failed to add resource.");
      }
    }
    onUpdated();
    setState({
      ...state,
      processing: false,
      processingType: "",
      error: null,
      success: null,
      selectedSitting: null,
      selectedResources: [],
    });
    onHide();

    //old

    /* let endpoint = `tuition/sitting/${resourceType}/add/multi`;

    let data = {};
    if (resourceType == "document") {
      data = {
        sittingDocuments: state?.selectedResources?.map((r) => ({
          documentID: r.id,
          sittingID: state?.selectedSitting?.value,
        })),
      };
    } else {
      data = {
        sittingMedias: state?.selectedResources?.map((s) => ({
          mediaID: s.id,
          sittingID: state?.selectedSitting?.value,
        })),
      };
    }

    const request = postData(`${endpoint}`, data, currentUser?.token);

    request.then((resp) => {
      if (resp.success) {
        onUpdated();
        setState({
          ...state,
          processing: false,
          processingType: "",
          error: null,
          success: null,
          selectedSitting: null,
          selectedResources: [],
        });
        onHide();
        toast.success("Resource updated.");
        return;
      }
      setState({
        ...state,
        processing: false,
        error:
          resp?.message ||
          `Unable to update Resource, Please fill all fields and check your internet connection.`,
      });
    }); */
  };

  const removeSittings = () => {
    setState({
      ...state,
      processing: true,
      processingType: "remove",
      error: null,
    });

    let endpoint = `tuition/sitting/${resourceType}/remove/multi`;

    let data = {};
    let allResources = [];

    if (resourceType == "document") {
      let sr = state.selectedResources;
      for (var i = 0; i < sr.length; i++) {
        let sittingDocuments = sr[i]?.sittingDocuments;
        sittingDocuments?.map((d) => {
          if (d?.sitting.id == state?.selectedSitting.value)
            allResources.push({
              documentID: d.id,
              sittingID: state?.selectedSitting.value,
            });
          return false;
        });
      }
      data = {
        sittingDocuments: allResources,
      };
    } else {
      let sr = state.selectedResources;
      for (var i = 0; i < sr.length; i++) {
        let sittingMedias = sr[i]?.sittingMedias;
        sittingMedias?.map((d) => {
          if (d?.sitting.id == state?.selectedSitting.value)
            allResources.push({
              mediaID: d.id,
              sittingID: state?.selectedSitting.value,
            });
          return false;
        });
      }

      data = {
        sittingMedias: allResources,
      };
    }

    const request = deleteData2(endpoint, data, currentUser?.token);

    request.then((resp) => {
      if (resp.success) {
        onUpdated();
        setState({
          ...state,
          processing: false,
          error: null,
          selectedResources: [],
        });
        onHide();
        toast.success("Resource updated.");
        return;
      }
      setState({
        ...state,
        processing: false,
        error:
          resp?.message ||
          `Unable to update Resource, Please fill all fields and check your internet connection.`,
      });
    });
  };

  const { data, error, mutate, isValdating } = useSWR(
    `tuition/sitting?active=false`
  );

  const sittingOptions = () => {
    if (!data) {
      return [];
    }
    return data?.sittings?.map((s) => ({
      label: sittingName(s),
      value: s.id,
    }));
  };

  useEffect(() => {
    setState({ ...state, error: null });
    if (selectedResources?.length > 0) {
      setState({ ...state, selectedResources: selectedResources });
    } else {
      setState({ ...state, selectedResources: [] });
    }
  }, [open]);

  return (
    <div>
      <Modal
        //className="custom-modal"
        show={open}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <div className="f-width d-flex space-between">
            <div className="title">Add/Remove Sitting Resource</div>
            <div
              onClick={() => {
                if (state.processing) return;
                reset();
                onHide();
              }}
            >
              <Close />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          <div className="row mb-50 mt-20">
            <div className="col-md-12 mb-20">
              <Select
                placeholder="Select Sitting"
                small
                label={"Sitting"}
                options={sittingOptions()}
                //value={[]}
                // isMulti
                value={state?.selectedSitting}
                onChange={(selected) => {
                  setState({ ...state, selectedSitting: selected });
                }}
              />
            </div>
            <div className="mt-20">
              <div>Selected resources</div>
              <div className="mt-10">
                {state?.selectedResources?.map((r) => (
                  <Badge className="badge-primary mr-10">
                    {r?.description}
                  </Badge>
                ))}
              </div>
            </div>
          </div>

          {state.error && (
            <div className="text-danger text-right mb-20">{state?.error}</div>
          )}
          <div className="col-md-12 mb-20">
            <div className="d-flex space-between">
              <Button
                classes="small danger"
                loading={state.processing && state?.processingType == "remove"}
                disabled={state.processing}
                onClick={() => removeSittings()}
              >
                Remove Sitting
              </Button>
              <Button
                classes="small danger"
                loading={state.processing && state?.processingType == "add"}
                disabled={state.processing}
                onClick={() => addSittings("add")}
              >
                Add Sitting
              </Button>
            </div>
          </div>
        </Modal.Body>

        {/*  <Modal.Footer></Modal.Footer> */}
      </Modal>
    </div>
  );
};
const sittingName = (s) =>
  `${moment(s.startAt).format("MMM, YYYY")} - ${moment(s.endAt).format(
    "MMM, YYYY"
  )} (${s?.program?.tag})`;

export default SittingResourceModal;
