import { useState ,useGlobal} from "reactn";
import { Modal, Row, Col } from "react-bootstrap";
import { useEffect } from "reactn";
import { deleteData, postData, putData } from "../../../api/api";
import Button from "../../button/button.component";
import SwitchButton from "../../form/toggle-button/toggle-button.component";
import InputField from "../../form/input-field/input-field.component";
import Select from "../../form/select/select.component";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { toast } from "react-toastify";
import useSWR from "swr";

const MoveResourceModal = ({
  open,
  currentUser,
  resource,
  subject,
  subjects=[],
  onHide,
  type = "add",
  onBlurHide = false,
  onEdit = () => {},
  onDelete = () => {},
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    action: type,
    processing: false,
    error: null,
    success: null,
  });

  const [_resource, _setResource] = useState({
    type:"",
    sSubject:"",
    dSubject:"",
    sFolder:"",
    dFolder:""
  });

 

  const subjectOptions=()=>{
    let s=[]
    subjects?.map(el=>s.push({"label":`${el.name}, [level ${el.level}] `, value: el.id}))
    return s
  }

  const reset = () => {
    setState({
      action: type,
      processing: false,
      error: null,
      success: null,
    });
  };

  const processResource = (action) => {
    setState({
      ...state,
      processing: true,
      error: null,
    });

    let endpoint="tuition/resource/move",
    data={
      resourceType: _resource.type,
      sourceFolder: _resource?.sFolder,
      destinationFolder: _resource.dFolder,

      sourceSubjectID: _resource.sSubject,
      destinationSubjectID:_resource.dSubject
    }
    
    console.log(data)
  
    const request =
      (type === "edit" || _resource?.id)
        ? putData(
            `${endpoint}/${_resource?.id}`,
            data,
            currentUser?.token
          )
        : postData(endpoint, data , currentUser?.token);

    request.then((resp) => {
      if (resp.success) {
        onUpdated(_resource?.type);
        setState({ ...state, processing: false, error: null });
        onHide();
        toast.success("Resource updated.")
        return;
      }
      setState({
        ...state,
        processing: false,
        error:
          resp?.message ||
          `Unable to update Resource, Please fill all fields and check your internet connection.`,
      });
    });
  };

  const folderOptions=()=>{
    let l=[{label:"Lecture Audios", value:"Lecture Audios"}]
    if(_resource?.type==="Video"){
      l=[{label:"Lecture Videos",value:"Lecture Videos"}, {label:"Revision Videos",value:"Revision Videos"}]
    }else if(_resource?.type==="PDF"){
       l=[
         {label:"Summary Notes",value:"Summary Notes"}, 
         {label:"Special Notes",value:"Special Notes"}, 
         {label:"Questions Bank",value:"Questions Bank"},
        {label:"ICAG Past Questions",value:"ICAG Past Questions"}
        ]
    }
    return l
  }

  const isValid=()=>(_resource?.type && _resource?.sSubject && _resource?.sFolder && _resource?.dFolder )

  const handleChange = (e) => {
    _setResource({
      ..._resource,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setState({ ...state, error: null });
    if (resource) {
     /*  _setResource({
         ...resource,
         type: resource?.mediaType || resource?.documentType, 
         folder: resource?.mediaFolder || resource?.documentFolder,
         url: resource?.mediaURL || resource?.documentURL
        }); */
    } else {
      _setResource(null);
    }
  }, [resource, open, type]);

  return (
    <div>
      <Modal
        //className="custom-modal"
        show={open}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <div className="f-width d-flex space-between">
            <div className="title">Move Resource</div>
            <div
              onClick={() => {
                if (state.processing) return;
                reset();
                onHide();
              }}
            >
              <Close />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          <div className="row mb-50 mt-20" hidden={type === "view"}>
            <div className="col-md-12 mb-20">
              <Select placeholder="Select type of file"
              small
              label={"File Type"}
              options={[{label:"PDF", value: "PDF"},{label:"Video" , value:"Video"},{label:"Audio" , value:"Audio"}]} 
              value={{label:_resource?.type, value: _resource?.type}}
              onChange={(selected)=>{
                  _setResource({..._resource, type: selected.value, folder: null})
              }}
              />
            </div>
            <div className="col-md-12 mb-20">
              <Select placeholder="Select Source(Subject)"
              small
              label={"Source(Subject)"}
              options={[...subjectOptions()]} 
              //value={{label:_resource?.type, value: _resource?.type}}
              onChange={(selected)=>{
                _setResource({..._resource, sSubject: selected.value})
              }}
              />
            </div>
            <div className="col-md-12 mb-20">
              <Select placeholder="Select Destination(Subject)"
              small
              label={"Destination(Subject)"}
              options={[...subjectOptions()]} 
              //value={{label:_resource?.type, value: _resource?.type}}
              onChange={(selected)=>{
                  _setResource({..._resource, dSubject: selected.value})
              }}
              />
            </div>
            
            <div className="col-md-6 mb-20">
              <Select placeholder="Select Source folder"
              small
              label={"Source Folder"}
              options={folderOptions()} 
              value={{label:_resource?.sFolder, value: _resource?.sFolder}}
              onChange={(selected)=>{
                  _setResource({..._resource, sFolder: selected.value})
              }}
              />
            </div>
            <div className="col-md-6 mb-20">
              <Select placeholder="Select Destination folder"
              small
              label={"Destination Folder"}
              options={folderOptions()} 
              value={{label:_resource?.dFolder, value: _resource?.dFolder}}
              onChange={(selected)=>{
                  _setResource({..._resource, dFolder: selected.value})
              }}
              />
            </div>
          </div>

          
        </Modal.Body>

        <Modal.Footer>
          {state.error && (
            <div className="text-danger text-right mb-20">{state?.error}</div>
          )}
          <div className="flex flex-end">
            {type !== "view" && (
              <Button
                classes="small danger"
                loading={state.processing}
                disabled={state.processing || !isValid()}
                onClick={processResource}
              >
                Move
              </Button>
            )}

          {/*   {type == "view" && (
              <Button
                classes="small"
                disabled={state.processing}
                onClick={onEdit}
              >
                Edit
              </Button>
            )} */}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MoveResourceModal;