import React, { useGlobal, useState } from "reactn";
import { Redirect , withRouter } from "react-router-dom";
import InputField from "../../components/form/input-field/input-field.component";
import Button from "../../components/button/button.component";
import "./reset-password.styles.scss";
import { ReactComponent as Logo } from "../../assets/icons/big-logo.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone.svg";
import { ReactComponent as Email } from "../../assets/icons/email.svg";
import { ReactComponent as Lock } from "../../assets/icons/lock.svg";
import { postData } from "../../api/api";
import { toast } from "react-toastify";
import queryString from "query-string"

const ResetPasswordPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");

  const [state, setState] = useState({
    processing: false,
    error: null,
    message:"",
    email: "",
    password: "",
    confirm_password:""
  });
  const params=queryString.parse(window.location?.search) 
  const token=params?.token

  //if (currentUser) return <Redirect to="/" />;

  const onProcess = () => {
    setState({ ...state, processing: true });
    const t= toast.loading("Please wait...")
    postData(
      `user/password/reset`,
      { emailAddress: state.email },
      currentUser?.token
    ).then((res) => {
      toast.dismiss(t)
     if (res.success) {
       setState({ ...state, processing: false, message:"Reset password link has been sent to email, Please follow that link to complete the process."});
        toast.success( "Reset password link has been sent to email, Please follow that link to complete the process.")
        return;
      }
      setState({ ...state, processing: false});
      toast.error("Failed to request password reset, \n Please check your email and try again");
    });
  };

  const onCreatePassword = () => {
    setState({ ...state, processing: true });
    const t= toast.loading("Please wait...")
    postData(
      `user/password/reset/confirm`,
      { resetToken: token,newPassword: state?.password },null
    ).then((res) => {
      toast.dismiss(t)
      if (res.success) {
        setState({ ...state, processing: false, message:"Please open the app and login with new password." });
        toast.success("Please open the app and login with new password.")
       // window.location.href="/login"
        return;
      }
      setState({ ...state, processing: false });
      toast.error(res?.message || "Failed to reset password reset, \n Please check your email and try again");
    });
  };

  
  

  return (
    <div className="reset-password-page">
      <div className="inner">
      <div className="row m-0 p-0">
        <div className="col-md-6 left-pane">
          <div>
            <div className="logo-div">
             <Logo />
            </div>
            <div className="left-header">
              ASPIRE PRO CONSULT
            </div>
          </div>
        </div>
        <div className="col-md-6 right-pane">
          <div className="right-pane-inner">
            {state?.message &&<div className="text-success">
                  {state.message}
              </div>}
            
            <div hidden={state?.message}>
              <div className="text-30 font-600 mb-20">
                Reset your <br />
                Password
              </div>
              {!token &&<div>
                <div className="text-20">
                  Enter your email address we will share a link to create a new password
                </div>
              
                <InputField
                leftIcon={<Email/>}
                  name="email"
                  classes="mt-40"
                  value={state.email}
                  label=""
                  placeholder="Email"
                  onChange={(e) => setState({ ...state, email: e.target.value })}
                />
                {state.error && (
                  <div className="text-danger mt-20">{state.error}</div>
                )}
                <Button
                  classes="f-width danger mt-30"
                  disabled={ state.processing ||
                    state.email.length == 0
                  }
                  loading={state.processing}
                  onClick={onProcess}
                >
                  Reset Password
                </Button>
              </div>}

              {token &&<div>
                <div className="text-20">
                  Enter a new password
                </div>
                <InputField
                leftIcon={<Lock/>}
                  name="password"
                  classes="mt-40"
                  value={state.password}
                  label=""
                  type="password"
                  placeholder="Enter new password"
                  onChange={(e) => setState({ ...state, password: e.target.value })}
                />
                <InputField
                leftIcon={<Lock/>}
                  name="confirm_password"
                  classes="mt-40"
                  value={state.confirm_password}
                  label=""
                  type="password"
                  placeholder="Confirm  password"
                  onChange={(e) => setState({ ...state, confirm_password: e.target.value })}
                />
                {state.error && (
                  <div className="text-danger mt-20">{state.error}</div>
                )}
                <Button
                  classes="f-width danger mt-30"
                  disabled={
                  state.processing ||
                    state.password.length == 0 ||
                    state.confirm_password.length == 0 ||
                    (state.password != state.confirm_password)
                  }
                  loading={state.processing}
                  onClick={onCreatePassword}
                >
                  Create new password
                </Button>
              </div>}

              <div hidden className="text-center mt-40" onClick={()=>props.history.push("/login")}>I have an account? <span className="text-danger pointer">Sign in</span> </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default withRouter(ResetPasswordPage);
