import { withRouter } from "react-router-dom"
import React, {useGlobal, useState} from "reactn"
import PageLayout from "../../components/page-layout/page-layout.component"
import Button from "../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import { Row,Col } from "react-bootstrap";

const JobPortalPage =(props)=>{
    const [currentUser] = useGlobal("currentUser");
    const [state, setState] = useState({
        query: "",
        status: "",
    });

    const jobs=[
        {title:"Accountant" ,company:"B/B company", location:"Tema , Accra",posted_at:"11 min ago"},
        {title:"Manager" ,company:"B/B company", location:"Tema , Accra",posted_at:"11 min ago"},
        {title:"Designer" ,company:"B/B company", location:"Tema , Accra",posted_at:"11 min ago"},
        {title:"Marketing Manager" ,company:"B/B company", location:"Tema , Accra",posted_at:"11 min ago"},
        {title:"Security Guard" ,company:"B/B company", location:"Tema , Accra",posted_at:"11 min ago"}
    ]
    return(
        <PageLayout activeLink="home"
        parentMenu="home"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}>
            <div className="text-30 font-500">Job portal</div>
            <div className="text-20 text-grey mb-20">Find your desired job and apply</div>
            <div className="row">
                <Col md={10}>
                    {
                        jobs.map((j)=>(
                            <div className="content-pane secondary mb-30">
                        <Row>
                            <Col md={7}>
                                <div className="text-26 font-500">{j.title}</div>
                                <div className="text-18 font-500">{j.company}<span className="text-light"> {j.location}</span></div>
                                <span className="text-light text-18 font-500 mt-10">{j.posted_at}</span>
                            </Col>
                            <Col md={5}>
                                <div className="d-flex align-items-center justify-content-evenly mt-20">
                                    <div><Button classes="small danger f-width" onClick={()=>props.history.push(`/job/${j.title}`)}>Apply now</Button></div>
                                    <div className="text-danger text-23 font-500 pointer mx-1" onClick={()=>props.history.push(`/job/${j.title}`)}>View&nbsp;Details</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                        ))
                    }
                    
                </Col>
               
            </div>

        </PageLayout>
    )
}


export default withRouter(JobPortalPage)