import { withRouter } from "react-router-dom"
import React, {useGlobal, useState,useEffect} from "reactn"
import PageLayout from "../../components/page-layout/page-layout.component"
import Button from "../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import { Row,Col } from "react-bootstrap";
import queryString from "query-string"
import ReactPlayer from 'react-player/lazy'
import { useRef } from "react";


const MediaPage =(props)=>{
    const [currentUser] = useGlobal("currentUser");
    const [mxx]=useGlobal("mxx")

    const params=queryString.parse(window.location?.search)

    const [state, setState] = useState({
        query: "",
        status: "",
    });
    
    //const decodedURL=Buffer.from(, 'base64').toString()
    
    //const url = URL.createObjectURL(myMediaSource);
    const uri=(url)=>{
      //const decode =Buffer.from(mxx, 'base64').toString()
      const myMediaSource = new MediaSource(url);
      return  URL.createObjectURL(myMediaSource);
    }

    const [url,setURL]=useState(null)
    useEffect(()=>{
      if(!mxx)props.history.goBack();
      setURL(Buffer.from(mxx, 'base64').toString())
      
    },[]);
 
    return(
        <PageLayout activeLink="home"
          parentMenu="home"
          inputProps={{
            value: state.query,
            onChange: (e) => {
              setState({ ...state, query: e.target.value });
            },
          }}>

           {mxx && <div className="mt-20">
            { (params?.type==="video" || params?.type==="audio" )&&
              <ReactPlayer 
                style={{maxWidth:"100%"}}
                controls
                config={{ 
                  file: { 
                    attributes: {
                      onContextMenu: e => e.preventDefault(),
                      controlsList: 'nodownload'
                    } 
                  } 
                }}
                url={url} 
              />
              }
              
              <div className="text-30 mt-20 font-500">{params?.title}</div>
              <div className="text-20 text-grey mb-20">{params?.description}</div>
            </div>}

        </PageLayout>
    )
}


export default withRouter(MediaPage)