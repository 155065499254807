import ContentLoader, { Facebook } from 'react-content-loader'
import { Spinner,Row,Col } from "react-bootstrap";

const CardLoader=({rows=1, columns=2})=>(
  <ContentLoader width={"100%"}>
    {/* Pure SVG */}
    <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
    <rect x="80" y="17" rx="4" ry="4" width="100%" height="13" />
    <rect x="80" y="40" rx="3" ry="3" width="100%" height="10" />
    <rect x="80" y="60" rx="2" ry="2" width="100%" height="20" />
  </ContentLoader>
)

const PageLoader=({rows=1, columns=2})=>(
  <>
  <ContentLoader width={"100%"}>
    {/* Pure SVG */}
    <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
    <rect x="80" y="17" rx="2" ry="2" width="100%" height="20" />
    <rect x="80" y="50" rx="2" ry="2" width="100%" height="20" />
    <rect x="0" y="80" rx="2" ry="2" width="100%" height="20" />
    <rect x="0" y="110" rx="2" ry="2" width="49%" height="40" />
    <rect x="51%" y="110" rx="2" ry="2" width="50%" height="40" />
  </ContentLoader>
  <div className="mt-40">
    <ContentLoader width={"100%"}>
    {/* Pure SVG */}
    <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
    <rect x="80" y="17" rx="2" ry="2" width="100%" height="20" />
    <rect x="80" y="50" rx="2" ry="2" width="100%" height="20" />
    <rect x="0" y="80" rx="2" ry="2" width="100%" height="20" />
    <rect x="0" y="110" rx="2" ry="2" width="49%" height="40" />
    <rect x="51%" y="110" rx="2" ry="2" width="50%" height="40" />
  </ContentLoader>
  </div>
  
  </>
  
)

const Loaders={
  card: <CardLoader/>,
  page: <PageLoader/>
}

const Loader=({type="page", rows=1, columns=2})=>{
    const L=Loaders?.[type] || <div className="d-flex justify-content-center w-100"> <Spinner animation="border" variant="primary" /> </div>
    return(
      L
    )
}

export default Loader