import React, { Fragment } from "react";

const FileIcon = ({
  variant = "default", //active
  color = "white",
  size = 37,
  viewBox = "0 0 37 48",
}) => {
  const _color =
    variant === "default"
      ? "#3F3F3F"
      : variant === "active"
      ? "#FFFFFF"
      : color;
  return (
    <svg
      width={size}
      height={size + 10}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Fragment>
        <path
          d="M28.0568 0H3.39496C1.73905 0 0.390625 1.32182 0.390625 2.94363V45.0559C0.390625 46.6801 1.73905 47.9999 3.39496 47.9999H33.6034C35.2608 47.9999 36.608 46.6801 36.608 45.0559V9.10191L28.0568 0ZM28.7659 4.40479L32.514 8.39515H28.7659V4.40479ZM34.0847 45.056C34.0847 45.3152 33.868 45.5281 33.6021 45.5281H3.39496C3.12944 45.5281 2.914 45.3152 2.914 45.056V2.94363C2.914 2.68373 3.12944 2.4731 3.39496 2.4731H26.2412V9.6316C26.2412 10.3132 26.808 10.8657 27.5042 10.8657H34.0847V45.056ZM7.36526 13.1468H28.7928C29.3148 13.1468 29.7382 13.5616 29.7382 14.0729C29.7382 14.5842 29.3148 14.999 28.7928 14.999H7.36526C6.84339 14.999 6.41991 14.5842 6.41991 14.0729C6.41991 13.5617 6.84339 13.1468 7.36526 13.1468ZM29.7382 20.2961C29.7382 20.8073 29.3148 21.2221 28.7928 21.2221H7.36526C6.84339 21.2221 6.41991 20.8074 6.41991 20.2961C6.41991 19.7847 6.84339 19.37 7.36526 19.37H28.7928C29.3148 19.37 29.7382 19.7848 29.7382 20.2961ZM29.7382 26.3154C29.7382 26.8267 29.3148 27.2415 28.7928 27.2415H7.36526C6.84339 27.2415 6.41991 26.8267 6.41991 26.3154C6.41991 25.8041 6.84339 25.3894 7.36526 25.3894H28.7928C29.3148 25.3894 29.7382 25.8041 29.7382 26.3154ZM29.7382 32.4373C29.7382 32.9486 29.3148 33.3633 28.7928 33.3633H7.36526C6.84339 33.3633 6.41991 32.9486 6.41991 32.4373C6.41991 31.9259 6.84339 31.5112 7.36526 31.5112H28.7928C29.3148 31.5112 29.7382 31.9259 29.7382 32.4373Z"
          fill={_color}
        />
      </Fragment>
    </svg>
  );
};

export default FileIcon;
