import React, { useGlobal, useState } from "reactn";
import { Redirect , withRouter } from "react-router-dom";
import InputField from "../../components/form/input-field/input-field.component";
import Button from "../../components/button/button.component";
import "./signup.styles.scss";
import { ReactComponent as Logo } from "../../assets/icons/big-logo.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone.svg";
import { ReactComponent as Email } from "../../assets/icons/email.svg";
import { ReactComponent as Lock } from "../../assets/icons/lock.svg";
import { register } from "../../api/services/account.service";
import { toast } from 'react-toastify';

const SignupPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");

  const [state, setState] = useState({
    processing: false,
    error: null,
    username: "",
    password: "",
    agree: false
  });

  //if (currentUser) return <Redirect to="/" />;

  const Signup = () => {

    setState({ ...state, processing: true, error: null });
    register(state.email, state.password).then((response) => {
      if (response.success) {
        setState({ ...state, processing: false });
        toast.success("Registration successful, Please login with your email and password.")
        //setCurrentUser(response.user);
        props.history.push(`/login`)
        return;
      }
      setState({
        ...state,
        processing: false,
        error: response.message || "Failed to register",
      });
    });
  };

  return (
    <div className="login-page">
      <div className="inner">
      <div className="row m-0 p-0">
        <div className="col-md-6 left-pane">
          <div>
            <div className="logo-div">
             <Logo />
            </div>
            <div className="left-header">
              ASPIRE PRO CONSULT
            </div>
          </div>
        </div>
        <div className="col-md-6 right-pane">
          <div className="right-pane-inner">
            <div className="text-12">
            </div>
            <div className="text-30 font-600 mb-20">
              Create  your <br />
              account
            </div>
            {/* <InputField
            leftIcon={<User/>}
              name="fullname"
              value={state.fullname}
              label=""
              placeholder="Fullname"
              onChange={(e) => setState({ ...state, fullname: e.target.value })}
            /> */}
             
             <InputField
             leftIcon={<Email/>}
              name="email"
              classes="mt-20"
              value={state.email}
              label=""
              placeholder="Email"
              onChange={(e) => setState({ ...state, email: e.target.value })}
            />
            {/* <InputField
            leftIcon={<Phone/>}
              classes="mt-20"
              name="phone"
              value={state?.phone}
              label=""
              placeholder="Phone number"
              type="tel"
              onChange={(e) => setState({ ...state, password: e.target.value })}
            /> */}
            <InputField
            leftIcon={<Lock/>}
              name="password"
              type="password"
              classes="mt-20"
              value={state?.password}
              label=""
              placeholder="Password"
              onChange={(e) => setState({ ...state, password: e.target.value })}
            />

            <div className="d-flex mt-20">
             <div className="mt-1"> <input type="checkbox"  checked={state.agree}
             onChange={(e)=>{
               setState({...state, agree: e.target.checked})
             }}/></div> <div className="text-15 mt-1 px-2"> I agree with <span className="text-danger pointer">Terms & Condition</span></div>
            </div>
            
            {state.error && (
              <div className="text-danger mt-20">{state.error}</div>
            )}
            <Button
            
              classes="f-width danger mt-30"
              disabled={
                state.processing ||
                state.email?.length ===0 ||
                state.password?.length ===0 || !state.agree
              }
              loading={state.processing}
              onClick={Signup}
            >
              Sign Up
            </Button>

            <div className="text-center mt-40" onClick={()=>props.history.push("/login")}>I have an account? <span className="text-danger pointer">Sign in</span> </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default withRouter(SignupPage);
