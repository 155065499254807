import React, { useGlobal, useState, useEffect } from "reactn";
import { withRouter } from "react-router-dom";
import PageLayout from "../../../../components/page-layout/page-layout.component";
import ConfirmModal from "../../../../components/modal/confirm-modal/confirm-modal.component";
import Button from "../../../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../../../components/loader-pane/loader-pane.component";
import { deleteData, putData } from "../../../../api/api";
import { Row, Col, Table } from "react-bootstrap";

import PaginationBar from "../../../../components/pagination-bar/pagination-bar.component";
import SwitchButton from "../../../../components/form/toggle-button/toggle-button.component";
import { toast } from "react-toastify";
import queryString from "query-string";
import StudentSittingModal from "../../../../components/modal/student-sitting-modal/student-sitting-modal.component";
import ExportButton from "../../../../components/export-button/export-button.component";
import useAccessLevels from "../../../../hooks/useAccessLevels";

const ViewSittingPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  //const [currentSitting]=

  const [state, setState] = useState({
    query: "",
    status: "",
    loading: false,
    openModal: false,
    processing: false,
    processingType: "",
    activeStudentSitting: null,
  });
  const [paginate, setPaginate] = useState({
    page: 0, // index 0, add +1
    pageSize: 25,
  });

  const sittingID = props.match.params.id;

  const parsed = queryString.parse(props.location.search);
  const name = parsed?.name;
  const program = parsed?.program;

  const url = `tuition/sitting/student/list?query=${state?.query}&offset=${
    paginate.page * paginate.pageSize
  }&limit=${paginate.pageSize}&sittingID=${sittingID}`;
  const { data, error, mutate, isValdating } = useSWR(url);

  const { hasAccess } = useAccessLevels(currentUser?.user?.accessLevels);
  const access = hasAccess("Sittings");

  const processsAccess = (sitting, value, index, type) => {
    setState({ ...state, processing: sitting.id, processingType: type });
    let url = type
      ? `tuition/sitting/student/${type}/access`
      : "tuition/sitting/student/access";
    let id =
      type === "intervention"
        ? sitting.intervention.id
        : type === "modular"
        ? sitting.modular.id
        : type === "offline"
        ? sitting.offline.id
        : sitting.id;
    putData(url, { id: id, access_granted: value }, currentUser?.token).then(
      (resp) => {
        if (resp.success) {
          let sittings = data?.studentSittings;

          let newSitting = {};
          if (type === "intervention") {
            newSitting = {
              ...sitting,
              intervention: resp.data.studentIntervention,
            };
          } else if (type === "modular") {
            newSitting = {
              ...sitting,
              modular: resp.data.studentModular,
            };
          } else if (type === "offline") {
            newSitting = {
              ...sitting,
              offline: resp.data.studentOffline,
            };
          } else {
            newSitting = resp.data.studentSitting;
          }
          let c = sittings.splice(index, 1, newSitting);
          //console.log("\n sitting",sittings[[index]])
          let nData = { count: data?.count, studentSittings: sittings };
          console.log(newSitting, resp.data);
          // mutate(nData,false)
          mutate();
          setState({ ...state, processing: false, processingType: "" });
          return;
        }
        setState({ ...state, processing: false, processingType: "" });
        toast.error(resp.message || "Unable to update Sitting access.");
      }
    );
  };

  return (
    <div>
      <StudentSittingModal
        open={state.openModal}
        currentUser={currentUser}
        onHide={() => {
          setState({
            ...state,
            openModal: false,
            activeStudentSitting: null,
          });
        }}
        sitting={state.activeStudentSitting}
        onUpdated={() => {
          mutate();
        }}
        access={access}
      />
      <PageLayout
        activeLink="sittings"
        parentMenu="sittings"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}
      >
        <div className="mt-3">
          <div className="d-flex space-between">
            <div className="text-24">
              {name} {program} Sitting Registration
            </div>
            {access?.write && (
              <div className="mt-1">
                <ExportButton
                  url={`tuition/sitting/student/export?sittingID=${sittingID}&program=${program}`}
                  fileName={`${name} ${program} Sitting`}
                />
              </div>
            )}
          </div>

          <LoaderPane
            loading={isValdating || (!data && !error)}
            noRecords={!isValdating && data?.sittings?.length === 0}
            error={error?.message}
          />

          {data && (
            <div>
              <Table striped>
                <thead>
                  <tr>
                    <th>Student Info</th>
                    <th>Total Fee</th>
                    <th>Amount Paid</th>
                    <th>Discount</th>
                    <th>Tuition </th>
                    <th>Intervention </th>
                    <th>Modular </th>
                    <th>Offline </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.studentSittings?.map((s, i) => (
                    <tr key={i}>
                      <td>
                        {s.student.firstName} {s.student.lastName} <br />
                        <span className="text-grey">
                          {s.student.emailAddress} <br />{" "}
                          {s.student.phoneNumber}
                        </span>
                      </td>

                      <td>
                        {s?.totalFee +
                          (s?.intervention?.totalFee || 0) +
                          (s?.modular?.totalFee || 0) +
                          (s?.offline?.totalFee || 0)}
                      </td>
                      <td>
                        {s?.amountPaid +
                          (s?.intervention?.amountPaid || 0) +
                          (s?.modular?.amountPaid || 0) +
                          (s?.offline?.amountPaid || 0)}
                      </td>
                      <td>
                        {s?.discount +
                          (s?.intervention?.discount || 0) +
                          (s?.modular?.discount || 0) +
                          (s?.offline?.discount || 0)}
                      </td>
                      <td>
                        <SwitchButton
                          checked={
                            s.accessGranted ||
                            (state.processing === s.id &&
                              state.processingType !== "intervention")
                          }
                          disabled={state.processing || !access?.write}
                          loading={
                            state.processing === s.id &&
                            state.processingType !== "intervention"
                          }
                          onChange={(value) => {
                            console.log(value);
                            processsAccess(s, value, i);
                            //let n=selected?.filter((item)=>(item.name !== sub.name))
                          }}
                        />
                      </td>
                      <td>
                        {s.intervention ? (
                          <SwitchButton
                            checked={
                              s.intervention.accessGranted ||
                              (state.processing === s?.intervention?.id &&
                                state.processsAccess === "intrvention")
                            }
                            disabled={state.processing || !access?.write}
                            loading={
                              state.processing === s?.intervention?.id &&
                              state.processingType === "intervention"
                            }
                            onChange={(value) => {
                              console.log(value);
                              processsAccess(s, value, i, "intervention");
                              //let n=selected?.filter((item)=>(item.name !== sub.name))
                            }}
                          />
                        ) : (
                          "Not registered"
                        )}
                      </td>
                      <td>
                        {s.modular ? (
                          <SwitchButton
                            checked={
                              s.modular.accessGranted ||
                              (state.processing === s?.modular?.id &&
                                state.processsAccess === "modular")
                            }
                            disabled={state.processing || !access?.write}
                            loading={
                              state.processing === s?.modular?.id &&
                              state.processingType === "modular"
                            }
                            onChange={(value) => {
                              console.log(value);
                              processsAccess(s, value, i, "modular");
                              //let n=selected?.filter((item)=>(item.name !== sub.name))
                            }}
                          />
                        ) : (
                          "Not registered"
                        )}
                      </td>
                      <td>
                        {s.offline ? (
                          <SwitchButton
                            checked={
                              s.offline.accessGranted ||
                              (state.processing === s?.offline?.id &&
                                state.processsAccess === "offline")
                            }
                            disabled={state.processing || !access?.write}
                            loading={
                              state.processing === s?.offline?.id &&
                              state.processingType === "offline"
                            }
                            onChange={(value) => {
                              console.log(value);
                              processsAccess(s, value, i, "offline");
                              //let n=selected?.filter((item)=>(item.name !== sub.name))
                            }}
                          />
                        ) : (
                          "Not registered"
                        )}
                      </td>
                      <td>
                        <span
                          className="text-danger font-500 pointer"
                          onClick={() => {
                            setState({
                              ...state,
                              activeStudentSitting: s,
                              openModal: true,
                            });
                          }}
                        >
                          View
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={9}>
                      <PaginationBar
                        page={paginate.page}
                        pageSize={paginate.pageSize}
                        totalItems={data?.count}
                        onChangePage={(newPage) => {
                          setPaginate({
                            ...paginate,
                            page: newPage,
                          });
                        }}
                        onChangeRowsPerPage={(size) => {
                          setPaginate({
                            pageSize: size,
                            page: 0,
                          });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </PageLayout>
    </div>
  );
};

export default withRouter(ViewSittingPage);
