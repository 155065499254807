import styled from "styled-components";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

export const SelectContainer = styled.div`
  background-color: ${(props) =>
    props.transparent ? "transparent" : "#ffffff"};
  border-radius: 10px;
  height: ${(props) =>
    props.isMulti ? "auto" : props.small ? "53px" : "64px"};

  padding-top: ${(props) => (props.small ? "5px" : "10px")};
  transition: border-color 0.6s linear;
  border: 1px solid rgba(63, 63, 63, 0.2);
  box-sizing: border-box;

  :hover {
    border-color: rgba(216, 36, 39, 0.27);
  }
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomSelect = styled(Select)`
  width: 100%;
  border: 0;
`;

export const CustomCreatableSelect = styled(CreatableSelect)`
  width: 100%;
  border: 0;
`;

export const Label = styled.label`
  font-size: 14px;
  line-height: 16px;
  color: #565656;
  margin-bottom: 13px;
`;

export const IconDiv = styled.label`
  ${(props) => (props.right ? "padding-left: 20px" : "padding-right: 20px")};
  margin: auto 0px;
`;

/* custom styles for select input */
export const Styles = {
  input: (styles, props) => ({
    ...styles,
    width: "100%",
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#303037",
    fontWeight: "bold",
    background: "transparent",
    border: 0,
    outline: "none",
    padding: "0px 20px",
    margin: 0,
  }),
  control: (styles) => ({
    ...styles,
    width: "100%",
    border: "0",
    margin: "0px",
    padding: "0px",
    boxShadow: "none",
    background: "transparent",
  }),
  container: (styles) => ({
    ...styles,
    border: "0",
    margin: "0",
    padding: "0px",
  }),
  placeholder: (styles) => ({
    ...styles,
    margin: "0",
    fontWeight: 500,
    fontSize: "17px",
    lineHeight: "25px",
    padding: "0px",
    /*  whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis', */
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    maxHeight: "40px",
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: "0px 20px",
    margin: "0",
    fontSize: "16px",
    color: "#05051B",
    fontWeight: "normal",
  }),
  menu: (styles) => ({
    ...styles,
    background: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 0px 14px 10px rgba(142, 142, 142, 0.08)",
  }),
  menuList: (styles) => ({
    ...styles,
    borderRadius: "0px",
    fontSize: "14px",
    color: "#000000",
  }),
};
