import { Fragment, useGlobal, useEffect } from "reactn";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter,
  withRouter,
} from "react-router-dom";

import LoginPage from "../pages/login/login.page";
import SignupPage from "../pages/signup/signup.page";
import ForogtPasswordPage from "../pages/reset-password/reset-password.page";
import HomePage from "../pages/home/home.page";
import ProgramRegistrationPage from "../pages/program-registration/program-registration.page";
import ExecutiveRegistrationPage from "../pages/program-registration/executive/executive-registration.page";
import JobPortalPage from "../pages/job-portal/job-portal.page";
import JobPage from "../pages/job-portal/job/job.page";
import ProgramPage from "../pages/program/program.page";
import AuthenticatedRoutes from "./authenticated";
import MediaPage from "../pages/media/media.page";
import ProgramsPage from "../pages/programs/programs.page";
//import ICAGProgramPage from "../pages/programs/ICAG/icag.page";
//import CITGProgramPage from "../pages/programs/view-program/view-program.page";
import ExecutiveTrainingPage from "../pages/programs/executive-training/executive-training.page";
import AccountPage from "../pages/account/account.page";
import UpdatesPage from "../pages/updates/updates.page";
import BookStorePage from "../pages/book-store/book-store.page";

const AppRoutes = (props) => {
  const [currentUser] = useGlobal("currentUser");

  useEffect(() => {
    props.history.replace(window.history.href);
    return () => {};
  }, [currentUser?.user?.id]);

  return <Fragment> </Fragment>;
};

export default withRouter(AppRoutes);
