import React from "react"
import Icons from "../../../assets/icons"
import "./program-card.styles.scss"

const ProgramCard=({title,description,tag="icag",onClick=()=>{}})=>{
     
    return(
       
        <div className="program-card" onClick={onClick}>
            <div className={`image-pane ${tag}`}>
                <div className="bottom">
                    <div className="text-20 font-600 title text-truncate">{title}</div>
                    <div className="icon_description d-flex justify-content-between align-items-center">
                        <div className="text-15 description ">{description}</div>
                        <div className="icon">
                            <Icons.ArrowRight  variant={"danger"}/>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default ProgramCard