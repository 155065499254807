import { useState, useGlobal } from "reactn";
import { Modal, Row, Col, Table } from "react-bootstrap";
import { useEffect } from "reactn";
import { deleteData, postData, putData } from "../../../api/api";
import Button from "../../button/button.component";
import SwitchButton from "../../form/toggle-button/toggle-button.component";
import InputField from "../../form/input-field/input-field.component";
import Select from "../../form/select/select.component";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { toast } from "react-toastify";
import moment from "moment";

const StudentSittingModal = ({
  open,
  currentUser,
  sitting,
  onHide,
  access,
  type = "add",
  onBlurHide = false,
  onEdit = () => {},
  onDelete = () => {},
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    action: type,
    processing: false,
    processsAccess: "",
    error: null,
    success: null,
    shouldUpdate: false,
  });

  const [_sitting, _setSitting] = useState({});

  const reset = () => {
    setState({
      action: type,
      processing: false,
      error: null,
      success: null,
      processsAccess: "",
      shouldUpdate: false, // when closing modal
    });
  };

  const handleChange = (e) => {
    _setSitting({
      ..._sitting,
      [e.target.name]: e.target.value,
    });
  };

  //selected subjects
  const [selectedTuitions, setSelectedTuitions] = useState([]);
  const [selectedInterventions, setSelectedInterventions] = useState([]);
  const [selectedModulars, setSelectedModulars] = useState([]);
  const [selectedOfflines, setSelectedOfflines] = useState([]);

  useEffect(() => {
    setState({ ...state, error: null, shouldUpdate: state.shouldUpdate });
    if (sitting) {
      _setSitting({ ...sitting });

      if (sitting?.subjects?.length > 0) {
        let ts = sitting?.subjects; /* ?.filter(
          (s) => s.accessGranted === true || s.partialAccess === true
        ); */
        setSelectedTuitions(ts);
      }

      if (sitting?.intervention?.subjects?.length > 0) {
        let ins = sitting?.intervention?.subjects;
        setSelectedInterventions(ins);
      }

      if (sitting?.modular?.subjects?.length > 0) {
        let mds = sitting?.modular?.subjects;
        setSelectedModulars(mds);
      }

      if (sitting?.offline?.subjects?.length > 0) {
        let ofs = sitting?.offline?.subjects;
        setSelectedOfflines(ofs);
      }
    } else {
      _setSitting(null);
      setSelectedTuitions([]);
      setSelectedInterventions([]);
      setSelectedModulars([]);
      setSelectedOfflines([]);
    }
  }, [sitting, open, type]);

  const student = _sitting?.student;
  const intervention = _sitting?.intervention;
  const modular = _sitting?.modular;
  const offline = _sitting?.offline;

  const processsAccess = (index, subject, value, partialvalue, type) => {
    setState({
      ...state,
      processing: subject.id,
      processingType: type,
      shouldUpdate: true,
    });
    let url = type
      ? `tuition/sitting/student/${type}/subject/access`
      : "tuition/sitting/student/subject/access";
    let id = subject.id;

    if (type === "intervention") {
      let newList = selectedInterventions;
      newList[index] = {
        ...subject,
        accessGranted: value,
        partialAccess: partialvalue,
      };
      setSelectedInterventions([...newList]);
    } else if (type === "modular") {
      let newList = selectedModulars;
      newList[index] = {
        ...subject,
        accessGranted: value,
        partialAccess: partialvalue,
      };
      setSelectedModulars([...newList]);
    } else if (type === "offline") {
      let newList = selectedOfflines;
      newList[index] = {
        ...subject,
        accessGranted: value,
        partialAccess: partialvalue,
      };
      setSelectedOfflines([...newList]);
    } else {
      let newList = selectedTuitions;
      newList[index] = {
        ...subject,
        accessGranted: value,
        partialAccess: partialvalue,
      };
      setSelectedTuitions([...newList]);
    }

    putData(
      url,
      { id: id, access_granted: value, partial_access: partialvalue },
      currentUser?.token
    ).then((resp) => {
      setState({ ...state, processing: false, processingType: "" });
      if (resp.success) {
        return;
      }

      if (type === "intervention") {
        let newList = selectedInterventions;
        newList[index] = { ...subject };
        setSelectedInterventions([...newList]);
      } else if (type === "modular") {
        let newList = selectedModulars;
        newList[index] = { ...subject };
        setSelectedModulars([...newList]);
      }
      if (type === "offline") {
        let newList = selectedOfflines;
        newList[index] = { ...subject };
        setSelectedOfflines([...newList]);
      } else {
        let newList = selectedTuitions;
        newList[index] = { ...subject };
        setSelectedTuitions([...newList]);
      }

      toast.error(
        resp.message ||
          `Unable to update Sitting access for ${subject?.subject.name}.`
      );
    });
  };

  return (
    <div>
      <Modal
        className="modal-large"
        show={open}
        onHide={() => {
          if (onBlurHide) {
            onHide();
          }
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <div className="f-width d-flex space-between">
            <div className="title">
              {" "}
              [{student?.firstName} {student?.lastName}] Sitting Registration
              for {_sitting?.sitting?.description} [
              {moment(_sitting?.sitting?.startAt).format("MMMM, YYYY")} -{" "}
              {moment(_sitting?.sitting?.endAt).format("MMMM, YYYY")}]
            </div>
            <div
              onClick={() => {
                if (state.processing) return;
                onUpdated();
                reset();
                onHide();
              }}
            >
              <Close />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          <div className="row mt-30">
            <div className="col-md-6">
              <div className="text-15 text-grey-light">Firstname</div>
              <div className="text-15 text-grey mb-10">
                {student?.firstName}
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-15 text-grey-light">Lastname</div>
              <div className="text-15 text-grey mb-10">{student?.lastName}</div>
            </div>
            <div className="col-md-6">
              <div className="text-15 text-grey-light">Email</div>
              <div className="text-15 text-grey mb-10">
                {student?.emailAddress}
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-15 text-grey-light">Phone</div>
              <div className="text-15 text-grey  mb-10">
                {student?.phoneNumber}
              </div>
            </div>
          </div>
          {_sitting?.subjects?.length > 0 && (
            <div>
              <p className="mt-30">Selected Tuition Subjects</p>
              <div className="row" style={{ marginBottom: "-10px" }}>
                <div className="col-md-4">
                  <div className="text-15 text-grey-light">Total fee</div>
                  <div className="text-15 text-grey">
                    {_sitting?.totalFee || "0.00"}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-15 text-grey-light">Amount Paid</div>
                  <div className="text-15 text-grey">
                    {_sitting?.amountPaid || "0.00"}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-15 text-grey-light">Discount</div>
                  <div className="text-15 text-grey">
                    {_sitting?.discount || "0.00"}
                  </div>
                </div>
              </div>

              <Table>
                <thead>
                  <tr>
                    <th>SUBJECT</th>
                    <th>ACCESS</th>
                    <th>PARTIAL ACCESS </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedTuitions.map((s, i) => {
                    //console.log(selectedSub);
                    return (
                      <tr key={i}>
                        <td>{s.subject.name}</td>
                        <td>
                          <SwitchButton
                            checked={s?.accessGranted}
                            disabled={state.processing || !access?.write}
                            loading={state.processing === s?.id}
                            onChange={(value) => {
                              processsAccess(
                                i,
                                s,
                                !s?.accessGranted,
                                s.partialAccess
                              );
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            onChange={() => {
                              processsAccess(
                                i,
                                s,
                                !s.partialAccess ? true : s.accessGranted,
                                s.partialAccess ? false : true
                              );
                            }}
                            disabled={state.processing || !access?.write}
                            value={s?.partialAccess}
                            checked={s?.partialAccess}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}

          {_sitting?.intervention?.subjects?.length > 0 && (
            <div className="mt-30">
              <p>Selected Intervention Subjects</p>
              <div className="row" style={{ marginBottom: "-10px" }}>
                <div className="col-md-4">
                  <div className="text-15 text-grey-light">Total fee</div>
                  <div className="text-15 text-grey">
                    {_sitting?.intervention?.totalFee || "0.00"}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-15 text-grey-light">Amount Paid</div>
                  <div className="text-15 text-grey">
                    {_sitting?.intervention?.amountPaid || "0.00"}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-15 text-grey-light">Discount</div>
                  <div className="text-15 text-grey">
                    {_sitting?.intervention?.discount || "0.00"}
                  </div>
                </div>
              </div>
              <Table striped>
                <thead>
                  <tr>
                    <th>SUBJECT</th>
                    <th>ACCESS</th>
                    <th>PARTIAL ACCESS </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedInterventions?.map((s, i) => (
                    <tr key={i}>
                      <td>{s.subject.name}</td>
                      <td>
                        <SwitchButton
                          checked={s.accessGranted}
                          disabled={state.processing || !access?.write}
                          loading={
                            state.processing === s?.id &&
                            state.processingType === "intervention"
                          }
                          onChange={(value) => {
                            processsAccess(
                              i,
                              s,
                              !s.accessGranted,
                              s.partialAccess,
                              "intervention"
                            );
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={() => {
                            processsAccess(
                              i,
                              s,
                              !s.partialAccess ? true : s.accessGranted,
                              !s.partialAccess,
                              "intervention"
                            );
                          }}
                          disabled={state.processing || !access?.write}
                          value={s?.partialAccess}
                          checked={s?.partialAccess}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
          {_sitting?.modular?.subjects?.length > 0 && (
            <div className="mt-30">
              <p>Selected Modular Subjects</p>
              <div className="row" style={{ marginBottom: "-10px" }}>
                <div className="col-md-4">
                  <div className="text-15 text-grey-light">Total fee</div>
                  <div className="text-15 text-grey">
                    {_sitting?.modular?.totalFee || "0.00"}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-15 text-grey-light">Amount Paid</div>
                  <div className="text-15 text-grey">
                    {_sitting?.modular?.amountPaid || "0.00"}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-15 text-grey-light">Discount</div>
                  <div className="text-15 text-grey">
                    {_sitting?.modular?.discount || "0.00"}
                  </div>
                </div>
              </div>
              <Table striped>
                <thead>
                  <tr>
                    <th>SUBJECT</th>
                    <th>ACCESS</th>
                    <th>PARTIAL ACCESS</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedModulars.map((s, i) => (
                    <tr key={i}>
                      <td>{s.subject.name}</td>
                      <td>
                        <SwitchButton
                          checked={s.accessGranted}
                          disabled={state.processing || !access?.write}
                          loading={
                            state.processing === s?.id &&
                            state.processingType === "modular"
                          }
                          onChange={(value) => {
                            processsAccess(
                              i,
                              s,
                              !s.accessGranted,
                              s.partialAccess,
                              "modular"
                            );
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={() => {
                            processsAccess(
                              i,
                              s,
                              !s.partialAccess ? true : s.accessGranted,
                              !s.partialAccess,
                              "modular"
                            );
                          }}
                          disabled={state.processing || !access?.write}
                          value={s?.partialAccess}
                          checked={s?.partialAccess}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
          {_sitting?.offline?.subjects?.length > 0 && (
            <div className="mt-30">
              <p>Selected Offline Subjects</p>
              <div className="row" style={{ marginBottom: "-10px" }}>
                <div className="col-md-4">
                  <div className="text-15 text-grey-light">Total fee</div>
                  <div className="text-15 text-grey">
                    {_sitting?.offline?.totalFee || "0.00"}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-15 text-grey-light">Amount Paid</div>
                  <div className="text-15 text-grey">
                    {_sitting?.offline?.amountPaid || "0.00"}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-15 text-grey-light">Discount</div>
                  <div className="text-15 text-grey">
                    {_sitting?.offline?.discount || "0.00"}
                  </div>
                </div>
              </div>
              <Table striped>
                <thead>
                  <tr>
                    <th>SUBJECT</th>
                    <th>ACCESS</th>
                    <th>PARTIAL ACCESS</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedOfflines?.map((s, i) => (
                    <tr key={i}>
                      <td>{s.subject.name}</td>
                      <td>
                        <SwitchButton
                          checked={s.accessGranted}
                          disabled={state.processing || !access?.write}
                          loading={
                            state.processing === s?.id &&
                            state.processingType === "offline"
                          }
                          onChange={(value) => {
                            processsAccess(
                              i,
                              s,
                              !s.accessGranted,
                              s?.partialAccess,
                              "offline"
                            );
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={() => {
                            processsAccess(
                              i,
                              s,
                              !s.partialAccess ? true : s.accessGranted,
                              !s.partialAccess,
                              "offline"
                            );
                          }}
                          disabled={state.processing || !access?.write}
                          value={s?.partialAccess}
                          checked={s?.partialAccess}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          {state.error && (
            <div className="text-danger text-right mb-20">{state?.error}</div>
          )}
          <div hidden className="flex flex-end">
            {type !== "view" && (
              <Button
                classes="small danger"
                loading={state.processing}
                disabled={
                  !access?.write ||
                  state.processing ||
                  _sitting?.lastName?.length === 0 ||
                  _sitting?.firstName?.length === 0
                }
                //onClick={processsitting}
              >
                Save
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StudentSittingModal;
