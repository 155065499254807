import Switch from "react-switch";
import { Spinner } from "react-bootstrap";

const SwitchButton = ({
  label = null,
  checked = false,
  onChange = () => {},
  loading = false,
  ...otherProps
}) => {
  return (
    <>
      {label && (
        <div style={{fontSize: "14px", lineHeight: "16px",color: "#565656" ,marginBottom: "13px" }}>{label}</div>
      )}
      <Switch
        checked={checked}
        onChange={onChange}
        uncheckedIcon={null}
        checkedIcon={null}
        onHandleColor={"#FFFFFF"}
        offHandleColor={"#FFFFFF"}
        offColor={"#C4C4C4"}
        onColor={"#26B023"}
        handleDiameter={24}
        height={26}
        {...otherProps}
      />

      {loading && (
        <div style={{ marginTop:"-26px" }}>
          <Spinner animation="grow" variant="success" />
        </div>
      )}
    </>
  );
};

export default SwitchButton;
