import { withRouter } from "react-router-dom"
import React, {useGlobal, useState} from "reactn"
import PageLayout from "../../components/page-layout/page-layout.component"
import Button from "../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import { Row,Col } from "react-bootstrap";
import queryString from "query-string"
import Book from "../../components/cards/book/book.component"
import CheckoutTab from "./checkout.tab";

const BookStorePage =(props)=>{
    const [currentUser] = useGlobal("currentUser");

    const params=queryString.parse(window.location?.search)

    const [state, setState] = useState({
        query: "",
        status: "",
        showBook: false,
        selectedBook: null,
        tab:""
    });

    
    return(
        <PageLayout activeLink="book-store"
        parentMenu="home"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}>

          <div className="mt-20" hidden={state.tab==="checkout"}> 
          { state.showBook &&<div className="mb-20">
            <div className="text-grey  mt-40 mb-40 pointer" onClick={()=>setState({showBook: false})}>Go back</div>
              <Row>
                <Col md={3}>
                  <Book name="book" amount="300" hideBottom onClick={()=>{}}/>
                </Col>
                <Col md={9}>
                  <div className="d-flex justify-content-between">
                    <div className="text-25 font-500">Torn by Paul Clarke</div>
                    <div className="text-23 font-bold">GHC 200</div>
                  </div>
                  <div className="text-23 font-600 mt-10 mb-10">Description</div>
                  <div className="text-17 mb-40">
                      Torn is another stunning book from Paul Clarke filled with that edgy thought-provoking angst that teens seem to love and which strangely brought back memories of French lessons reading Sartre. Jean Paul had many a hero torn between the cushy option and doing what they knew to be right - and this is where Cat Clarke's heroine Alice finds herself. Should Alice tell all? Does Alice even know all?
                  </div>
                  <Row>
                    <Col md={9}>
                      <Button classes="danger f-width" onClick={()=>setState({...state,tab:"checkout"})}>Buy Book</Button>
                    </Col>
                    <Col md={3}/>
                  </Row>
                </Col>
                
              </Row>
              
            </div>}

            <div hidden={state.showBook} >
              <div className="text-30 font-500 mb-40">{params?.program || "" } Book store</div>
                <Row>
                  <Col md={3}>
                    <Book name="book" amount="300" onClick={()=>{setState({...state, showBook: true})}}/>
                  </Col>
                  <Col md={3}>
                    <Book name="book" amount="300" onClick={()=>{setState({...state, showBook: true})}}/>
                  </Col>
                  <Col md={3}>
                    <Book name="book" amount="300" onClick={()=>{setState({...state, showBook: true})}}/>
                  </Col>
                  <Col md={3}>
                    <Book name="book" amount="300" onClick={()=>{setState({...state, showBook: true})}}/>
                  </Col>
                  <Col md={3}>
                    <Book name="book" amount="300" onClick={()=>{setState({...state, showBook: true})}}/>
                  </Col>
                  <Col md={3}>
                    <Book name="book" amount="300" onClick={()=>{setState({...state, showBook: true})}}/>
                  </Col>
                  <Col md={3}>
                    <Book name="book" amount="300" onClick={()=>{setState({...state, showBook: true})}}/>
                  </Col>
                  <Col md={3}>
                    <Book name="book" amount="300" onClick={()=>{setState({...state, showBook: true})}}/>
                  </Col>
                </Row>
            </div>
          </div>

          {state.tab==="checkout"&&<div className="mt-20">
            <CheckoutTab program={params.program} onBack={()=>{setState({...state,tab:""})}} />
          </div>}

        </PageLayout>
    )
}


export default withRouter(BookStorePage)