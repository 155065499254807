import React, { useState, useEffect } from "reactn";

import queryString from "query-string";

const PaystackPage = (props) => {
  const params = queryString.parse(window.location?.search);
  const email = params?.email;
  const amount = parseFloat(params?.amount || 0);
  const ref = params?.ref;
  const status = params?.status || "";

  useEffect(() => {
    if (status) return;
    onPay({ amount, email, ref: ref || new Date().getMilliseconds() });
  }, []);

  const onPay = ({ amount, email, ref }) => {
    let handler = window.PaystackPop.setup({
      key: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
      email: email,
      amount: amount * 100,
      currency: "GHS",
      ref: ref,
      callback: function (response) {
        // this happens after the payment is completed successfully
        // var reference = response.reference;
        window.location.href = `/paystack?status=successful&ref=${response?.reference}`;
      },
      onClose: function () {
        window.location.href = `/paystack?status=cancelled`;
      },
    });

    handler.openIframe();
  };

  return <div></div>;
};

export default PaystackPage;
