// import error messages
import ERRORS from "../errors";

import { apiBaseUrl, postData, putData, createConfig } from "../api";
// import axios
const axios = require("axios").default;

export const authenticate = async (username, password) => {
  // initiate request
  try {
    let response = await axios.post(`${apiBaseUrl}user/authenticate`, {
      username,
      password,
    });

    return {
      success: true,
      user: response.data,
      message: response.message,
    };
  } catch (error) {
    console.log(error);
    if (error.response) {
      if (error.response.status === 401) {
        return { success: false, message: error.response.data.message };
      }

      if (error.response.status === 400) {
        return {
          success: false,
          message: error.response.data.error,
        };
      }

      return { success: false, message: ERRORS.UNKNOWN_SERVER_ERROR };
    }

    // could not contact server or could not receive a response
    return { success: false, message: ERRORS.UNKNOWN_ERROR };
  }
};

export const register = async (username, password) => {
  // initiate request
  try {
    let response = await axios.post(`${apiBaseUrl}user/register`, {
      username,
      password,
    });

    return {
      success: true,
      user: response.data,
      message: response.message,
    };
  } catch (error) {
    console.log(error);
    if (error.response) {
      if (error.response.status === 401) {
        return { success: false, message: error.response.data.message };
      }

      if (error.response.status === 400) {
        return {
          success: false,
          message: error.response.data.error,
        };
      }

      return { success: false, message: ERRORS.UNKNOWN_SERVER_ERROR };
    }

    // could not contact server or could not receive a response
    return { success: false, message: ERRORS.UNKNOWN_ERROR };
  }
};
