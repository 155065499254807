import React, { useGlobal, useState, useEffect } from "reactn";
import { withRouter } from "react-router-dom";
import PageLayout from "../../../components/page-layout/page-layout.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";

import { Row, Col, Table } from "react-bootstrap";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import UserModal from "../../../components/modal/user-modal/user-modal.component";
import useAccessLevels from "../../../hooks/useAccessLevels";

const AdminUsersPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [state, setState] = useState({
    activeTab: 0,
    query: "",
    status: "",
    loading: true,
    openModal: false,
    activeBook: null,
    activeOrder: null,
    openOrderModal: false,
  });

  const [paginate, setPaginate] = useState({
    page: 0, // index 0, add +1
    pageSize: 30,
  });

  const { data, error, mutate, isValdating } = useSWR(
    `user?query=${state?.query}&offset=${
      paginate.page * paginate.pageSize
    }&limit=${paginate.pageSize}`
  );

  const { hasAccess } = useAccessLevels(currentUser?.user?.accessLevels);
  const access = hasAccess("Users");
  const [userModal, setUserModal] = useState({ open: false, user: null });

  return (
    <div>
      <PageLayout
        activeLink="users"
        parentMenu="users"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}
      >
        <div className="mt-3">
          <div className="text-24 mb-20">
            Users
            {access?.write && (
              <span
                className="text-danger text-16 font-500 mx-3 pointer"
                onClick={() => {
                  setUserModal({ open: true, user: null });
                }}
              >
                Add New Admin
              </span>
            )}
          </div>

          <div>
            <LoaderPane
              loading={isValdating || (!data && !error)}
              noRecords={!isValdating && data?.users?.length === 0}
              error={error?.message}
            />

            {data && (
              <div>
                <Table>
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Access Levels</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.users?.map((s, i) => (
                      <tr key={i}>
                        <td>{s?.username}</td>
                        <td>{new Date(s.createdAt).toDateString()}</td>
                        <td>{s?.isActive ? "Active" : "Inactive"}</td>
                        <td>{s?.accessLevels ? "Activated" : "N/A"}</td>
                        <td>{s.status}</td>
                        <td>
                          <span
                            className="text-danger font-500 pointer"
                            onClick={() => {
                              setUserModal({ open: true, user: s });
                            }}
                          >
                            View
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={8}>
                        <PaginationBar
                          page={paginate.page}
                          pageSize={paginate.pageSize}
                          totalItems={
                            data?.complaints?.length < paginate.pageSize
                              ? paginate.pageSize
                              : paginate.pageSize * (paginate.page + 1)
                          }
                          onChangePage={(newPage) => {
                            setPaginate({
                              ...paginate,
                              page: newPage,
                            });
                          }}
                          onChangeRowsPerPage={(size) => {
                            setPaginate({
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
      </PageLayout>
      {userModal.open && (
        <UserModal
          open={userModal.open}
          user={userModal?.user}
          currentUser={currentUser}
          onHide={() => {
            setUserModal({ open: false, user: null });
          }}
          onUpdated={() => {
            mutate();
          }}
          access={access}
        />
      )}
    </div>
  );
};

export default withRouter(AdminUsersPage);
