import React ,{useState,useGlobal,useEffect} from "reactn"
import Button from "../../../components/button/button.component";
import { Col, Row } from "react-bootstrap"
import InputField from "../../../components/form/input-field/input-field.component"
import Select from "../../../components/form/select/select.component"
import { toast } from "react-toastify";
import { fetchData, postData } from "../../../api/api";
import { withRouter } from "react-router-dom";
import useSWR from "swr";


const InformationTab=(props)=>{

    const [state, setState] = useState({
        level: null,
        sittingID: null,
        lectureProgram: null,
        lectureVenue: "N/A",
    })

   const levels= props?.program?.tag == "CITG" ? 
   [
       {label:"Level 1", value:1},
        {label:"Level 2", value:2},
    ] :
    [
        {label:"Level 1", value:1},
        {label:"Level 2", value:2},
        {label:"Level 3", value:3} 
    ]

    const lecturePrograms=props?.program?.tag == "CITG" ? 
    [
        {value:"Face-to-Face/Lecture Room",label:"Face-to-Face/Lecture Room"},
        {value:"E-Learning",label:"E-Learning"},
    ]:
    [
        {value:"Face-to-Face/Lecture room",label:"Face-to-Face/Lecture Room"},
        {value:"E-Learning",label:"E-Learning"},
        {value:"Revision Master Class",label:"Revision Master Class"}
    ]

    const sittings = []
    const formatter = new Intl.DateTimeFormat('en-us', { month: 'short' })
    props.sittings?.forEach(s => sittings.push({
        value: s.id,
        label: `${formatter.format(new Date(s.startAt))} ${new Date(s.startAt).getFullYear()} - ${formatter.format(new Date(s.endAt))} ${new Date(s.endAt).getFullYear()}`
    }))


    return(
          <div>
                {props.program && <div>
                    <div className="text-30 font-500">Register {props.program.tag}</div>
                    <div className="text-20 text-grey mb-40">Enter your details to register for  {props.program.name} <br/></div>
                    <Row>
                        <Col md={9}>
                            <div className="mb-25">
                                <Select placeholder="Sitting" 
                                label={"Sitting"}
                                options={sittings} 
                                onChange={(selected)=>setState({...state,sittingID: selected.value})}/>
                            </div>
                            <div className="mb-25">
                                <Select placeholder="Level" 
                                label={"Level"}
                                options={levels} 
                                    onChange={(selected)=>setState({...state,level: selected.value})}/>
                            </div>
                            <div className="mb-25">
                                <Select placeholder="Lecture Program"
                                label={"Lecture Program"}
                                options={lecturePrograms} 
                                onChange={(selected)=>{
                                    setState({...state,lectureProgram: selected.value, lectureVenue: "N/A"})
                                }}
                                />
                            </div>
                            {(state.lectureProgram !== "E-Learning") &&
                            <div className="mb-25">
                                <Select placeholder="Lecture Venue"
                                label={"Lecture Venue"}
                                options={[
                                    {value:"Weekend - Kwabenya",label:" Weekend - Kwabenya"},
                                    {value:"Weekday - Accra Central",label:"Weekday - Accra Central"}
                                    ]} 
                                    onChange={(selected)=>setState({...state,lectureVenue: selected.value})}
                                />
                            </div>}
                            <Row>
                                <Col md={8}/>
                                <Col>
                                <Button classes="f-width danger mt-20" disabled={!state.sittingID || !state.level ||  !state.lectureProgram} onClick={()=>props?.onNext(state)}>Next</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>}
            </div>
    )
}

export default withRouter( InformationTab)