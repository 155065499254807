import { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useEffect } from "reactn";
import { deleteData, postData, putData } from "../../../api/api";
import Button from "../../button/button.component";
import InputField from "../../form/input-field/input-field.component";
import Select from "../../form/select/select.component";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { toast } from "react-toastify";

const ProfileModal = ({
  open,
  currentUser,
  student,
  onHide,
  type = "add",
  onBlurHide = false,
  onEdit = () => {},
  onDelete = () => {},
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    action: type,
    processing: false,
    error: null,
    success: null,
    changeEmail: false,
    email:"",
    changingEmail: false
  });

  const [_student, _setStudent] = useState({
    "firstName": "",
    "lastName": "",
    "phoneNumber": "",
    "whatsAppNumber": "",
    "emailAddress": student? student?.emailAddress : currentUser?.user?.username,
    "address": "",
    "workplace": "",
    "registrationNumber": ""
  });


  const reset = () => {
    setState({
      action: type,
      processing: false,
      changingEmail: false,
      error: null,
      success: null,
    });
  };

  const processstudent = (action) => {
    setState({
      ...state,
      processing: true,
      error: null,
    });

    const request =
     (type === "edit" || _student?.id)
        ? putData(
            `student/${_student?.id}`,
            {
              ..._student,
              //status: _student?.status === "Active" ? "Inactive" : "Active",
            },
            currentUser?.token
          )
        : postData(`student`, {..._student }, currentUser?.token);

    request.then((resp) => {
      if (resp.success) {
        setState({ ...state, processing: false, error: null });
        onHide();
        onUpdated(resp.data.student);
        toast.success("Profile updated.")
        return;
      }
      setState({
        ...state,
        processing: false,
        error:
          resp?.message ||
          `Unable to update profile, Please fill all fields and check your internet connection.`,
      });
    });
  };

  const changeEmail=()=>{
    if(state.changingEmail || state.email?.length<3)return;
    setState({...state, changingEmail: true, error: null})

    putData("user/change-email",{emailAddress: student?.emailAddress, newEmailAddress: state?.email}, currentUser?.token).then((resp)=>{
      if(resp.success){
        let newEmail=resp.data?.user.username
        setState({...state, changingEmail: false, changeEmail: false, success:"Email updated.",})
        _setStudent({..._student, emailAddress: newEmail })
        onUpdated()
        return;
      }
      setState({...state, changingEmail: false, error:  resp?.message})
    })
  }

  const handleChange = (e) => {
    _setStudent({
      ..._student,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setState({ ...state, error: null, success: null });
    if (student) {
      _setStudent({ ...student, emailAddress: student?.emailAddress || currentUser?.user?.username});
    } else {
      _setStudent(null);
    }
  }, [student, open, type]);

  return (
    <div>
      <Modal
        //className="custom-modal"
        show={open}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <div className="f-width d-flex space-between">
            <div className="title">{student ?" Profile" : "Complete your profile"}</div>
            <div
              onClick={() => {
                if (state.processing) return;
                reset();
                onHide();
              }}
            >
              <Close />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          <div className="row mb-50 mt-20" hidden={type === "view"}>
            <div className="col-md-12 mb-20">
              <InputField
                disabled
                classes="small"
                type="email"
                value={_student?.emailAddress }
                label="Email"
                name="emailAddress"
                placeholder="Enter email"
                //onChange={handleChange}
              />
             {currentUser?.user?.isSuperUser && <div>
                
                {state?.changeEmail&&<InputField
                classes="small"
                type="email"
                value={state?.email}
                label="New Email"
                name="email"
                placeholder="Enter new email"
                onChange={(e)=>setState({...state, email:e.target.value})}
                rightIcon={
                  <div className="text-success pointer" onClick={changeEmail}>
                    {state?.changingEmail?"Please wait...":"Save"}
                  </div>
                }
              />}

              {/* <div className="pointer text-danger text-14" 
                onClick={()=>setState({...state, changeEmail: !state.changeEmail})}> {state.changeEmail ?"Cancel":"Change Email"}
                </div> */}

              </div>}
              
            </div>
            <div className="col-md-6 mb-20">
              <InputField
                classes="small"
                value={_student?.firstName}
                label="First Name"
                name="firstName"
                placeholder="Enter first name"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-20">
              <InputField
                classes="small"
                value={_student?.lastName}
                label="Lastname"
                name="lastName"
                placeholder="Enter lastname"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-20">
              <InputField
                classes="small"
                value={_student?.phoneNumber}
                label="Phone number"
                name="phoneNumber"
                placeholder="Enter Phone numbner"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-20">
              <InputField
                classes="small"
                value={_student?.whatsAppNumber}
                label="WhatsApp Number"
                name="whatsAppNumber"
                placeholder="Enter Phone numbner"
                onChange={handleChange}
              />
            </div>
            
            <div className="col-md-12 mb-20">
              <InputField
                classes="small"
                value={_student?.address}
                label="Address"
                name="address"
                placeholder="Enter address"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-20">
              <InputField
                classes="small"
                value={_student?.workplace}
                label="Workplace"
                name="workplace"
                placeholder="Enter workplace"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-20">
              <InputField
                classes="small"
                value={_student?.registrationNumber}
                label="Registration Number"
                name="registrationNumber"
                placeholder="Enter registration number"
                onChange={handleChange}
              />
            </div>
          </div>

          {/**view */}

          {type === "view" && (
            <div className="row">
              <div className="col-md-6">
                <div className="text-15 text-grey-light">ID</div>
                <div className="text-15 text-grey mb-10">
                  {_student?.unique_id}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Name</div>
                <div className="text-15 text-grey mb-10">{_student?.name}</div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Location</div>
                <div className="text-15 text-grey  mb-10">
                  {_student?.location}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Phone</div>
                <div className="text-15 text-grey mb-10">
                  {_student?.telephone}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Email</div>
                <div className="text-15 text-grey mb-10">
                  {_student?.email}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Status</div>
                <div className="text-15 text-grey  mb-10">
                  {_student?.status}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Assigned to</div>
                <div className="text-15 text-grey mb-10">
                  {_student?.assigned_to?.first_name}{" "}
                  {_student?.assigned_to?.last_name}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          {state?.success && (
            <div className="text-success center mb-20">{state?.success}</div>
          )}
          {state?.error && (
            <div className="text-danger text-right mb-20">{state?.error}</div>
          )}
          <div className="flex flex-end">
            {type !== "view" && (
              <Button
                classes="small danger"
                loading={state.processing}
                disabled={state.processing || _student?.lastName?.length===0 || _student?.firstName?.length===0}
                onClick={processstudent}
              >
                Save
              </Button>
            )}

          {/*   {type == "view" && (
              <Button
                classes="small"
                disabled={state.processing}
                onClick={onEdit}
              >
                Edit
              </Button>
            )} */}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProfileModal;