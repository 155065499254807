import React from "react"
import TablePagination from '@material-ui/core/TablePagination';
import  "./pagination-bar.styles.scss"
export default function PaginationBar({
     page,
     pageSize,
     totalItems,
     onChangePage=()=>{},
     onChangeRowsPerPage=()=>{}
    }) {


  const handleChangePage = (event, newPage) => {
        onChangePage(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    let size=parseInt(event.target.value, 10)
    onChangeRowsPerPage(size)
  };

  return (
   <TablePagination
      className="pagination-bar"
      component="div"
      count={totalItems}
      page={page}
      rowsPerPage={pageSize}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}