import React, { useGlobal, useState, useEffect } from "reactn";
import { withRouter } from "react-router-dom";
import PageLayout from "../../../components/page-layout/page-layout.component";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import Button from "../../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import { deleteData, putData } from "../../../api/api";
import { Row, Col, Table } from "react-bootstrap";
import SwitchButton from "../../../components/form/toggle-button/toggle-button.component";
import Select from "../../../components/form/select/select.component";
import Book from "../../../components/cards/book/book.component";
import BookModal from "../../../components/modal/book-modal/book-modal.component";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import BookOrderModal from "../../../components/modal/book-order-modal/book-order-modal.component";
import useAccessLevels from "../../../hooks/useAccessLevels";

const AdminJobPortalPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [, setCurrentJob] = useGlobal("currentJob");
  const [state, setState] = useState({
    activeTab: 0,
    query: "",
    status: "",
    loading: true,
    openModal: false,
    activeBook: null,
    activeOrder: null,
    openOrderModal: false,
  });

  const [paginate, setPaginate] = useState({
    page: 0, // index 0, add +1
    pageSize: 8,
  });

  const { data, error, mutate, isValdating } = useSWR(
    `job?query=${state?.query}&offset=${
      paginate.page * paginate.pageSize
    }&limit=${paginate.pageSize}`
  );

  const { hasAccess } = useAccessLevels(currentUser?.user?.accessLevels);
  const access = hasAccess("Job-portal");

  return (
    <div>
      <PageLayout
        activeLink="job-portal"
        parentMenu="job-portal"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}
      >
        <div className="mt-3">
          <div className="text-24 mb-20">
            {" "}
            Jobs
            {access?.write && (
              <span
                className="text-danger mx-3 pointer text-18 font-500"
                onClick={() => {
                  setCurrentJob(null, () =>
                    props.history.push(`/admin/job/new`)
                  );
                }}
              >
                Add new Job
              </span>
            )}
          </div>

          <div>
            <LoaderPane
              loading={isValdating || (!data && !error)}
              noRecords={!isValdating && data?.books?.length === 0}
              error={error?.message}
            />

            {data && (
              <div>
                <Table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Title</th>
                      <th>Company</th>
                      <th>Type</th>
                      <th>Link/Email</th>
                      <th>Salary</th>
                      <th>Deadline</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.jobs?.map((s, i) => (
                      <tr key={i}>
                        <td>{new Date(s.createdAt).toDateString()}</td>
                        <td>{s.title}</td>
                        <td>{s.company}</td>
                        <td>{s.jobType}</td>
                        <td>{s.applyLink || s.applyEmail}</td>
                        <td>{s.salary}</td>
                        <td>{new Date(s.deadline).toDateString()}</td>
                        <td>
                          <span
                            className="text-danger font-500 pointer"
                            onClick={() => {
                              setCurrentJob(s, () => {
                                props.history.push(`/admin/job/view`);
                              });
                            }}
                          >
                            View
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={8}>
                        <PaginationBar
                          page={paginate.page}
                          pageSize={paginate.pageSize}
                          totalItems={data?.count}
                          onChangePage={(newPage) => {
                            setPaginate({
                              ...paginate,
                              page: newPage,
                            });
                          }}
                          onChangeRowsPerPage={(size) => {
                            setPaginate({
                              pageSize: size,
                              page: 0,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
      </PageLayout>
    </div>
  );
};

export default withRouter(AdminJobPortalPage);
