import React from "reactn";
import { Spinner } from "react-bootstrap";
//import { ReactComponent as NoRecordsIcon } from "../../assets/icons/phone.svg";
import Loader from "../content-loader/content-loader.component"

const LoaderPane = ({
  loading,
  error = null,
  canReload = true,
  noRecords = false,
  loader,
  loaderType="page",
  noRecordsMessage,
  onReload = () => {},
}) => {
  return (
    <div className="d-flex justify-center w-100">
      {loading && (
        <div className="w-100">
          {loader ? loader  : <Loader type={loaderType}/>}
        </div>
      )}

      {error && !loading && <span className="text-danger">{error}</span>}

      {noRecords && !loading && (
        <div className="text-grey d-flex justifer-center">
          <div>
            {/* <NoRecordsIcon /> */}
            <div className="text-grey text-center"> {noRecordsMessage || "No records"}</div>
          </div>
        </div>
      )}

      {error && canReload && (
        <span>
          &nbsp;
          <span
            className="text-primary pointer font-bold"
            onClick={() => onReload()}
          >
            TRY AGAIN
          </span>
        </span>
      )}
    </div>
  );
};

export default LoaderPane;
