import React from "react"
import Button from "../../../../components/button/button.component";

import { Col, Row } from "react-bootstrap"
import InputField from "../../../../components/form/input-field/input-field.component"
import Select from "../../../../components/form/select/select.component"

const InformationTab=(props)=>{
    return(
          <div>
                <div className="text-30 font-500">Register {props?.program}</div>
                <div className="text-20 text-grey mb-40">Enter your details to register for  {props?.program}<br/> program</div>
                <Row>
                    <Col md={9}>
                         <div className="mb-25">
                            <Select placeholder="Can you provide your own laptop?" options={[{label:"Yes", value:"Yes"},{label:"No", value:"No"}]} />
                        </div>
                        <div className="mb-25">
                            <Select placeholder="Choose a training program" options={[{label:"Program 1", value:"Program 1"},{label:"Program 2", value:"Program 2"},{label:"Program 3", value:"Program 3"}]} />
                        </div>
                        <div className="mb-25">
                            <Select placeholder="Select a schedule" options={[{value:"Schedule 1",label:"Schedule 1"},{value:"Schedule 2",label:"Schedule 2"}]} />
                        </div>
                        <Row>
                            <Col md={8}/>
                            <Col>
                            <Button classes="f-width danger mt-20" onClick={()=>props?.onNext()}>Next</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
    )
}

export default InformationTab