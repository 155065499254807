import React, { Fragment } from "react";

const VideoIcon = ({
  variant = "default", //active
  color = "white",
  size = 48,
  viewBox = "0 0 49 48",
}) => {
  const _color =
    variant === "default"
      ? "#3F3F3F"
      : variant === "active"
      ? "#FFFFFF"
      : color;
  return (
    <svg
      width={size + 1}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Fragment>
        <path
          d="M40.8333 4H0V44H49V4H40.8333ZM6.53333 42.4H1.63333V37.6H6.53333V42.4ZM6.53333 36H1.63333V31.2H6.53333V36ZM6.53333 29.6H1.63333V24.8H6.53333V29.6ZM6.53333 23.2H1.63333V18.4H6.53333V23.2ZM6.53333 16.8H1.63333V12H6.53333V16.8ZM6.53333 10.4H1.63333V5.6H6.53333V10.4ZM40.8333 42.4H8.16667V5.6H40.8333V42.4ZM47.3667 42.4H42.4667V37.6H47.3667V42.4ZM47.3667 36H42.4667V31.2H47.3667V36ZM47.3667 29.6H42.4667V24.8H47.3667V29.6ZM47.3667 23.2H42.4667V18.4H47.3667V23.2ZM47.3667 16.8H42.4667V12H47.3667V16.8ZM47.3667 10.4H42.4667V5.6H47.3667V10.4Z"
          fill={_color}
        />
        <path
          d="M30.9284 23.6003C30.8561 23.4765 30.7514 23.374 30.6251 23.3032L19.1918 16.9032C18.8012 16.6823 18.3017 16.8134 18.0762 17.196C18.0039 17.3188 17.9661 17.4582 17.9668 17.6V30.4C17.9671 30.6855 18.1227 30.9492 18.3751 31.092C18.6278 31.2349 18.9391 31.2349 19.1918 31.092L30.6251 24.692C31.0166 24.4726 31.1524 23.9839 30.9284 23.6003ZM19.6001 29.0208V18.9792L28.5704 24L19.6001 29.0208Z"
          fill={_color}
        />
      </Fragment>
    </svg>
  );
};

export default VideoIcon;
