import React from "react";

const HomeIcon = ({
  variant = "default", //active
  color = "white",
  size = 27,
  viewBox = "0 0 27 27",
}) => {
  const _color =
    variant === "default"
      ? "#3F3F3F"
      : variant === "active"
      ? "#FFFFFF"
      : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.0111 10.2053L17.478 1.67105C16.4219 0.618035 14.9914 0.0267181 13.5 0.0267181C12.0086 0.0267181 10.5781 0.618035 9.52201 1.67105L0.988888 10.2053C0.674367 10.5178 0.425005 10.8896 0.255256 11.2992C0.0855081 11.7088 -0.0012492 12.148 1.35894e-05 12.5914V23.6355C1.35894e-05 24.5307 0.355593 25.3891 0.988528 26.022C1.62146 26.655 2.47991 27.0105 3.37501 27.0105H23.625C24.5201 27.0105 25.3786 26.655 26.0115 26.022C26.6444 25.3891 27 24.5307 27 23.6355V12.5914C27.0013 12.148 26.9145 11.7088 26.7448 11.2992C26.575 10.8896 26.3257 10.5178 26.0111 10.2053ZM16.875 24.7605H10.125V20.3348C10.125 19.4397 10.4806 18.5812 11.1135 17.9483C11.7465 17.3154 12.6049 16.9598 13.5 16.9598C14.3951 16.9598 15.2536 17.3154 15.8865 17.9483C16.5194 18.5812 16.875 19.4397 16.875 20.3348V24.7605ZM24.75 23.6355C24.75 23.9339 24.6315 24.2201 24.4205 24.431C24.2095 24.642 23.9234 24.7605 23.625 24.7605H19.125V20.3348C19.125 18.843 18.5324 17.4122 17.4775 16.3573C16.4226 15.3024 14.9919 14.7098 13.5 14.7098C12.0082 14.7098 10.5774 15.3024 9.52254 16.3573C8.46764 17.4122 7.87501 18.843 7.87501 20.3348V24.7605H3.37501C3.07664 24.7605 2.7905 24.642 2.57952 24.431C2.36854 24.2201 2.25001 23.9339 2.25001 23.6355V12.5914C2.25106 12.2933 2.36948 12.0075 2.57964 11.796L11.1128 3.26517C11.7469 2.63397 12.6053 2.27961 13.5 2.27961C14.3948 2.27961 15.2531 2.63397 15.8873 3.26517L24.4204 11.7994C24.6297 12.0101 24.7481 12.2944 24.75 12.5914V23.6355Z"
        fill={_color}
      />
    </svg>
  );
};

export default HomeIcon;
