import { useState, useGlobal } from "reactn";
import { Modal, Row, Col } from "react-bootstrap";
import { useEffect } from "reactn";
import { deleteData, postData, putData } from "../../../api/api";
import Button from "../../button/button.component";
import SwitchButton from "../../form/toggle-button/toggle-button.component";
import InputField from "../../form/input-field/input-field.component";
import Select from "../../form/select/select.component";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { toast } from "react-toastify";

const BookModal = ({
  open,
  currentUser,
  book,
  onHide,
  access,
  type = "add",
  onBlurHide = false,
  onEdit = () => {},
  onDelete = () => {},
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    action: type,
    processing: false,
    error: null,
    success: null,
  });
  const [programs] = useGlobal("programs");

  const [_book, _setBook] = useState({
    title: "",
    author: "",
    price: "",
    tag: "",
    description: "",
    coverPhotoUrl: "",
  });

  const reset = () => {
    setState({
      action: type,
      processing: false,
      error: null,
      success: null,
    });
  };

  const processbook = (action) => {
    setState({
      ...state,
      processing: true,
      error: null,
    });

    let data = { ..._book, price: parseFloat(_book.price) };

    const request =
      type === "edit" || _book?.id
        ? putData(`bookstore/book/${_book?.id}`, data, currentUser?.token)
        : postData(`bookstore/book`, data, currentUser?.token);

    request.then((resp) => {
      if (resp.success) {
        setState({ ...state, processing: false, error: null });
        onHide();
        onUpdated(resp.data.book);
        toast.success("Book updated.");
        return;
      }
      setState({
        ...state,
        processing: false,
        error:
          resp?.message ||
          `Unable to update Book, Please fill all fields and check your internet connection.`,
      });
    });
  };

  const handleChange = (e) => {
    _setBook({
      ..._book,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setState({ ...state, error: null });
    if (book) {
      _setBook({ ...book });
    } else {
      _setBook(null);
    }
  }, [book, open, type]);

  return (
    <div>
      <Modal
        //className="custom-modal"
        show={open}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <div className="f-width d-flex space-between">
            <div className="title">Book</div>
            <div
              onClick={() => {
                if (state.processing) return;
                reset();
                onHide();
              }}
            >
              <Close />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          <div className="row mb-50 mt-20" hidden={type === "view"}>
            <div className="col-md-6 mb-20">
              <InputField
                classes="small"
                value={_book?.title}
                label="Title"
                name="title"
                placeholder="Book Title"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-20">
              <InputField
                classes="small"
                value={_book?.author}
                label="Author"
                name="author"
                placeholder="Author"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-12 mb-20">
              <InputField
                classes="small"
                value={_book?.description}
                label="Description"
                name="description"
                placeholder="Description"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-12 mb-20">
              <InputField
                classes="small"
                value={_book?.coverPhotoUrl}
                label="Cover Photo(link)"
                name="coverPhotoUrl"
                placeholder="eg. https://photo.png"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-4 mb-20">
              <InputField
                classes="small"
                type="number"
                value={_book?.price}
                min={1}
                label="Price"
                name="price"
                placeholder="Enter price"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-8 mb-20">
              <InputField
                classes="small"
                type="text"
                value={_book?.tag}
                min={1}
                label="Tag"
                name="tag"
                placeholder="Enter tags separated by `,`"
                onChange={handleChange}
              />
            </div>
          </div>

          {/**view */}

          {type === "view" && (
            <div className="row">
              <div className="col-md-6">
                <div className="text-15 text-grey-light">ID</div>
                <div className="text-15 text-grey mb-10">
                  {_book?.unique_id}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Name</div>
                <div className="text-15 text-grey mb-10">{_book?.name}</div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Location</div>
                <div className="text-15 text-grey  mb-10">
                  {_book?.location}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Phone</div>
                <div className="text-15 text-grey mb-10">
                  {_book?.telephone}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Email</div>
                <div className="text-15 text-grey mb-10">{_book?.email}</div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Status</div>
                <div className="text-15 text-grey  mb-10">{_book?.status}</div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Assigned to</div>
                <div className="text-15 text-grey mb-10">
                  {_book?.assigned_to?.first_name}{" "}
                  {_book?.assigned_to?.last_name}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          {state.error && (
            <div className="text-danger text-right mb-20">{state?.error}</div>
          )}
          {access?.write && (
            <div className="flex flex-end">
              {type !== "view" && (
                <Button
                  classes="small danger"
                  loading={state.processing}
                  disabled={
                    state.processing ||
                    _book?.lastName?.length === 0 ||
                    _book?.firstName?.length === 0
                  }
                  onClick={processbook}
                >
                  Save
                </Button>
              )}
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BookModal;
