import { useState, useGlobal } from "reactn";
import { Modal, Row, Col } from "react-bootstrap";
import { useEffect } from "reactn";
import { deleteData, postData, putData } from "../../../api/api";
import Button from "../../button/button.component";
import SwitchButton from "../../form/toggle-button/toggle-button.component";
import InputField from "../../form/input-field/input-field.component";
import Select from "../../form/select/select.component";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { toast } from "react-toastify";
import useAccessLevels from "../../../hooks/useAccessLevels";

const UserModal = ({
  open,
  currentUser,
  user,
  onHide,
  onBlurHide = false,
  onUpdated = () => {},
  access,
}) => {
  const [userData, setUserData] = useState({
    username: "",
    accessLevels: "",
  });

  const [password, setPassword] = useState("");

  useEffect(() => {
    if (open) {
      setUserData({
        username: user?.username,
        accessLevels: user?.accessLevels,
      });
    }
  }, [open]);

  const { accessLevels, hasAccess } = useAccessLevels(
    userData?.accessLevels || ""
  );

  const [state, setState] = useState({
    processing: false,
    error: null,
    success: null,
  });

  const reset = () => {
    setState({
      processing: false,
      error: null,
      success: null,
    });
    setPassword("");
  };

  const onSubmit = (action) => {
    setState({
      ...state,
      processing: true,
      error: null,
    });

    //return;
    const request = user?.id
      ? putData(
          `user/access-levels`,
          { emailAddress: user.username, accessLevels: userData?.accessLevels },
          currentUser?.token
        )
      : postData(
          `user/superuser`,
          {
            username: userData?.username,
            password: password,
            accessLevels: userData?.accessLevels,
          },
          currentUser?.token
        );

    request.then((resp) => {
      if (resp.success) {
        setState({ ...state, processing: false, error: null });
        onHide();
        onUpdated();
        toast.success("User updated.");
        return;
      }
      toast.error("An error occurred!");
      setState({
        ...state,
        processing: false,
        error: resp?.message || `Unable to update User`,
      });
    });
  };

  return (
    <div>
      <Modal
        //className="custom-modal"
        show={open}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <div className="f-width d-flex space-between">
            <div className="title">User</div>
            <div
              onClick={() => {
                if (state.processing) return;
                reset();
                onHide();
              }}
            >
              <Close />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          <div className=" mb-50 mt-20">
            <InputField
              label={"Email"}
              disabled={user?.id}
              value={userData?.username}
              onChange={(e) => {
                setUserData({ ...userData, username: e.target.value });
              }}
            />

            {!user && (
              <div className="mt-2">
                <InputField
                  label={"Password"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
            )}

            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>ACCESS</th>
                    <th>READ</th>
                    <th>WRITE</th>
                  </tr>
                </thead>
                <tbody>
                  {accessLevels?.map((accessLevel, i) => {
                    const access = hasAccess(accessLevel);
                    return (
                      <tr key={i}>
                        <td>{accessLevel}</td>
                        <td>
                          {" "}
                          <SwitchButton
                            checked={access.read || access.write}
                            onChange={() => {
                              console.log(accessLevel);
                              let n = userData?.accessLevels || "";
                              if (access.read || access.write) {
                                n = n.replace(`${accessLevel}_read`, "");
                                n = n.replace(`${accessLevel}_write`, "");
                              } else {
                                n = `${n} ${accessLevel}_read `;
                              }
                              setUserData({ ...userData, accessLevels: n });
                            }}
                          />
                        </td>
                        <td>
                          {" "}
                          <SwitchButton
                            checked={access.write}
                            onChange={() => {
                              let n = userData?.accessLevels || "";
                              if (access.write) {
                                n = n.replace(`${accessLevel}_write`, "");
                              } else {
                                n = `${n} ${accessLevel}_write `;
                              }
                              setUserData({ ...userData, accessLevels: n });
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {state.error && (
            <div className="text-danger text-right mb-20">{state?.error}</div>
          )}
          <div className="flex flex-end">
            {access?.write && (
              <Button
                classes="small danger"
                loading={state.processing}
                disabled={state.processing}
                onClick={onSubmit}
              >
                Save
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserModal;
