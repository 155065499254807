import { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import Button from "../../button/button.component";
import {ReactComponent as SuccessImage} from "../../../assets/icons/success.svg"
import {ReactComponent as ErrorImage} from "../../../assets/icons/success.svg"

const SuccessErrorModal = ({
  open,
  onHide,
  success = null,
  processing = false,
  error = null,
  onRetry = () => {},
  onBlurHide = false,
}) => {
  const [state, setState] = useState({});

  return (
    <div>
      <Modal
        className="modal-small"
        show={open}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
      
        <Modal.Body className="scrollbar mb-20">
          <div className="">

            {success && !processing && (
              <div className="mt-10 text-center">
                <div className="d-flex justify-content-center" style={{margin:"-60px  0px"}}><SuccessImage/></div>
                <div className="text-30 font-500"> {success?.description} </div>
                <div className="mb-30"> {success?.message} </div>
                {success?.buttonText &&
                <div className="px-4"><Button
                  classes="f-width small success"
                  loading={processing}
                  disabled={processing}
                  onClick={()=>success?.buttonAction()}
                >
                  {success?.buttonText} 
                </Button></div>}
              </div>
            )}

            {error && (!processing || !success) && (
              <div className="mt-10 text-danger text-center"><ErrorImage/></div>
            )}
          </div>
          <div className="f-width mt-30">
            
            {<Button
              classes="small secondary outline mx-2"
              hidden={success}
              loading={processing}
              disabled={processing}
              onClick={onRetry}
            >
              Retry
            </Button>}

            <div className="text-20 text-center pointer" onClick={onHide}>Close</div>
            
          </div>
        </Modal.Body>

        <Modal.Footer hidden></Modal.Footer>
      </Modal>
    </div>
  );
};

export default SuccessErrorModal;
