import React, { useRef } from "react";
//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor as TinyEditor } from "@tinymce/tinymce-react";
import "./editor.styles.scss";
import TurndownService from "turndown";
import pagedown from "pagedown";

const Editor = ({
  initialValue,
  value,
  init,
  label,
  placeholder,
  height = 250,
  onInit = (evt, editor) => {},
  onEditorChange = (value, editor) => {},
  appendTo = "body", //.modal, #element
}) => {
  //const editorRef = useRef(null);

  return (
    <div className="editor">
      {label && <div className="label">{label}</div>}
      <TinyEditor
        apiKey={process.env.REACT_APP_TINYMCE_KEY}
        onInit={(e, ed) => {
          const tinyAuxEl = document.querySelector(".tox-tinymce-aux");
          const modalBodyEl = document.querySelector(appendTo); // if using something else just grab your modal body element here
          modalBodyEl.appendChild(tinyAuxEl);
        }}
        initialValue={initialValue}
        value={value}
        onEditorChange={onEditorChange}
        init={{
          height: height,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code wordcount", //help
          ],
          toolbar:
            "undo redo | formatselect | link | " +
            "bold italic backcolor | alignleft aligncenter |  " +
            "alignright alignjustify | outdent indent | bullist numlist" + //bullist numlist
            "removeformat ", //| help
          //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          placeholder: placeholder,
        }}
      />
      <script></script>
    </div>
  );
};

export const convertToHtml = (text = " ") => {
  let converter = new pagedown.Converter();
  let html = converter.makeHtml(text);
  //console.log("converted",html)
  return html;
};

export const convertToMarkdown = (html = " ") => {
  let markdown = new TurndownService().turndown(html);
  return markdown;
};

export default Editor;
