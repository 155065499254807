import React, { useGlobal, useState, useEffect } from "reactn";
import { withRouter } from "react-router-dom";
import PageLayout from "../../../components/page-layout/page-layout.component";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import Button from "../../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import { deleteData, putData } from "../../../api/api";
import { Row, Col, Table } from "react-bootstrap";
import { Facebook } from "react-content-loader";
import ProfileModal from "../../../components/modal/profile-modal/profile-modal.component";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import InputField from "../../../components/form/input-field/input-field.component";
import { toast } from "react-toastify";
import useAccessLevels from "../../../hooks/useAccessLevels";

const AdminSettingsPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [state, setState] = useState({
    query: "",
    status: "",
    activeTab: 0,
    loading: false,
    openProfile: false,
    changingPassword: false,
    password: "",
    emailAddress: "",
    changeEmail: "",
    newEmailAddress: "",
    oldEmailAddress: "",
  });

  const { hasAccess } = useAccessLevels(currentUser?.user?.accessLevels);
  const access = hasAccess("Settings");

  const resetPassword = () => {
    setState({ ...state, changingPassword: true });
    putData(
      "user/admin/password/reset",
      { emailAddress: state.emailAddress, password: state.password },
      currentUser?.token
    ).then((resp) => {
      setState({
        ...state,
        changingPassword: false,
        emailAddress: "",
        password: "",
      });
      if (resp.success) {
        toast.success("Password reset successful.");
        return;
      }
      toast.success(resp?.message || "Failed to reset password");
    });
  };

  const changeStudentOrUserEmail = (changeEmail) => {
    //changeEmail = []
    var url = `${changeEmail}/change-email`;
    setState({ ...state, changeEmail: changeEmail });
    putData(
      url,
      {
        emailAddress: state.oldEmailAddress,
        newEmailAddress: state.newEmailAddress,
      },
      currentUser?.token
    ).then((resp) => {
      setState({
        ...state,
        changeEmail: "",
        oldEmailAddress: "",
        newEmailAddress: "",
      });
      if (resp.success) {
        toast.success("Email changed");
        return;
      }
      toast.success(resp?.message || "Failed to reset password");
    });
  };

  return (
    <div>
      <PageLayout
        activeLink="settings"
        parentMenu="settings"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            /* if(paginate.page>0){
                    setPaginate({...paginate,page:0})
                }
                setState({ ...state, query: e.target.value });
                 */
          },
        }}
      >
        <div className="mt-3">
          <div className="text-24"> Settings</div>
          <div className="row">
            <div className="col-md-4 col-sm-12 mb-20">
              <InputField
                classes="small"
                value={state?.emailAddress}
                label="User Email"
                name="name"
                placeholder="Enter  user email"
                onChange={(e) =>
                  setState({ ...state, emailAddress: e.target.value })
                }
              />
            </div>
            <div className="col-md-4 col-sm-12 mb-20">
              <InputField
                classes="small"
                value={state?.password}
                label="New Password"
                name="password"
                placeholder="Enter new password"
                onChange={(e) =>
                  setState({ ...state, password: e.target.value })
                }
              />
            </div>
            <div className="col-12">
              {access?.write && (
                <Button
                  classes={"small danger"}
                  disabled={
                    state?.changingPassword || state.password?.length < 1
                  }
                  loading={state?.changingPassword}
                  onClick={resetPassword}
                >
                  Reset
                </Button>
              )}
            </div>
          </div>
          {/*  change email */}
          <div className="row mt-30">
            <div className="col-md-4 col-sm-12 mb-10">
              <InputField
                classes="small"
                value={state?.oldEmailAddress}
                label="User/Student Email"
                name="oldEmail"
                placeholder="Enter  email"
                onChange={(e) =>
                  setState({ ...state, oldEmailAddress: e.target.value })
                }
              />
            </div>
            <div className="col-md-4 col-sm-12 mb-10">
              <InputField
                classes="small"
                value={state?.newEmailAddress}
                label="New Email"
                name="newEmail"
                placeholder="Enter the new email"
                onChange={(e) =>
                  setState({ ...state, newEmailAddress: e.target.value })
                }
              />
            </div>
            <div className="col-12">
              {access?.write && (
                <span
                  className="text-14 text-danger mx-3  pointer"
                  onClick={() => {
                    if (state.changeEmail) return;
                    changeStudentOrUserEmail("user");
                  }}
                >
                  {state.changeEmail == "user"
                    ? "Please wait..."
                    : "Change  User Email "}{" "}
                </span>
              )}
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  );
};

export default withRouter(AdminSettingsPage);
