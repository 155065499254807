import React from "react";
import {
  SelectContainer,
  CustomSelect,
  CustomCreatableSelect,
  Label,
  Styles,
  Inner,
  IconDiv,
} from "./select.styles";

const MainSelect = ({
  id,
  name,
  label,
  placeholder,
  leftIcon,
  small = false,
  rightIcon,
  iSearchable,
  options,
  isMulti = false,
  createable = false,
  onChange,
  ...otherProps
}) => (
  <>
    {label && <Label htmlFor={id}>{label}</Label>}
    <SelectContainer small={small} isMulti={isMulti}>
      <Inner>
        <IconDiv htmlFor={id} hidden>
          {leftIcon}
        </IconDiv>
        {/** selectinput that accept user typed-in value */}
        {createable ? (
          <CustomCreatableSelect
            id={id}
            placeholder={placeholder}
            options={options}
            isMulti={isMulti}
            iSearchable={iSearchable}
            styles={(Styles, {})}
            onChange={onChange}
            onCreateOption={() => {}}
            {...otherProps}
          />
        ) : (
          <CustomSelect
            id={id}
            placeholder={placeholder}
            options={options}
            isMulti={isMulti}
            iSearchable={iSearchable}
            styles={Styles}
            onChange={onChange}
            {...otherProps}
          />
        )}

        <IconDiv right htmlFor={id} hidden>
          {rightIcon}
        </IconDiv>
      </Inner>
    </SelectContainer>
  </>
);

export default MainSelect;
