import { useState, useGlobal } from "reactn";
import { Modal, Row, Col } from "react-bootstrap";
import { useEffect } from "reactn";
import { deleteData, postData, putData } from "../../../api/api";
import Button from "../../button/button.component";
import SwitchButton from "../../form/toggle-button/toggle-button.component";
import InputField from "../../form/input-field/input-field.component";
import Select from "../../form/select/select.component";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { toast } from "react-toastify";
import useSWR from "swr";
import moment from "moment";

const ResourceModal = ({
  open,
  currentUser,
  resource,
  subject,
  onHide,
  type = "add",
  onBlurHide = false,
  onEdit = () => {},
  onDelete = () => {},
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    action: type,
    processing: false,
    error: null,
    success: null,
  });

  const [_resource, _setResource] = useState({
    name: "",
    level: null,
    price: "",
    isIntervention: false,
    programID: "",
    sittingMedias: [],
  });

  const reset = () => {
    setState({
      action: type,
      processing: false,
      error: null,
      success: null,
    });
  };

  const processresource = (action) => {
    setState({
      ...state,
      processing: true,
      error: null,
    });

    let endpoint = "";
    let data = {};

    const sittingIDs = _resource?.sittingMedias?.map((s) => s.value);

    if (_resource?.type === "PDF") {
      endpoint = "tuition/document";
      data = {
        ..._resource,
        documentType: "PDF",
        documentFolder: _resource?.folder,
        documentURL: _resource?.url,
        subjectID: subject.id,
        sittingIDs: sittingIDs,
      };
    } else {
      endpoint = "tuition/media";
      data = {
        ..._resource,
        mediaType: _resource?.type,
        mediaFolder: _resource?.folder,
        mediaURL: _resource?.url,
        mediaSource: _resource?.mediaSource || "Custom",
        subjectID: subject.id,
        sittingIDs: sittingIDs,
      };
    }
    delete data["sittingMedias"];
    delete data["sittingDocuments"];
    delete data["subject"];

    const request =
      type === "edit" || _resource?.id
        ? putData(`${endpoint}/${_resource?.id}`, data, currentUser?.token)
        : postData(endpoint, data, currentUser?.token);

    request.then((resp) => {
      if (resp.success) {
        onUpdated(_resource?.type);
        setState({ ...state, processing: false, error: null });
        onHide();
        toast.success("Resource updated.");
        return;
      }
      setState({
        ...state,
        processing: false,
        error:
          resp?.message ||
          `Unable to update Resource, Please fill all fields and check your internet connection.`,
      });
    });
  };

  const { data, error, mutate, isValdating } = useSWR(
    `tuition/sitting?query=${state?.query}`
  );

  const folderOptions = () => {
    let l = [{ label: "Lecture Audios", value: "Lecture Audios" }];
    if (_resource?.type === "Video") {
      l = [
        { label: "Lecture Videos", value: "Lecture Videos" },
        { label: "Revision Videos", value: "Revision Videos" },
      ];
    } else if (_resource?.type === "PDF") {
      l = [
        { label: "Summary Notes", value: "Summary Notes" },
        { label: "Special Notes", value: "Special Notes" },
        { label: "Questions Bank", value: "Questions Bank" },
        { label: "ICAG Past Questions", value: "ICAG Past Questions" },
      ];
    }
    return l;
  };

  const sittingOptions = () => {
    if (!data) {
      return [];
    }
    return data?.sittings?.map((s) => ({
      label: sittingName(s),
      value: s.id,
    }));
  };

  const isValid = () =>
    _resource?.description &&
    _resource?.type &&
    _resource?.folder &&
    _resource?.url;

  const handleChange = (e) => {
    _setResource({
      ..._resource,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setState({ ...state, error: null });
    if (resource) {
      let type =
        resource?.mediaType == "Video" || resource?.mediaType == "Audio"
          ? "sittingMedias"
          : "sittingDocuments";

      const sm = resource[`${type}`]?.map((s) => ({
        label: sittingName(s.sitting),
        value: s.sitting?.id,
      }));

      _setResource({
        ...resource,
        type: resource?.mediaType || resource?.documentType,
        folder: resource?.mediaFolder || resource?.documentFolder,
        url: resource?.mediaURL || resource?.documentURL,
        sittingMedias: sm,
      });
    } else {
      _setResource(null);
    }
  }, [resource, open, type]);

  return (
    <div>
      <Modal
        //className="custom-modal"
        show={open}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <div className="f-width d-flex space-between">
            <div className="title">Resource</div>
            <div
              onClick={() => {
                if (state.processing) return;
                reset();
                onHide();
              }}
            >
              <Close />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          <div className="row mb-50 mt-20" hidden={type === "view"}>
            <div className="col-md-12 mb-20">
              <InputField
                classes="small"
                value={_resource?.description}
                label="Description"
                name="description"
                placeholder="Enter description"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 mb-20">
              <Select
                placeholder="Select type of file"
                small
                label={"File Type"}
                options={[
                  { label: "PDF", value: "PDF" },
                  { label: "Video", value: "Video" },
                  { label: "Audio", value: "Audio" },
                ]}
                value={{ label: _resource?.type, value: _resource?.type }}
                onChange={(selected) => {
                  _setResource({
                    ..._resource,
                    type: selected.value,
                    folder: null,
                  });
                }}
              />
            </div>
            <div className="col-md-6 mb-20">
              <Select
                placeholder="Select folder"
                small
                label={"Folder"}
                options={folderOptions()}
                value={{ label: _resource?.folder, value: _resource?.folder }}
                onChange={(selected) => {
                  _setResource({ ..._resource, folder: selected.value });
                }}
              />
            </div>
            <div className="col-md-12 mb-20">
              <Select
                placeholder="Select Sittings"
                small
                label={"Sittings"}
                options={sittingOptions()}
                //value={[]}
                isMulti
                value={_resource?.sittingMedias || null}
                onChange={(selected) => {
                  _setResource({ ..._resource, sittingMedias: [...selected] });
                }}
              />
            </div>
            <div className="col-md-12 mb-20">
              <InputField
                classes="small"
                value={_resource?.url}
                min={1}
                label="File URL"
                name="url"
                placeholder="Enter Url"
                onChange={handleChange}
              />
            </div>
          </div>

          {state.error && (
            <div className="text-danger text-right mb-20">{state?.error}</div>
          )}
          <div className="col-md-12 mb-20">
            <div className="flex flex-end">
              {type !== "view" && (
                <Button
                  classes="small danger"
                  loading={state.processing}
                  disabled={state.processing || !isValid()}
                  onClick={processresource}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>

        {/*  <Modal.Footer></Modal.Footer> */}
      </Modal>
    </div>
  );
};
const sittingName = (s) =>
  `${moment(s.startAt).format("MMM, YYYY")} - ${moment(s.endAt).format(
    "MMM, YYYY"
  )} (${s?.program?.tag})`;

export default ResourceModal;
