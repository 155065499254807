import React from "react";

const BellIcon = ({
  variant = "default", //active
  color = "white",
  size,
  viewBox = "0 0 39 42",
}) => {
  const _color =
    variant === "default"
      ? "#686868"
      : variant === "active"
      ? "#FFFFFF"
      : color;
  return (
    <svg
      width={size || 39}
      height={size || 42}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.529392 23.9089L3.85439 11.9459C4.82899 8.44127 6.9471 5.36294 9.87205 3.20026C12.797 1.03758 16.3609 -0.0853389 19.9973 0.0100012C23.6337 0.105341 27.1339 1.41347 29.9415 3.72644C32.7491 6.03942 34.703 9.22451 35.4926 12.7754L38.0669 24.3517C38.3512 25.6308 38.3447 26.9575 38.0477 28.2337C37.7506 29.51 37.1708 30.7032 36.3509 31.7254C35.531 32.7476 34.492 33.5725 33.3105 34.1394C32.1291 34.7062 30.8355 35.0005 29.5251 35.0004H27.5756C27.174 36.9784 26.1009 38.7568 24.5381 40.0341C22.9753 41.3114 21.019 42.0092 19.0006 42.0092C16.9823 42.0092 15.026 41.3114 13.4632 40.0341C11.9004 38.7568 10.8273 36.9784 10.4256 35.0004H8.95914C7.61022 35.0005 6.27955 34.6887 5.07107 34.0894C3.86259 33.4901 2.80903 32.6196 1.99271 31.5457C1.17638 30.4718 0.619385 29.2238 0.365238 27.899C0.111092 26.5742 0.166672 25.2087 0.527645 23.9089H0.529392ZM19.0006 38.5004C20.0826 38.4959 21.1367 38.1573 22.0189 37.5308C22.901 36.9043 23.568 36.0205 23.9286 35.0004H14.0726C14.4333 36.0205 15.1003 36.9043 15.9824 37.5308C16.8645 38.1573 17.9187 38.4959 19.0006 38.5004ZM4.78014 29.4267C5.26779 30.0736 5.89958 30.5979 6.62533 30.9579C7.35108 31.3179 8.15078 31.5036 8.9609 31.5004H29.5251C30.3113 31.5003 31.0873 31.3236 31.796 30.9834C32.5048 30.6433 33.128 30.1483 33.6199 29.535C34.1117 28.9217 34.4595 28.2058 34.6377 27.4402C34.8158 26.6745 34.8197 25.8786 34.6491 25.1112L32.0766 13.5332C31.4565 10.744 29.9219 8.24224 27.7166 6.42547C25.5113 4.6087 22.762 3.58123 19.9058 3.50641C17.0495 3.43158 14.2502 4.31369 11.9529 6.0125C9.6555 7.71132 7.99195 10.1293 7.22664 12.8822L3.90164 24.8452C3.68194 25.6246 3.64695 26.4445 3.79945 27.2398C3.95194 28.035 4.28772 28.7838 4.78014 29.4267Z"
        fill={_color}
      />
    </svg>
  );
};

export default BellIcon;
