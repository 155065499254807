import React, { useGlobal, useState} from "reactn"
import Button from "../../../../components/button/button.component";

import { Col, Row } from "react-bootstrap"
import InputField from "../../../../components/form/input-field/input-field.component"
import Select from "../../../../components/form/select/select.component"
import SwitchButton from "../../../../components/form/toggle-button/toggle-button.component";


const subjects=[
    {name:"Financial Accounting", level:" 1", amount:"100"},
    {name:"Advanced Audit", level:" 1", amount:"100"},
    {name:"Corporate Report", level:" 1", amount:"100"},
    {name:"Financial Reporting", level:" 1", amount:"100"}];

    

const SelectSubjectsTab=(props)=>{
    const [selectedSub,setSelectedSub]=useGlobal("selectedSub")

    const [selected,setSelected]=useState([])

    useState(()=>{
        if(selectedSub?.length>0){
            setSelected(selectedSub)
        }
    },[])

    return(
          <div>
                <div className="text-30 font-500">Select subjects</div>
                <div className="text-20 text-grey mb-40">Select your preferred subjects to register</div>
                <Row className="mt-20">
                    <Col md={9}>
                        {
                            subjects.map((sub,i)=>(
                            <div className="mb-20">
                                <div className="d-flex align-content-center justify-content-between">
                                    <div>
                                        <div className="text-20 font-500">{i+1} &nbsp; {sub.name}</div>
                                        <div className="text-15">
                                            <span>Level {sub.level}</span> &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>GHC {sub.amount}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <SwitchButton checked={selected?.find(item=>item.name === sub.name) ? true: false} 
                                        onChange={(value)=>{
                                            let n=selected?.filter((item)=>(item.name !== sub.name))
                                            if(value){
                                                setSelected([...n,sub])
                                            }else{
                                                setSelected(n)
                                            }
                                        }}/>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                            ))
                        }
                        <Row className="mt-30">
                            <Col md={8} className="mt-30">
                                <span className="text-grey pointer " onClick={()=>props.onBack()}>Previous step</span>
                            </Col>
                            <Col>
                            <Button classes="f-width danger mt-20" onClick={()=>props?.onNext(selected)}>Next</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
    )
}

export default SelectSubjectsTab