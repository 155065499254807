import React from "react";
import "./input-field.styles.scss";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";

const InputField = ({
  ref = React.createRef(),
  label,
  isSearch=false,
  rightIcon,
  leftIcon,
  transparent=false,
  classes,
  ...otherProps
}) => (
  <label className={`input-field ${classes}`}>
    {label && <label>{label}</label>}
    <div className={`input-container ${transparent ? "transparent" : ""} $`}>
      {(isSearch || leftIcon) && (
        <div className="left-icon">{isSearch ? <SearchIcon /> : leftIcon}</div>
      )}
      <input ref={ref} {...otherProps} />
      {rightIcon && <div className="right-icon">{rightIcon}</div>}
    </div>
  </label>
);

export default InputField;
