import React from "react";

const AppStoreIcon = ({
  variant = "default", //active
  color = "white",
  size,
  viewBox = "0 0 35 35",
}) => {
  const _color =
    variant === "default"
      ? "#3F3F3F"
      : variant === "active"
      ? "#FFFFFF"
      : color;
  return (
    <svg
      width={size || 35}
      height={size || 35}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8223 0H7.17773C3.21993 0 0 3.21993 0 7.17773V27.8223C0 31.7801 3.21993 35 7.17773 35H27.8223C31.7801 35 35 31.7801 35 27.8223V7.17773C35 3.21993 31.7801 0 27.8223 0ZM10.7768 26.9688C10.4606 27.5164 9.88497 27.8229 9.29387 27.8229C9.00409 27.8229 8.71069 27.7493 8.44225 27.5944C7.62617 27.1232 7.34556 26.076 7.81669 25.2599L8.15281 24.6777H12.0995L10.7768 26.9688ZM7.55371 23.3105C6.61138 23.3105 5.84473 22.5439 5.84473 21.6016C5.84473 20.6592 6.61138 19.8926 7.55371 19.8926H10.9155L15.5266 11.9059L14.2769 9.74135C13.8058 8.92527 14.0863 7.87801 14.9024 7.40681H14.9025C15.7186 6.93567 16.7658 7.21636 17.237 8.03236L17.5 8.48798L17.763 8.03243C18.2344 7.21629 19.2816 6.93574 20.0975 7.40687C20.4928 7.63513 20.7756 8.00365 20.8938 8.44457C21.012 8.88549 20.9513 9.34609 20.723 9.74142L14.8622 19.8926H18.559L20.5324 23.3105H7.55371ZM27.4463 23.3105H26.0578L27.1832 25.2598C27.6544 26.0758 27.3738 27.1231 26.5578 27.5943C26.2944 27.7463 26.0021 27.824 25.706 27.824C25.5575 27.824 25.4082 27.8046 25.2609 27.765C24.8199 27.6469 24.4514 27.3641 24.2231 26.9687L18.2893 16.691L20.2626 13.273L24.0845 19.8926H27.4463C28.3886 19.8926 29.1553 20.6592 29.1553 21.6016C29.1553 22.5439 28.3886 23.3105 27.4463 23.3105Z"
        fill={_color}
      />
    </svg>
  );
};

export default AppStoreIcon;
