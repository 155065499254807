import React, { useGlobal, useState, useEffect } from "reactn";
import { withRouter } from "react-router-dom";
import PageLayout from "../../../components/page-layout/page-layout.component";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import Button from "../../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import { deleteData, putData } from "../../../api/api";
import { Row, Col, Table } from "react-bootstrap";
import SwitchButton from "../../../components/form/toggle-button/toggle-button.component";
import Select from "../../../components/form/select/select.component";
import Book from "../../../components/cards/book/book.component";
import BookModal from "../../../components/modal/book-modal/book-modal.component";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import BookOrderModal from "../../../components/modal/book-order-modal/book-order-modal.component";
import ExportButton from "../../../components/export-button/export-button.component";
import useAccessLevels from "../../../hooks/useAccessLevels";
const BooksPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [state, setState] = useState({
    activeTab: 0,
    query: "",
    orderQuery: "",
    status: "",
    loading: true,
    openModal: false,
    activeBook: null,
    activeOrder: null,
    openOrderModal: false,
  });

  const [paginate, setPaginate] = useState({
    page: 0, // index 0, add +1
    pageSize: 8,
  });
  const [paginateOrders, setPaginateOrders] = useState({
    page: 0, // index 0, add +1
    pageSize: 25,
  });

  const { data, error, mutate, isValdating } = useSWR(
    `bookstore/book?query=${state?.query}&offset=${
      paginate.page * paginate.pageSize
    }&limit=${paginate.pageSize}&price=0`
  );

  const {
    data: orderData,
    error: orderError,
    mutate: mutateOrders,
  } = useSWR(
    `bookstore/order?query=${state?.orderQuery}&offset=${
      paginateOrders.page * paginateOrders.pageSize
    }&limit=${paginateOrders.pageSize}`
  );

  const { hasAccess } = useAccessLevels(currentUser?.user?.accessLevels);
  const access = hasAccess("Book-store");

  const [confirm, setConfirm] = useState({
    open: false,
    title: "Confirm",
    description: "",
    error: null,
    processing: false,
    data: null,
    action: "",
  });

  const onConfirmOrder = () => {
    let url = `bookstore/order/${confirm.data.id}`;
    setConfirm({ ...confirm, open: true, error: null, processing: true });
    putData(``, {}, currentUser?.token).then(() => {});
  };

  return (
    <div>
      <BookModal
        open={state?.openModal}
        currentUser={currentUser}
        book={state.activeBook}
        onHide={() => {
          setState({ ...state, openModal: false, activeBook: null });
        }}
        onUpdated={() => {
          mutate();
        }}
        access={access}
      />
      <ConfirmModal
        open={confirm.open}
        title={confirm.title}
        description={confirm.description}
        error={confirm.error}
        processing={confirm.processing}
        data={confirm.data}
        onHide={() => setConfirm({ ...confirm, open: false })}
        onConfirm={() => {
          if (confirm.action === "confirmOrder") {
          }
        }}
      />
      <BookOrderModal
        open={state.openOrderModal}
        currentUser={currentUser}
        order={state.activeOrder}
        onHide={() => {
          setState({ ...state, activeOrder: null, openOrderModal: false });
        }}
        onUpdated={() => {
          mutateOrders();
        }}
        access={access}
      />
      <PageLayout
        activeLink="book-store"
        parentMenu="book-store"
        inputProps={{
          value: state.activeTab === 0 ? state.query : state.orderQuery,
          onChange: (e) => {
            if (state.activeTab === 0) {
              setState({ ...state, query: e.target.value });
            } else {
              setState({ ...state, orderQuery: e.target.value });
            }
          },
        }}
      >
        <div className="mt-3">
          <div className="text-24 mb-20">
            {" "}
            Books
            <span
              className="text-danger mx-3 pointer text-18 font-500"
              onClick={() => {
                setState({ ...state, openModal: true });
              }}
            >
              Add new Book
            </span>
          </div>

          <div className="mb-20">
            <span
              className={`text-20 pointer ${
                state.activeTab === 0 && "text-danger"
              }`}
              onClick={() => setState({ ...state, activeTab: 0 })}
            >
              Books
            </span>
            <span
              className={`text-20 mx-3 pointer ${
                state.activeTab === 1 && "text-danger"
              }`}
              onClick={() => setState({ ...state, activeTab: 1 })}
            >
              Orders
            </span>
          </div>

          <div hidden={state.activeTab !== 0}>
            <LoaderPane
              loading={isValdating || (!data && !error)}
              noRecords={!isValdating && data?.books?.length === 0}
              error={error?.message}
            />
            <Row>
              {data &&
                data.books?.map((book) => (
                  <Col md={3} xs={6}>
                    <Book
                      hideCart
                      {...book}
                      onClick={() => {
                        setState({
                          ...state,
                          activeBook: book,
                          openModal: true,
                        });
                      }}
                    />
                  </Col>
                ))}
            </Row>
            {data?.count && (
              <PaginationBar
                page={paginate.page}
                pageSize={paginate.pageSize}
                totalItems={data?.count}
                onChangePage={(newPage) => {
                  setPaginate({
                    ...paginate,
                    page: newPage,
                  });
                }}
                onChangeRowsPerPage={(size) => {
                  setPaginate({
                    pageSize: size,
                    page: 0,
                  });
                }}
              />
            )}
          </div>

          <div hidden={state.activeTab !== 1}>
            <LoaderPane
              loading={!orderData && !orderError}
              noRecords={orderData && orderData?.bookOrders?.length === 0}
              error={orderError?.message}
            />
            {access?.write && (
              <div className="d-flex flex-end">
                <ExportButton
                  url={`bookstore/orders/export`}
                  fileName={`Book_orders_${new Date().toDateString()}`}
                />
              </div>
            )}
            {orderData && (
              <div>
                <Table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Name</th>
                      <th>Items</th>
                      <th>Cost</th>
                      <th>Paid Online</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData?.bookOrders?.map((s, i) => (
                      <tr key={i}>
                        <td>{new Date(s.createdAt)?.toDateString()}</td>
                        <td>
                          {s?.user?.student
                            ? `${s.user.student?.firstName} ${s.user.student?.lastName}`
                            : `${s.user?.username}`}
                          <br />
                          <span className="text-12">
                            {s.user?.student?.emailAddress}
                          </span>{" "}
                        </td>
                        <td>{s.orderItems?.length}</td>
                        <td>{s.totalCost}</td>
                        <td>{s.paidOnline ? "Yes" : "No"}</td>
                        <td>
                          <span
                            className="text-danger font-500 pointer"
                            onClick={() => {
                              setState({
                                ...state,
                                activeOrder: s,
                                openOrderModal: true,
                              });
                            }}
                          >
                            Details
                          </span>
                        </td>
                      </tr>
                    ))}
                    {orderData && (
                      <tr>
                        <td colSpan={6}>
                          <PaginationBar
                            page={paginateOrders.page}
                            pageSize={paginateOrders.pageSize}
                            totalItems={orderData?.count}
                            onChangePage={(newPage) => {
                              setPaginateOrders({
                                ...paginate,
                                page: newPage,
                              });
                            }}
                            onChangeRowsPerPage={(size) => {
                              setPaginateOrders({
                                pageSize: size,
                                page: 0,
                              });
                            }}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
      </PageLayout>
    </div>
  );
};

export default withRouter(BooksPage);
