import { withRouter } from "react-router-dom"
import React, {useGlobal, useState,useEffect} from "reactn"
import PageLayout from "../../components/page-layout/page-layout.component"
import Button from "../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import { Row,Col } from "react-bootstrap";
import queryString from "query-string"
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';


const DocumentPage =(props)=>{
    const [currentUser] = useGlobal("currentUser");
    const [dxx]=useGlobal("dxx")

    const params=queryString.parse(window.location?.search)

    const [state, setState] = useState({
        query: "",
        status: "",
    });

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [zoom, setZoom]=useState(1)
    
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
    const onChangePage=(p)=>{
      if(p<1 || p>numPages) return;
     
      setPageNumber(p)
    }

    //const decodedURL=Buffer.from(params?.url, 'base64').toString()
    const [url,setUrl]=useState(null);

    useEffect(()=>{
      if(!dxx){
        props.history.goBack()
      }else{
        setUrl(Buffer.from(dxx, 'base64').toString())
      }

    },[])

    return(
      <PageLayout activeLink="home"
        parentMenu="home"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}>

            <div className="mt-20" 
              onContextMenu={(e)=> e.preventDefault()}
            >
              <div className="text-30 mt-20 font-500">{params?.title} </div>
              <div className="text-20 text-grey mb-20">{params?.description}</div>
              
              {url &&<div>
                <div style={{
                  maxWidth: "700px",
                  overflowX: "auto",
                }}>
                
                    <Document 
                      file={url} 
                      onSourceError={(e)=>console.log(e)}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page
                        size="A4"
                        pageNumber={pageNumber} 
                        renderAnnotationLayer={true}
                        renderInteractiveForms={true}
                      />
                    </Document>
                </div>
                <div className="d-flex justify-content-around mt-20 mb-50" style={{maxWidth:"500px"}}>
                  <div>
                    <span className="pointer font-500 mx-2" onClick={()=>onChangePage(pageNumber - 1)}>&lt;Previous</span> |
                    <span className="pointer font-500 mx-2" onClick={()=>onChangePage(pageNumber + 1)}>Next&gt;</span>
                  </div>
                </div>
              </div>}
            </div>

        </PageLayout>
    )
}


export default withRouter(DocumentPage)