import { withRouter } from "react-router-dom"
import React, {useGlobal, useState,useEffect} from "reactn"
import PageLayout from "../../components/page-layout/page-layout.component"
import Button from "../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import { Row,Col } from "react-bootstrap";
import AdvancedButton from "../../components/advanced-button/advanced-button.component";
import queryString from "query-string"

const ProgramPage =(props)=>{
    const [currentUser] = useGlobal("currentUser");
    const params=queryString.parse(window.location?.search)
    
    const [state, setState] = useState({
        query: "",
        status: "",
        tab:""
    });

    useEffect(() => {
        setState({...state, tab: params?.tab || ""})
        
       
    }, [window.location.search,props.history])

    
    return(
        <PageLayout activeLink="programs"
        parentMenu="programs"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}>

            <div style={{maxWidth: "620px"}}>
            {state.tab?.length===0 &&<div>
                <div className="text-30 font-500">{params?.program || "My Registered"} Program</div>
                <div className="text-20 text-grey mb-20">Select studying material to begin studying what you <br/> need</div>
                <div className="row">
                    <Col md={12}>
                        <AdvancedButton title="Media" onClick={()=>props.history.push(`/program?tab=media`)}/>
                        <AdvancedButton title="Summary notes" onClick={()=>props.history.push(`/program?tab=notes`)}/>
                    </Col>
                </div>
            </div>}

            {state.tab === "media" &&<div>
                <div className="text-30 font-500">Media</div>
                <div className="text-20 text-grey mb-20">Select material to begin studying what you need</div>
                <div className="row">
                    <Col md={12}>
                        <AdvancedButton title="Videos" onClick={()=>props.history.push(`/program?tab=videos`)}/>
                        <AdvancedButton title="Audios" onClick={()=>props.history.push(`/program?tab=audios`)}/>
                    </Col>
                </div>
            </div>}

            {state.tab === "videos" &&<div>
                <div className="text-30 font-500">Videos</div>
                <div className="text-20 text-grey mb-20">Select preferred lecture video to start watching</div>
                <div className="row">
                    <Col md={12}>
                        <AdvancedButton title="Learning the elements of audits" titleBold leftIcon="Video" hideRightButton
                         onClick={()=>props.history.push(`/media?type=video&url=`)}/>
                        <AdvancedButton title="Intoduction to Audit" titleBold leftIcon="Video" hideRightButton
                         onClick={()=>props.history.push(`/media?type=video&url=`)}/>
                        <AdvancedButton title="How to finalize advanced audits" titleBold leftIcon="Video" hideRightButton 
                        onClick={()=>props.history.push(`/media?type=video&url=`)}/>
                        <AdvancedButton title="Actual steps to advance audit 101" titleBold leftIcon="Video" hideRightButton
                         onClick={()=>props.history.push(`/media?type=video&url=`)}/>
                    </Col>
                </div>
            </div>}

            {state.tab === "audios" &&<div>
                <div className="text-30 font-500">Audio</div>
                <div className="text-20 text-grey mb-20">Select preferred lecture audio to start watching</div>
                <div className="row">
                    <Col md={12}>
                       <AdvancedButton title="Introduction to advanced audit 101" titleBold leftIcon="Audio" hideRightButton
                         onClick={()=>{
                             //props.history.push(`/media?type=audio&url=`)
                        }}/>
                        <AdvancedButton title="Learning the elements of audits" titleBold leftIcon="Audio" hideRightButton
                         onClick={()=>{
                             //props.history.push(`/media?type=audio&url=`)
                            }}/>
                        <AdvancedButton title="How to finalize advanced audits" titleBold leftIcon="Audio" hideRightButton 
                        onClick={()=>{
                            //props.history.push(`/media?type=audio&url=`)
                        }}/>
                    </Col>
                </div>
            </div>}

            {state.tab === "notes" &&<div>
                <div className="text-30 font-500">Summary notes</div>
                <div className="text-20 text-grey mb-20">Select preferred lecture note to start reading</div>
                <div className="row">
                    <Col md={12}>
                        <AdvancedButton title="Introduction to advanced audit 101" titleBold leftIcon="File" hideRightButton
                         onClick={()=>props.history.push(`/media?type=note&url=`)}/>
                        <AdvancedButton title="Actual steps to advance audit 101" titleBold leftIcon="File" hideRightButton
                         onClick={()=>props.history.push(`/media?type=note&url=`)}/>
                        <AdvancedButton title="Learning the elements of audits" titleBold leftIcon="File" hideRightButton 
                        onClick={()=>props.history.push(`/media?type=note&url=`)}/>
                        <AdvancedButton title="How to finalize advanced audits" titleBold leftIcon="File" hideRightButton
                         onClick={()=>props.history.push(`/media?type=note&url=`)}/>
                    </Col>
                </div>
            </div>}
            </div>
        </PageLayout>
    )
}


export default withRouter(ProgramPage)