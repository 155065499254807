import React, { Fragment } from "react";

const PlayStoreIcon = ({
  variant = "default", //active
  color = "white",
  size,
  viewBox = "0 0 35 35",
}) => {
  const _color =
    variant === "default"
      ? "#3F3F3F"
      : variant === "active"
      ? "#FFFFFF"
      : color;
  return (
    <svg
      width={size || 35}
      height={size || 35}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Fragment>
        <path
          d="M2.59953 1.28906C2.18429 1.83674 1.95312 2.51045 1.95312 3.23143V31.7683C1.95312 32.438 2.15325 33.0671 2.51472 33.5926L18.0723 17.3968L2.59953 1.28906Z"
          fill={_color}
        />
        <path
          d="M19.496 15.9136L24.5431 10.6595L6.80201 0.43688C6.032 -0.00692186 5.14854 -0.112131 4.32422 0.119116L19.496 15.9136Z"
          fill={_color}
        />
        <path
          d="M19.497 18.8794L4.17188 34.8333C4.5023 34.9441 4.84423 34.9999 5.18643 34.9999C5.7416 34.9999 6.29704 34.8541 6.80298 34.5625L24.6717 24.2662L19.497 18.8794Z"
          fill={_color}
        />
        <path
          d="M31.5651 14.7052L26.3783 11.7163L20.9219 17.3963L26.5057 23.2095L31.5651 20.2941C32.5775 19.7109 33.1819 18.666 33.1819 17.4996C33.1819 16.333 32.5775 15.2884 31.5651 14.7052Z"
          fill={_color}
        />
      </Fragment>
    </svg>
  );
};

export default PlayStoreIcon;
