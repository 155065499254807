import { useState ,useGlobal} from "reactn";
import { Modal, Row, Col, Badge } from "react-bootstrap";
import { useEffect } from "reactn";
import { deleteData, postData, putData } from "../../../api/api";
import Button from "../../button/button.component";
import SwitchButton from "../../form/toggle-button/toggle-button.component";
import InputField from "../../form/input-field/input-field.component";
import Select from "../../form/select/select.component";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { toast } from "react-toastify";
import useSWR from "swr";

const CopyResourceModal = ({
  open,
  currentUser,
  resource,
  //subject,
  //subjects=[],
  onHide,
  type = "add",
  onBlurHide = false,
  onUpdated = () => {},
}) => {

  const [selectedSubjects, setSelectedSubjects]=useGlobal("selectedSubjects")
  const [state, setState] = useState({
    action: type,
    processing: false,
    error: null,
    success: null,
  });

  const [_resource, _setResource] = useState({
    type:"",
    sSubject:"",
    dSubject:"",
    sFolder:"",
    dFolder:""
  });


  const reset = () => {
    setState({
      action: type,
      processing: false,
      error: null,
      success: null,
    });
  };

  const processResource = (action) => {
    setState({
      ...state,
      processing: true,
      error: null,
    });

    let endpoint="tuition/resource/copy";

    let subIds=[];
    selectedSubjects?.map((s)=>{
      subIds.push(s.id)
    })
    
    let data={
      resourceType: _resource.type,
      resourceDescription: _resource.description,
      resourceUrl: _resource.url,
      sourceFolder: _resource?.folder,
      destinationFolder: _resource.dFolder,
      sourceSubjectID: _resource.subject.id,
      destinationSubjectIDs: subIds
    }
    
    console.log(data)
  
    const request = postData(endpoint, data , currentUser?.token);

    request.then((resp) => {
      if (resp.success) {
        onUpdated(_resource?.type);
        setState({ ...state, processing: false, error: null });
        onHide();
        toast.success("Resource updated.")
        return;
      }
      setState({
        ...state,
        processing: false,
        error:
          resp?.message ||
          `Unable to update Resource, Please fill all fields and check your internet connection.`,
      });
    });
  };

  const folderOptions=()=>{
    let l=[{label:"Lecture Audios", value:"Lecture Audios"}]
    if(_resource?.type==="Video"){
      l=[{label:"Lecture Videos",value:"Lecture Videos"}, {label:"Revision Videos",value:"Revision Videos"}]
    }else if(_resource?.type==="PDF"){
       l=[
         {label:"Summary Notes",value:"Summary Notes"}, 
         {label:"Special Notes",value:"Special Notes"}, 
         {label:"Questions Bank",value:"Questions Bank"},
        {label:"ICAG Past Questions",value:"ICAG Past Questions"}
        ]
    }
    return l
  }

  const isValid=()=>(_resource?.type && _resource?.dFolder )

  const handleChange = (e) => {
    _setResource({
      ..._resource,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setState({ ...state, error: null });
    if (resource) {
      _setResource({
         ...resource,
         type: resource?.mediaType || resource?.documentType, 
         folder: resource?.mediaFolder || resource?.documentFolder,
         url: resource?.mediaURL || resource?.documentURL,
         dFolder: resource?.mediaFolder || resource?.documentFolder,
        }); 
    } else {
      _setResource(null);
    }
  }, [resource, open, type]);

  return (
    <div>
      <Modal
        //className="custom-modal"
        show={open}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <div className="f-width d-flex space-between">
            <div className="title">Copy Resource</div>
            <div
              onClick={() => {
                if (state.processing) return;
                reset();
                onHide();
              }}
            >
              <Close />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          <div className="row mb-50 mt-20">
            <div className="col-md-12">
              <div className="text-light">Source Subject</div>
              <div className="">{_resource?.subject?.name}</div>
            </div>
            <div className="col-md-12 mt-10">
              <div className="text-light">File Description</div>
              <div className="">{_resource?.description}</div>
            </div>
            <div className="col-md-12 mt-10">
              <div className="text-light">File URL</div>
              <div className="">{_resource?.url}</div>
            </div>
            <div className="col-md-6 mt-10">
              <div className="text-light">File Type</div>
              <div className="">{_resource?.type}</div>
            </div>
            <div className="col-md-6 mt-10">
              <div className="text-light">Folder</div>
              <div className="">{_resource?.folder}</div>
            </div>
            <hr className="mt-10"/>
            <div className="col-md-6 mt-10">
              <Select placeholder="Select Destination folder"
              small
              label={"Destination Folder"}
              options={folderOptions()} 
              value={{label:_resource?.dFolder, value: _resource?.dFolder}}
              onChange={(selected)=>{
                  _setResource({..._resource, dFolder: selected.value})
              }}
              />
            </div>
            <div className="col-md-12 mt-10">
            <div className="text-light">Destination Subject(s):</div> 
              {selectedSubjects?.map((s,i)=>(<Badge key={i} className="mx-1">{s.name} </Badge>))}
            </div>
           
          </div>
        </Modal.Body>

        <Modal.Footer>
          {state.error && (
            <div className="text-danger text-right mb-20">{state?.error}</div>
          )}
          <div className="flex flex-end">
            {type !== "view" && (
              <Button
                classes="small danger"
                loading={state.processing}
                disabled={state.processing || !isValid()}
                onClick={processResource}
              >
                Copy
              </Button>
            )}

          {/*   {type == "view" && (
              <Button
                classes="small"
                disabled={state.processing}
                onClick={onEdit}
              >
                Edit
              </Button>
            )} */}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CopyResourceModal;