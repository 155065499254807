import React, { useGlobal, useState, useEffect } from "reactn";
import { withRouter } from "react-router-dom";
import PageLayout from "../../../components/page-layout/page-layout.component";
import ConfirmModal from "../../../components/modal/confirm-modal/confirm-modal.component";
import Button from "../../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";
import { deleteData } from "../../../api/api";
import { Row, Col, Table } from "react-bootstrap";
import SubjectModal from "../../../components/modal/subject-modal/subject-modal.component";
import SwitchButton from "../../../components/form/toggle-button/toggle-button.component";
import Select from "../../../components/form/select/select.component";
import MoveResourceModal from "../../../components/modal/move-resource-modal/move-resource-modal.component";
import { toast } from "react-toastify";
import useAccessLevels from "../../../hooks/useAccessLevels";

const SubjectsPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [, setSelectedSubjects] = useGlobal("selectedSubjects");
  const [, setCurrentSubject] = useGlobal("currentSubject");

  const { hasAccess } = useAccessLevels(currentUser?.user?.accessLevels);
  const access = hasAccess("Subjects");

  const options = [
    { label: "ICAG", value: 1 },
    { label: "CTIG", value: 2 },
    { label: "Executive Training", value: 3 },
    { label: "CIMA", value: 4 },
  ];
  const [state, setState] = useState({
    query: "",
    active: true,
    status: "",
    loading: true,
    openModal: false,
    openCopyModal: false,
    activeSubject: null,
    isIntervention: false,
    isModular: false,
    isOffline: false,
    programID: 1,
  });

  const [subs, setSubs] = useState([]);
  const isChecked = (id) => {
    if (subs?.find((item) => item.id === id)) return true;
    return false;
  };

  const onCheckChange = (item) => {
    let c = isChecked(item.id);
    if (c) {
      let n = subs?.filter((s) => s.id !== item.id);
      setSubs([...n]);
    } else {
      setSubs([...subs, item]);
    }
  };

  const { data, error, mutate, isValdating } = useSWR(
    `tuition/v2/subject?query=${state?.query}&isIntervention=${state.isIntervention}&isModular=${state.isModular}&isOffline=${state.isOffline}&programID=${state?.programID}&price=0`
  );

  return (
    <div>
      <SubjectModal
        open={state?.openModal}
        currentUser={currentUser}
        subject={state.activeSubject}
        onHide={() => {
          setState({ ...state, openModal: false, activeSubject: null });
        }}
        onUpdated={() => {
          mutate();
        }}
        access={access}
      />

      <MoveResourceModal
        open={state.openCopyModal}
        currentUser={currentUser}
        subjects={data?.subjects}
        onHide={() => {
          setState({ ...state, openCopyModal: false });
        }}
      />

      <PageLayout
        activeLink="subjects"
        parentMenu="subjects"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}
      >
        <div className="mt-3">
          <div className="text-24">
            {" "}
            Subjects
            {access?.write && (
              <span
                className="text-danger mx-3 pointer text-18 font-500"
                onClick={() => {
                  setState({ ...state, openModal: true });
                }}
              >
                Add new Subject
              </span>
            )}{" "}
            |
            {access?.write && (
              <span
                className="text-danger mx-3 pointer text-16 font-500"
                onClick={() => {
                  setState({ ...state, openCopyModal: true });
                }}
              >
                Move Subject Resources
              </span>
            )}
          </div>

          <div className="row mt-20 mb-20">
            <div className="col-md-3">
              <Select
                placeholder="Select folder"
                small
                label={"Filter by Program"}
                options={options}
                value={options.find((e) => e.value == state?.programID)}
                onChange={(selected) => {
                  setState({ ...state, programID: selected.value });
                }}
              />
            </div>
            <div className="col-md-3">
              <Select
                placeholder="Select sitting type"
                small
                label={"Sitting type"}
                options={[
                  { label: "Tuition", value: "" },
                  { label: "Intervention", value: "isIntervention" },
                  { label: "Modular", value: "isModular" },
                  { label: "Offline", value: "isOffline" },
                ]}
                value={{
                  label: state?.isIntervention
                    ? "Intervention"
                    : state?.isModular
                    ? "Modular"
                    : state?.isOffline
                    ? "Offline"
                    : "Tuition",
                  value: state?.isIntervention
                    ? "isIntervention"
                    : state?.isModular
                    ? "isModular"
                    : state?.isOffline
                    ? "isOffline"
                    : null,
                }}
                onChange={(selected) => {
                  let sub = {
                    ...state,
                    isIntervention: false,
                    isModular: false,
                    isOffline: false,
                  };
                  if (selected.value) sub[[selected.value]] = true;
                  setState({ ...sub });
                }}
              />
            </div>
            {/*  <div className="col-md-2 mt-10">
              <SwitchButton
                label={"Switch to Intervntion"}
                checked={state?.isIntervention}
                onChange={(value) => {
                  setState({ ...state, isIntervention: value });
                }}
              />
            </div> */}
          </div>

          <LoaderPane
            loading={isValdating || (!data && !error)}
            noRecords={!isValdating && data?.subjects?.length === 0}
            error={error?.message}
          />
          {access?.write && subs?.length > 0 && (
            <div>
              <span
                className="text-danger pointer"
                onClick={() => {
                  setSelectedSubjects([...subs]);
                  toast.success(`${subs?.length} subjects copied.`);
                }}
              >
                Done{" "}
              </span>{" "}
              &nbsp;|
              {/*  <span className="mx-2 text-danger pointer" onClick={()=>setSubs([])}>Clear Selection </span> */}
              <div className="text-light text-14">
                {subs?.length} subjects selected
              </div>
            </div>
          )}
          {data && (
            <div>
              <Table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Level</th>
                    <th>Price</th>
                    <th>Links</th>
                    <th>Type</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.subjects?.map((s, i) => (
                    <tr key={i}>
                      <td>
                        <input
                          type={"checkbox"}
                          value={subs?.find((item) => item.id === s.id)}
                          onChange={() => {
                            onCheckChange(s);
                          }}
                        />
                        {s.id}
                      </td>
                      <td>{s.name}</td>
                      <td>{s.level}</td>
                      <td>{s.price}</td>
                      <td>
                        {s?.whatsappLink && (
                          <a
                            className="mx-2"
                            rel="noreferrer"
                            target={"_blank"}
                            href={s?.whatsappLink}
                          >
                            WhatsApp
                          </a>
                        )}
                        {s?.meetingLink && (
                          <a
                            rel="noreferrer"
                            target={"_blank"}
                            href={s?.meetingLink}
                          >
                            Zoom/Meeting Link
                          </a>
                        )}
                        {!s.whatsappLink && !s.meetingLink && "No Links"}
                      </td>
                      <td>
                        {s?.isIntervention
                          ? "Intervention"
                          : s?.isModular
                          ? "Modular"
                          : s?.isOffline
                          ? "Offline"
                          : "Tuition"}
                      </td>
                      <td>
                        {access?.write && (
                          <span
                            className="text-danger font-500 pointer"
                            onClick={() => {
                              setState({
                                ...state,
                                activeSubject: s,
                                openModal: true,
                              });
                            }}
                          >
                            Edit
                          </span>
                        )}

                        <span
                          className="text-danger mx-3 font-500 pointer"
                          onClick={() => {
                            setCurrentSubject(s);
                            props.history.push(`/admin/subjects/${s.id}`);
                          }}
                        >
                          View
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </PageLayout>
    </div>
  );
};

export default withRouter(SubjectsPage);
