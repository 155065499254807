import styled from "styled-components";

export const Wrapper=styled.div`
    border: 1px solid rgba(63, 63, 63, 0.2);
    background: ${props=>props.transparent ?"transparent":"#FFFFFF"};
    box-sizing: border-box;
    border-radius: 10px;
    transition: border-color 0.6s linear;
    margin-bottom: 20px;
    padding: 17px 30px ;
    :hover{
      border-color:  #D82427;
    }
`

export const TopContent=styled.div`
    display: flex;
`
export const Title=styled.div`
  flex:1;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
`
export  const RigthButton=styled.div`
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    
    :hover{
        opacity:0.8;
    }
`

export  const InnerContent=styled.div`
  padding-bottom: 15px;
`


