import React from "react";
import HomeIcon from "./components/home/home.icon";
import ProgramsIcon from "./components/programs/programs.icon";
import ChevronIcon from "./components/chevron/chevron.icon";
import BellIcon from "./components/bell/bell.icon";
import SavedIcon from "./components/saved/saved.icon";
import ArrowRightIcon from "./components/arrow-right/arrow-right.icon";
import PlayStoreIcon from "./components/playstore/playstore.icon";
import AppStoreIcon from "./components/appstore/appstore.icon";
import VideoIcon from "./components/video/video.icon";
import AudioIcon from "./components/audio/audio.icon";
import FileIcon from "./components/file/file.icon";
import BusketIcon from "./components/busket/busket.icon";

const Icons = {
  Home: HomeIcon,
  Programs: ProgramsIcon,
  Chevron: ChevronIcon,
  Bell: BellIcon,
  Save: SavedIcon,
  ArrowRight: ArrowRightIcon,
  PlayStore: PlayStoreIcon,
  AppStore: AppStoreIcon,
  Video: VideoIcon,
  Audio: AudioIcon,
  File: FileIcon,
  Busket: BusketIcon,
};

export default Icons;
