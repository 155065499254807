import styled from "styled-components";

export const Wrapper=styled.div`
    border: 1px solid rgba(63, 63, 63, 0.2);
    background: ${props=>props.transparent ?"transparent":"#FFFFFF"};
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    transition: border-color 0.6s linear;
    margin-bottom: 15px;
    min-height: 66px;
    cursor: pointer;

    :hover{
      border-color:  #D82427;
    }
`

export  const RigthButton=styled.div`
    background: ${props=>props.flat ? "transparent" : props.disabled ? "#C4C4C4": "#D82427"};
    border-radius: 0px 10px 10px 0px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid  ${props=> props.flat ? "transparent" : props=>props.disabled ? "#C4C4C4" : "#D82427"};

    :hover{
        opacity:0.8;
    }
`

export  const LeftIcon=styled.div`
  margin: auto 0px auto 20px
`
export  const Title=styled.div`
  font-weight: ${props=>props.titleBold ? "600": "500"};
  font-size: ${props=>props.titleBold ? "21px": "20px"};
  line-height: 30px;
  color: #3F3F3F;
`

export  const InnerContent=styled.div`
  flex: 1;
  width: 100%;
  padding: 18px 20px;
`


