import { useGlobal, useEffect, useState } from "reactn";
import "./App.scss";
import {
  Routes,
  Route,
  Redirect,
  Switch,
  BrowserRouter,
} from "react-router-dom";

import AppRoutes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSWR, { SWRConfig, trigger } from "swr";
import { SWRFetcher, http } from "./api/api";

import LoginPage from "./pages/login/login.page";
import SignupPage from "./pages/signup/signup.page";
import ResetPasswordPage from "./pages/reset-password/reset-password.page";
import HomePage from "./pages/home/home.page";
import ProgramRegistrationPage from "./pages/program-registration/program-registration.page";
import ExecutiveRegistrationPage from "./pages/program-registration/executive/executive-registration.page";
import JobPortalPage from "./pages/job-portal/job-portal.page";
import JobPage from "./pages/job-portal/job/job.page";
import ProgramPage from "./pages/programs/view-program/view-program.page";
//import AuthenticatedRoutes from "./authenticated";
import MediaPage from "./pages/media/media.page";
import ProgramsPage from "./pages/programs/programs.page";
//import ICAGProgramPage from "./pages/programs/ICAG/icag.page";
//import CITGProgramPage from "./pages/programs/view-program/view-program.page";
import ExecutiveTrainingPage from "./pages/programs/executive-training/executive-training.page";
import AccountPage from "./pages/account/account.page";
import UpdatesPage from "./pages/updates/updates.page";
import BookStorePage from "./pages/book-store/book-store.page";
import DocumentPage from "./pages/document/document.page";
import devtools from "devtools-detect";
//admin
import SittingsPage from "./pages/admin/sittings/sittings.page";
import SubjectsPage from "./pages/admin/subjects/subjects.page";
import ViewSittingPage from "./pages/admin/sittings/view/view-sitting.page";
import StudentsPage from "./pages/admin/students/students.page";
import ViewSubjectPage from "./pages/admin/subjects/view/view-subject.page";
import BooksPage from "./pages/admin/books/books.page";
import AdminJobPortalPage from "./pages/admin/job-portal/job-portal.page";
import AdminJobPage from "./pages/admin/job-portal/job/job.page";
import AdminSettingsPage from "./pages/admin/settings/settings.page";
import PaystackPage from "./pages/payment/paystack.page";
import TheTellerPage from "./pages/payment/theteller.page";
import ComplaintsPage from "./pages/admin/complaints/complaints.page";
import AdminUsersPage from "./pages/admin/users/users.page";
import AdminPaymentsPage from "./pages/admin/payments/payments.page";
import StudentSittingsPage from "./pages/admin/student-sittings /student-sittings.page";

function App(props) {
  const [currentUser] = useGlobal("currentUser");

  window.oncontextmenu = function (e) {
    if (process.env.NODE_ENV === "production") {
      e.preventDefault();
    }
  };
  const [opened, setOpened] = useState(false);
  useEffect(() => {
    setOpened(true);
  }, [currentUser]);

  const checkDev = (event) =>
    event.detail.isOpen ? setOpened(true) : setOpened(false);

  window.addEventListener("devtoolschange", (event) => {
    if (process.env.NODE_ENV === "production") {
      checkDev(event);
    }
    //console.log("DevTools orientation:", event.detail.orientation);
  });

  useEffect(() => {
    //props.history.replace(window.history.href);
  }, [currentUser]);

  return (
    <SWRConfig
      value={{
        fetcher: SWRFetcher(currentUser?.token),
        refreshInterval: 0,
        errorRetryCount: 1,
        revalidateOnFocus: false,
        onError: (error, key) => {
          if (error == "Error: Request failed with status code 401") {
            //localStorage.clear();
            //window.location.href = "/login";
          }
        },
      }}
    >
      {opened && (
        <BrowserRouter>
          <Switch>
            <Route
              path="/"
              exact
              render={() =>
                currentUser ? (
                  currentUser?.user?.isSuperUser ? (
                    <Redirect to="/admin/sittings" />
                  ) : (
                    <HomePage />
                  )
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route
              path="/login"
              exact
              render={() =>
                currentUser ? <Redirect to="/home" /> : <LoginPage />
              }
            />
            <Route path="/signup" component={SignupPage} />
            <Route path="/reset-password" component={ResetPasswordPage} />
            <Route
              path="/home"
              render={() =>
                currentUser ? (
                  currentUser?.user?.isSuperUser ? (
                    <Redirect to="/admin/sittings" />
                  ) : (
                    <HomePage />
                  )
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route path="/job/:job" component={JobPage} />
            <Route path="/job-portal" component={JobPortalPage} />
            <Route
              path="/executive-program-registration"
              component={ExecutiveRegistrationPage}
            />
            <Route
              path="/program-registration/:tag"
              render={() =>
                currentUser ? (
                  <ProgramRegistrationPage />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route exact path="/programs" component={ProgramsPage} />
            <Route
              path="/programs/:tag"
              render={() =>
                currentUser ? <ProgramPage /> : <Redirect to="/login" />
              }
            />
            {/* icag,citg */}
            {/* <Route exact path="/programs/citg" component={CITGProgramPage} /> */}
            <Route
              exact
              path="/programs/executive-training"
              component={ExecutiveTrainingPage}
            />
            <Route
              exact
              path="/document"
              render={() =>
                currentUser ? <DocumentPage /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/media"
              render={() =>
                currentUser ? <MediaPage /> : <Redirect to="/login" />
              }
            />
            <Route exact path="/account" component={AccountPage} />
            <Route exact path="/updates" component={UpdatesPage} />
            <Route exact path="/book-store" component={BookStorePage} />
            {currentUser?.user?.isSuperUser && (
              <>
                <Route exact path="/admin/sittings" component={SittingsPage} />
                <Route
                  exact
                  path="/admin/sittings/:id"
                  component={ViewSittingPage}
                />
                <Route
                  exact
                  path="/admin/student-registrations"
                  component={StudentSittingsPage}
                />
                <Route exact path="/admin/subjects" component={SubjectsPage} />
                <Route
                  exact
                  path="/admin/subjects/:id"
                  component={ViewSubjectPage}
                />
                <Route exact path="/admin/students" component={StudentsPage} />
                <Route exact path="/admin/book-store" component={BooksPage} />
                <Route
                  exact
                  path="/admin/job-portal"
                  component={AdminJobPortalPage}
                />
                <Route exact path="/admin/job/view" component={AdminJobPage} />
                <Route exact path="/admin/job/new" component={AdminJobPage} />
                <Route
                  exact
                  path="/admin/settings"
                  component={AdminSettingsPage}
                />
                <Route
                  exact
                  path="/admin/complaints"
                  component={ComplaintsPage}
                />
                <Route
                  exact
                  path="/admin/payments"
                  component={AdminPaymentsPage}
                />
                <Route exact path="/admin/users" component={AdminUsersPage} />
              </>
            )}
            <Route path="/paystack" component={PaystackPage} />
            <Route path="/theteller" component={TheTellerPage} />
          </Switch>
        </BrowserRouter>
      )}
      <ToastContainer />
    </SWRConfig>
  );
}

export default App;
