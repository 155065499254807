import React, { useGlobal, useState, useEffect } from "reactn";
import { withRouter } from "react-router-dom";
import PageLayout from "../../../../components/page-layout/page-layout.component";
import Button from "../../../../components/button/button.component";
import useSWR from "swr";
import LoaderPane from "../../../../components/loader-pane/loader-pane.component";
import { deleteData, postData, putData } from "../../../../api/api";
import { Row, Col, Table } from "react-bootstrap";

import Select from "../../../../components/form/select/select.component";
import { toast } from "react-toastify";
import queryString from "query-string";
import InputField from "../../../../components/form/input-field/input-field.component";
import Editor, {
  convertToHtml,
  convertToMarkdown,
} from "../../../../components/form/editor/editor.component";
import useAccessLevels from "../../../../hooks/useAccessLevels";

const AdminJobPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [currentJob, setCurrentJob] = useGlobal("currentJob");

  const [state, setState] = useState({
    query: "",
    status: "",
    loading: false,
    openModal: false,
    processing: false,
  });
  const [paginate, setPaginate] = useState({
    page: 0, // index 0, add +1
    pageSize: 25,
  });

  const sittingID = props.match.params.id;

  const parsed = queryString.parse(props.location.search);
  const name = parsed?.name;

  const [job, setJob] = useState(null);

  useEffect(() => {
    if (currentJob) {
      setJob({
        ...currentJob,
        deadline: currentJob?.deadline?.split("T")[0],
        link: currentJob?.applyLink || currentJob?.applyEmail,
      });
    }
  }, []);
  const isvalid = () => job?.title && job?.link;

  const processJob = () => {
    setState({ ...state, processing: true });
    let data = {
      ...job,
      deadline: `${job.deadline}T00:00:00Z`,
      description: convertToMarkdown(job?.description || " "),
      applyEmail: job?.link?.includes("http") ? null : job.link,
      applyLink: job?.link?.includes("http") ? job.link : null,
      salary: parseFloat(job?.salary || 0),
    };
    console.log(data);
    const request = job?.id
      ? putData(`job/${job.id}`, data, currentUser.token)
      : postData("job", data, currentUser?.token);
    request.then((resp) => {
      if (resp.success) {
        toast.success("Job updated.");
        setState({ ...state, processing: false });
        setCurrentJob(null, () => {
          props.history.goBack();
        });
        return;
      }
      setState({ ...state, processing: false });
      toast.error(resp.message || "Unable to update Job");
    });
  };

  const handleChange = (e) => {
    setJob({ ...job, [e.target.name]: e.target.value });
  };

  const { hasAccess } = useAccessLevels(currentUser?.user?.accessLevels);
  const access = hasAccess("Job-portal");

  return (
    <div>
      <PageLayout
        activeLink="job-portal"
        parentMenu="job-portal"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}
      >
        <div className="mt-3">
          <div className="text-24">Job</div>
          <div>
            <Row>
              <Col md={6}>
                <InputField
                  classes="mb-20"
                  value={job?.title}
                  label={"Job title"}
                  name="title"
                  onChange={handleChange}
                />
              </Col>
              <Col md={6}>
                <InputField
                  classes="mb-20"
                  value={job?.company}
                  label={"Company"}
                  name="company"
                  onChange={handleChange}
                />
              </Col>
              <Col md={6}>
                <InputField
                  classes="mb-20"
                  value={job?.location}
                  label={"Location"}
                  name="location"
                  onChange={handleChange}
                />
              </Col>
              <Col md={6}>
                <InputField
                  classes="mb-20"
                  value={job?.link}
                  label={"Link or Email"}
                  name="link"
                  placeholder="eg. https://link.com or job@mail.com"
                  onChange={handleChange}
                />
              </Col>
              <Col>
                <Select
                  label={"Job type"}
                  classes="mb-20"
                  value={
                    job?.jobType
                      ? { label: job?.jobType, value: job?.jobType }
                      : null
                  }
                  options={[
                    { label: "Full-time", value: "Full-time" },
                    { label: "Part-time", value: "Part-time" },
                    { label: "Contract", value: "Contract" },
                  ]}
                  onChange={(selected) => {
                    setJob({ ...job, jobType: selected.value });
                  }}
                />
              </Col>
              <Col md={6}>
                <InputField
                  classes="mb-20"
                  value={job?.deadline || null}
                  label={"Deadline"}
                  name="deadline"
                  type="date"
                  onChange={handleChange}
                />
              </Col>
              <Col md={6}>
                <InputField
                  classes="mb-20"
                  value={job?.salary}
                  label={"Salary"}
                  name="salary"
                  type="number"
                  min={0}
                  onChange={handleChange}
                />
              </Col>
              <Col md={12}>
                <Editor
                  label={"Job Description"}
                  // initialValue={convertToHtml(job?.description || " ")}
                  appendTo="body"
                  value={convertToHtml(job?.description)}
                  height={350}
                  onEditorChange={(value) => {
                    setJob({ ...job, description: value });
                  }}
                />
              </Col>
              <Col md={8} />
              <Col md={4}>
                {access?.write && (
                  <Button
                    classes={"small mt-20 danger w-100"}
                    disabled={state.processing || !isvalid()}
                    loading={state.processing}
                    onClick={processJob}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </PageLayout>
    </div>
  );
};

export default withRouter(AdminJobPage);
