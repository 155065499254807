import { useState, useGlobal } from "reactn";
import { Modal, Row, Col } from "react-bootstrap";
import { useEffect } from "reactn";
import { deleteData, postData, putData } from "../../../api/api";
import Button from "../../button/button.component";
import SwitchButton from "../../form/toggle-button/toggle-button.component";
import InputField from "../../form/input-field/input-field.component";
import Select from "../../form/select/select.component";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { toast } from "react-toastify";

const SittingModal = ({
  open,
  currentUser,
  sitting,
  onHide,
  type = "add",
  onBlurHide = false,
  onEdit = () => {},
  onDelete = () => {},
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    action: type,
    processing: false,
    error: null,
    success: null,
  });

  const [_sitting, _setSitting] = useState({
    startAt: "",
    endAt: "",
    programID: "",
    description: "",
    postSitting: false,
  });

  const reset = () => {
    setState({
      action: type,
      processing: false,
      error: null,
      success: null,
    });
  };

  const processsitting = (action) => {
    setState({
      ...state,
      processing: true,
      error: null,
    });

    const data = {
      ..._sitting,
      description: _sitting?.description,
      startAt: `${_sitting.startAt}T00:00:00Z`,
      endAt: `${_sitting.endAt}T00:00:00Z`,
      postSitting: _sitting?.postSitting,
    };

    //return;
    const request =
      type === "edit" || sitting?.id
        ? putData(
            `tuition/sitting/${_sitting?.id}`,
            {
              ...data,
            },
            currentUser?.token
          )
        : postData(`tuition/sitting`, { ...data }, currentUser?.token);

    request.then((resp) => {
      if (resp.success) {
        setState({ ...state, processing: false, error: null });
        onHide();
        onUpdated(resp.data.sitting);
        toast.success("Sitting updated.");
        return;
      }
      toast.error("An error occurred!");
      setState({
        ...state,
        processing: false,
        error:
          resp?.message ||
          `Unable to update Sitting, Please fill all fields and check your internet connection.`,
      });
    });
  };

  const handleChange = (e) => {
    _setSitting({
      ..._sitting,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setState({ ...state, error: null });
    if (sitting) {
      _setSitting({
        ...sitting,
        startAt: sitting?.startAt.split("T")[0],
        endAt: sitting?.endAt.split("T")[0],
        // postSitting: sitting?.postSitting || false,
      });
    } else {
      _setSitting(null);
    }
  }, [sitting, open, type]);

  return (
    <div>
      <Modal
        //className="custom-modal"
        show={open}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <div className="f-width d-flex space-between">
            <div className="title">Sitting</div>
            <div
              onClick={() => {
                if (state.processing) return;
                reset();
                onHide();
              }}
            >
              <Close />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          <div className="row mb-50 mt-20" hidden={type === "view"}>
            <div className="col-md-12 mb-20">
              <InputField
                classes="small"
                value={_sitting?.description}
                label="Description"
                name="description"
                placeholder="Enter description"
                type="text"
                onChange={(e) => {
                  _setSitting({
                    ..._sitting,
                    description: `${e.target.value}`,
                  });
                }}
              />
            </div>
            <div className="col-md-6 mb-20">
              <InputField
                classes="small"
                value={_sitting?.startAt}
                label="Start date"
                name="startAt"
                placeholder="Start date"
                type="date"
                onChange={(e) => {
                  _setSitting({
                    ..._sitting,
                    startAt: `${e.target.value}`,
                  });
                }}
              />
            </div>
            <div className="col-md-6 mb-20">
              <InputField
                classes="small"
                type="date"
                value={_sitting?.endAt}
                label="End date"
                name="endAt"
                placeholder="End date"
                onChange={(e) => {
                  _setSitting({
                    ..._sitting,
                    endAt: `${e.target.value}`,
                  });
                }}
              />
            </div>

            <div className="col-md-6 mb-20">
              <Select
                value={
                  sitting || _sitting?.programID
                    ? {
                        label:
                          _sitting?.programID === 1
                            ? "ICAG"
                            : _sitting?.programID === 2
                            ? "CITG"
                            : _sitting?.programID === 3
                            ? "Executive Training"
                            : _sitting?.programID === 4
                            ? "CIMA"
                            : "",
                        value: _sitting?.programID,
                      }
                    : null
                }
                placeholder="Select Program"
                small
                label={"Program"}
                options={[
                  { label: "ICAG", value: 1 },
                  { label: "CTIG", value: 2 },
                  { label: "Executive Training", value: 3 },
                  { label: "CIMA", value: 4 },
                ]}
                //value={}
                onChange={(selected) => {
                  _setSitting({ ..._sitting, programID: selected.value });
                }}
              />
            </div>
            {/* <div className="col-md-6 mb-20 pt-3">
              <SwitchButton
                label={"Post Sitting"}
                checked={_sitting?.postSitting}
                onChange={(value) => {
                  _setSitting({ ..._sitting, postSitting: value });
                }}
              />
            </div> */}
          </div>

          {/**view */}

          {type === "view" && (
            <div className="row">
              <div className="col-md-6">
                <div className="text-15 text-grey-light">ID</div>
                <div className="text-15 text-grey mb-10">{_sitting?.id}</div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Description</div>
                <div className="text-15 text-grey mb-10">
                  {_sitting?.description}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Start</div>
                <div className="text-15 text-grey mb-10">
                  {_sitting?.startAt}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">End</div>
                <div className="text-15 text-grey  mb-10">
                  {_sitting?.endAt}
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-15 text-grey-light">Program</div>
                <div className="text-15 text-grey mb-10">
                  {_sitting?.programID}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          {state.error && (
            <div className="text-danger text-right mb-20">{state?.error}</div>
          )}
          <div className="flex flex-end">
            {type !== "view" && (
              <Button
                classes="small danger"
                loading={state.processing}
                disabled={
                  state.processing ||
                  _sitting?.lastName?.length === 0 ||
                  _sitting?.firstName?.length === 0
                }
                onClick={processsitting}
              >
                Save
              </Button>
            )}

            {/*   {type == "view" && (
              <Button
                classes="small"
                disabled={state.processing}
                onClick={onEdit}
              >
                Edit
              </Button>
            )} */}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SittingModal;
