import React, { useGlobal, useState, useEffect } from "reactn";
import { withRouter } from "react-router-dom";
//import { withRouter } from "react-router";
import InputField from "../../components/form/input-field/input-field.component";
import Button from "../../components/button/button.component";
import "./login.styles.scss";
import { ReactComponent as Logo } from "../../assets/icons/big-logo.svg";
import { authenticate } from "../../api/services/account.service";
import { toast } from "react-toastify";
import { postData } from "../../api/api";

const LoginPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");

  const [state, setState] = useState({
    processing: false,
    error: null,
    username: "",
    password: "",
  });

const onLogout = (token) => {
        postData(`user/logout`, {}, token).then((resp) => {
        });
    };

  const login = () => { 
    setState({ ...state, processing: true, error: null });
    authenticate(state.username, state.password).then((response) => {
      //console.log(response);
      if (response.success) {
        setState({ ...state, processing: false });
        if(!response.user?.user?.isSuperUser){
          toast.error("Admin access only")
          onLogout(response.user?.token)
          return;
        } 
        setCurrentUser(response.user);
        props.history.push(`/home`)
        return;
      }
      setState({
        ...state,
        processing: false,
        error: response.message || "Unable to login.",
      });
    });
  };


  useEffect(() => {
    //if(currentUser){props.history.push(`/home`)}
    //console.log(currentUser)
   
  }, [])

  return (
    <div className="login-page">
      <div className="inner">
      <div className="row m-0 p-0">
        <div className="col-md-6 left-pane">
          <div>
            <div className="logo-div">
             <Logo />
            </div>
            <div className="left-header">
              ASPIRE PRO CONSULT
            </div>
          </div>
        </div>
        <div className="col-md-6 right-pane">
          <div className="right-pane-inner">
            <div className="text-12">
            </div>
             <div hidden className="text-30 font-600 mb-20">
                Please download Aspire Executive student app.
              </div>
            <div >
              <div className="text-30 font-600 mb-20">
                Signin to your <br />
                account
              </div>
              <InputField
                name="username"
                value={state.username}
                label=""
                placeholder="Email"
                onChange={(e) => setState({ ...state, username: e.target.value })}
              />
              <InputField
                classes="mt-20"
                name="password"
                value={state.password}
                label=""
                placeholder="Password"
                type="password"
                onChange={(e) => setState({ ...state, password: e.target.value })}
              />
              <div className="text-danger mt-15 text-10  pointer text-right mb-30" onClick={()=>{props.history.push("/reset-password")}}>
                Forgot password?
              </div>
              {state.error && (
                <div className="text-danger mb-20 text-center">{state.error}</div>
              )}
              <Button
                classes="f-width danger"
                disabled={
                state.processing ||
                  state.username.length == 0 ||
                  state.password.length == 0
                }
                loading={state.processing}
                onClick={()=>login()}
              >
                Login
              </Button>
            </div>

            <div hidden className="text-center mt-40" onClick={()=>props.history.push("/signup")}>Don't have an account? <span className="text-danger pointer">Sign up</span> </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default  withRouter( LoginPage);
