import React ,{ useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import Button from "../../../components/button/button.component";

const ConfirmModal = ({
  open,
  onHide,
  title,
  description,
  success = null,
  processing = false,
  error = null,
  onConfirm = () => {},
  onBlurHide = false,
}) => {
  const [state, setState] = useState({});

  return (
    <div>
      <Modal
        className="modal-small"
        show={open}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <div className="title">{title}</div>
        </Modal.Header>
        <Modal.Body className="scrollbar mb-20">
          <div className="">
            {!success && <div className="text-grey">{description}</div>}

            {success && !processing && (
              <div className="mt-10 text-success text-center">{success}</div>
            )}

            {error && (!processing || !success) && (
              <div className="mt-10 text-danger text-center">{error}</div>
            )}
          </div>
          <div className="d-flex flex-end f-width mt-30">
            <Button
              classes="small secondary outline mx-2"
              hidden={success}
              loading={processing}
              disabled={processing}
              onClick={onConfirm}
            >
              Confirm
            </Button>

            <Button classes="small danger" disabled={processing} onClick={onHide}>
              Close
            </Button>
          </div>
        </Modal.Body>

        <Modal.Footer hidden></Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
