import React, { Fragment } from "react";

const ProgramsIcon = ({
  variant = "default", //active
  color = "white",
  size = 27,
  viewBox = "0 0 27 27",
}) => {
  const _color =
    variant === "default"
      ? "#3F3F3F"
      : variant === "active"
      ? "#FFFFFF"
      : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Fragment>
        <path
          d="M7.875 0H4.5C3.30653 0 2.16193 0.474106 1.31802 1.31802C0.474106 2.16193 0 3.30653 0 4.5L0 7.875C0 9.06848 0.474106 10.2131 1.31802 11.057C2.16193 11.9009 3.30653 12.375 4.5 12.375H7.875C9.06848 12.375 10.2131 11.9009 11.057 11.057C11.9009 10.2131 12.375 9.06848 12.375 7.875V4.5C12.375 3.30653 11.9009 2.16193 11.057 1.31802C10.2131 0.474106 9.06848 0 7.875 0V0ZM10.125 7.875C10.125 8.47174 9.88795 9.04403 9.46599 9.46599C9.04403 9.88795 8.47174 10.125 7.875 10.125H4.5C3.90326 10.125 3.33097 9.88795 2.90901 9.46599C2.48705 9.04403 2.25 8.47174 2.25 7.875V4.5C2.25 3.90326 2.48705 3.33097 2.90901 2.90901C3.33097 2.48705 3.90326 2.25 4.5 2.25H7.875C8.47174 2.25 9.04403 2.48705 9.46599 2.90901C9.88795 3.33097 10.125 3.90326 10.125 4.5V7.875Z"
          fill={_color}
        />
        <path
          d="M22.5 0H19.125C17.9315 0 16.7869 0.474106 15.943 1.31802C15.0991 2.16193 14.625 3.30653 14.625 4.5V7.875C14.625 9.06848 15.0991 10.2131 15.943 11.057C16.7869 11.9009 17.9315 12.375 19.125 12.375H22.5C23.6935 12.375 24.8381 11.9009 25.682 11.057C26.5259 10.2131 27 9.06848 27 7.875V4.5C27 3.30653 26.5259 2.16193 25.682 1.31802C24.8381 0.474106 23.6935 0 22.5 0V0ZM24.75 7.875C24.75 8.47174 24.5129 9.04403 24.091 9.46599C23.669 9.88795 23.0967 10.125 22.5 10.125H19.125C18.5283 10.125 17.956 9.88795 17.534 9.46599C17.1121 9.04403 16.875 8.47174 16.875 7.875V4.5C16.875 3.90326 17.1121 3.33097 17.534 2.90901C17.956 2.48705 18.5283 2.25 19.125 2.25H22.5C23.0967 2.25 23.669 2.48705 24.091 2.90901C24.5129 3.33097 24.75 3.90326 24.75 4.5V7.875Z"
          fill={_color}
        />
        <path
          d="M7.875 14.625H4.5C3.30653 14.625 2.16193 15.0991 1.31802 15.943C0.474106 16.7869 0 17.9315 0 19.125L0 22.5C0 23.6935 0.474106 24.8381 1.31802 25.682C2.16193 26.5259 3.30653 27 4.5 27H7.875C9.06848 27 10.2131 26.5259 11.057 25.682C11.9009 24.8381 12.375 23.6935 12.375 22.5V19.125C12.375 17.9315 11.9009 16.7869 11.057 15.943C10.2131 15.0991 9.06848 14.625 7.875 14.625V14.625ZM10.125 22.5C10.125 23.0967 9.88795 23.669 9.46599 24.091C9.04403 24.513 8.47174 24.75 7.875 24.75H4.5C3.90326 24.75 3.33097 24.513 2.90901 24.091C2.48705 23.669 2.25 23.0967 2.25 22.5V19.125C2.25 18.5283 2.48705 17.956 2.90901 17.534C3.33097 17.1121 3.90326 16.875 4.5 16.875H7.875C8.47174 16.875 9.04403 17.1121 9.46599 17.534C9.88795 17.956 10.125 18.5283 10.125 19.125V22.5Z"
          fill={_color}
        />
        <path
          d="M22.5 14.625H19.125C17.9315 14.625 16.7869 15.0991 15.943 15.943C15.0991 16.7869 14.625 17.9315 14.625 19.125V22.5C14.625 23.6935 15.0991 24.8381 15.943 25.682C16.7869 26.5259 17.9315 27 19.125 27H22.5C23.6935 27 24.8381 26.5259 25.682 25.682C26.5259 24.8381 27 23.6935 27 22.5V19.125C27 17.9315 26.5259 16.7869 25.682 15.943C24.8381 15.0991 23.6935 14.625 22.5 14.625V14.625ZM24.75 22.5C24.75 23.0967 24.5129 23.669 24.091 24.091C23.669 24.513 23.0967 24.75 22.5 24.75H19.125C18.5283 24.75 17.956 24.513 17.534 24.091C17.1121 23.669 16.875 23.0967 16.875 22.5V19.125C16.875 18.5283 17.1121 17.956 17.534 17.534C17.956 17.1121 18.5283 16.875 19.125 16.875H22.5C23.0967 16.875 23.669 17.1121 24.091 17.534C24.5129 17.956 24.75 18.5283 24.75 19.125V22.5Z"
          fill={_color}
        />
      </Fragment>
    </svg>
  );
};

export default ProgramsIcon;
