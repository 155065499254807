import React, { useState } from "react";

import { Wrapper, RigthButton, InnerContent , Title,LeftIcon } from "./advanced-button.styles";
import Icons from "../../assets/icons";
const AdvancedButton = ({
  children,
  title,
  leftIcon,
  titleBold=false,
  hideRightButton=false,
  disabled=false,
  loading=false,
  buttonIcon,
  onClick=()=>{}
}) => {
  const Icon={
    Video:<Icons.Video/>,
    Audio:<Icons.Audio/>,
    File:<Icons.File/>
  }
  
  return (
    <div>
    <Wrapper onClick={()=>{
      if(disabled) return
       onClick()
      }}>
      {leftIcon &&<LeftIcon>
        {Icon[[leftIcon]]}
      </LeftIcon>}
      <InnerContent>
       <Title titleBold={titleBold}>{title}</Title>
      </InnerContent>
      {!hideRightButton &&<RigthButton  disabled={disabled}>
          {<Icons.ArrowRight variant="active"/> }
      </RigthButton>}
    </Wrapper>
    
    </div>
  );
};

export default AdvancedButton;
