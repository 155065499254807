import React, { useGlobal, useState, useEffect } from "reactn";
import { withRouter } from "react-router-dom";
import PageLayout from "../../../components/page-layout/page-layout.component";
import useSWR from "swr";
import LoaderPane from "../../../components/loader-pane/loader-pane.component";

import { Row, Col, Table } from "react-bootstrap";
import PaginationBar from "../../../components/pagination-bar/pagination-bar.component";
import useAccessLevels from "../../../hooks/useAccessLevels";

const AdminPaymentsPage = (props) => {
  const [currentUser, setCurrentUser] = useGlobal("currentUser");
  const [state, setState] = useState({
    activeTab: 0,
    query: "",
    status: "",
    loading: true,
    openModal: false,
    activeBook: null,
    activeOrder: null,
    openOrderModal: false,
  });

  const [paginate, setPaginate] = useState({
    page: 0, // index 0, add +1
    pageSize: 20,
  });

  const { hasAccess } = useAccessLevels(currentUser?.user?.accessLevels);
  const access = hasAccess("Payments");

  const limit = access.write ? paginate.pageSize : 3;
  const offset = access?.write ? paginate.page * paginate.pageSize : 0;

  const [canFetch, setCanFetch] = useState(false);

  useEffect(() => {
    if (access?.write) {
      setCanFetch(true);
    }
  }, [access]);
  const [readOnly, setReadOnly] = useState(null);

  const { data, error, mutate, isValdating } = useSWR(
    canFetch &&
      `payment?query=${state?.query}&email=${
        access.write ? "" : state?.query
      }&offset=${offset}&limit=${limit}`,
    {
      onSuccess: (data) => {
        if (!access.write) {
          setReadOnly({ ...data });
          setCanFetch(false);
        }
      },
    }
  );

  return (
    <div>
      <PageLayout
        activeLink="payments"
        parentMenu="payments"
        inputProps={{
          value: state.query,
          onChange: (e) => {
            setState({ ...state, query: e.target.value });
          },
        }}
      >
        <div className="mt-3">
          <div className="text-24 mb-20 d-flex gap-2">
            Payments{" "}
            <div>
              {state.query?.length > 0 && (
                <span
                  className="font-bold text-18 text-danger pointer"
                  onClick={() => {
                    setCanFetch(true);
                  }}
                >
                  Load Payments
                </span>
              )}
            </div>
          </div>

          <div>
            {canFetch && (
              <LoaderPane
                loading={isValdating || (!data && !error)}
                noRecords={!isValdating && data?.payments?.length === 0}
                error={error?.message}
              />
            )}

            {(data || readOnly) && (
              <div>
                <Table>
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Ref</th>
                      <th>Description</th>
                      <th>Amount</th>
                      <th>Provider</th>
                      <th>Source</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.payments?.map((s, i) => (
                      <tr key={i}>
                        <td>
                          {s?.emailAddress}
                          <div className="text-12">
                            {new Date(s.createdAt).toDateString()}
                          </div>
                        </td>
                        <td>{s.ref}</td>
                        <td>{s.description}</td>
                        <td>{s.amount}</td>
                        <td>{s.provider}</td>
                        <td>{s.source}</td>
                        <td>
                          <span
                            className={` ${
                              s.status?.toLowerCase() == "success"
                                ? "bg-success"
                                : s.status?.toLowerCase() == "failed"
                                ? "bg-danger"
                                : "bg-primary"
                            } text-12  p-1 rounded text-white`}
                          >
                            {s.status}
                          </span>
                        </td>
                      </tr>
                    ))}
                    {readOnly?.payments?.map((s, i) => (
                      <tr key={i}>
                        <td>
                          {s?.emailAddress}
                          <div className="text-12">
                            {new Date(s.createdAt).toDateString()}
                          </div>
                        </td>
                        <td>{s.ref}</td>
                        <td>{s.description}</td>
                        <td>{s.amount}</td>
                        <td>{s.provider}</td>
                        <td>{s.source}</td>
                        <td>
                          <span
                            className={` ${
                              s.status?.toLowerCase() == "success"
                                ? "bg-success"
                                : s.status?.toLowerCase() == "failed"
                                ? "bg-danger"
                                : "bg-primary"
                            } text-12  p-1 rounded text-white`}
                          >
                            {s.status}
                          </span>
                        </td>
                      </tr>
                    ))}
                    {access.write && (
                      <tr>
                        <td colSpan={7}>
                          <PaginationBar
                            page={paginate.page}
                            pageSize={paginate.pageSize}
                            totalItems={
                              data?.complaints?.length >= paginate.pageSize
                                ? paginate.pageSize * (paginate.page + 2)
                                : paginate.pageSize * (paginate.page + 1)
                            }
                            onChangePage={(newPage) => {
                              setPaginate({
                                ...paginate,
                                page: newPage,
                              });
                            }}
                            onChangeRowsPerPage={(size) => {
                              setPaginate({
                                pageSize: size,
                                page: 0,
                              });
                            }}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
      </PageLayout>
    </div>
  );
};

export default withRouter(AdminPaymentsPage);
