import React from "react";

const SavedIcon = ({
  variant = "default", //active
  color = "white",
  size,
  viewBox = "0 0 25 27",
}) => {
  const _color =
    variant === "default"
      ? "#3F3F3F"
      : variant === "active"
      ? "#FFFFFF"
      : color;
  return (
    <svg
      width={size || 25}
      height={size || 27}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6543 27C21.2381 26.9989 20.8263 26.9153 20.4426 26.754C20.0589 26.5928 19.711 26.3571 19.4189 26.0606L12.5001 19.1824L5.58139 26.0651C5.13704 26.5159 4.56719 26.8224 3.94611 26.9446C3.32503 27.0669 2.68153 26.9991 2.09951 26.7503C1.51172 26.5138 1.00888 26.1056 0.656654 25.579C0.304431 25.0524 0.119196 24.4318 0.125139 23.7983V5.625C0.125139 4.13316 0.717771 2.70242 1.77266 1.64752C2.82755 0.592632 4.2583 0 5.75014 0L19.2501 0C19.9888 0 20.7203 0.145495 21.4027 0.428178C22.0852 0.71086 22.7053 1.12519 23.2276 1.64752C23.7499 2.16985 24.1643 2.78995 24.447 3.47241C24.7296 4.15486 24.8751 4.88631 24.8751 5.625V23.7983C24.8814 24.4313 24.6969 25.0515 24.3455 25.5781C23.9941 26.1046 23.4922 26.5131 22.9053 26.7503C22.509 26.916 22.0837 27.0009 21.6543 27ZM5.75014 2.25C4.85503 2.25 3.99659 2.60558 3.36365 3.23852C2.73072 3.87145 2.37514 4.72989 2.37514 5.625V23.7983C2.37473 23.9857 2.42989 24.1691 2.53365 24.3252C2.63741 24.4814 2.78511 24.6032 2.9581 24.6755C3.13109 24.7477 3.32161 24.767 3.50558 24.731C3.68956 24.6951 3.85874 24.6053 3.99176 24.4733L11.7126 16.7996C11.9234 16.5901 12.2086 16.4725 12.5058 16.4725C12.803 16.4725 13.0881 16.5901 13.2989 16.7996L21.0108 24.471C21.1438 24.6031 21.313 24.6928 21.4969 24.7288C21.6809 24.7648 21.8714 24.7454 22.0444 24.6732C22.2174 24.601 22.3651 24.4791 22.4689 24.323C22.5726 24.1668 22.6278 23.9835 22.6274 23.796V5.625C22.6274 4.72989 22.2718 3.87145 21.6389 3.23852C21.0059 2.60558 20.1475 2.25 19.2524 2.25H5.75014Z"
        fill={_color}
      />
    </svg>
  );
};

export default SavedIcon;
