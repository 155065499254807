import { useState, useGlobal } from "reactn";
import { Modal, Row, Col } from "react-bootstrap";
import { useEffect } from "reactn";
import { deleteData, postData, putData } from "../../../api/api";
import Button from "../../button/button.component";
import SwitchButton from "../../form/toggle-button/toggle-button.component";
import InputField from "../../form/input-field/input-field.component";
import Select from "../../form/select/select.component";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { toast } from "react-toastify";

const OrderOrderModal = ({
  open,
  currentUser,
  order,
  onHide,
  access,
  type = "add",
  onBlurHide = false,
  onEdit = () => {},
  onDelete = () => {},
  onUpdated = () => {},
}) => {
  const [state, setState] = useState({
    action: type,
    processing: false,
    error: null,
    success: null,
  });

  const [_order, _setOrder] = useState({});

  const reset = () => {
    setState({
      action: type,
      processing: false,
      error: null,
      success: null,
    });
  };

  const processOrder = (action) => {
    //actions=[confirm, cancel, delivery/confirm]
    setState({
      ...state,
      processing: true,
      error: null,
    });

    const request = putData(
      `bookstore/order/${_order?.id}/${action}`,
      null,
      currentUser?.token
    );

    request.then((resp) => {
      if (resp.success) {
        setState({ ...state, processing: false, error: null });
        onHide();
        onUpdated(resp.data);
        toast.success("Order updated.");
        return;
      }
      setState({
        ...state,
        processing: false,
        error:
          resp?.message ||
          `Unable to update Order, Please check your internet connection.`,
      });
    });
  };

  useEffect(() => {
    setState({ ...state, error: null });
    if (order) {
      _setOrder({ ...order });
    } else {
      _setOrder(null);
    }
  }, [order, open, type]);

  return (
    <div>
      <Modal
        //className="custom-modal"
        show={open}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <div className="f-width d-flex space-between">
            <div className="title">Order</div>
            <div
              onClick={() => {
                if (state.processing) return;
                reset();
                onHide();
              }}
            >
              <Close />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          {/**view */}

          {_order && (
            <div>
              <div className="row">
                <div className="col-md-6">
                  <div className="text-15 text-grey-light">Date</div>
                  <div className="text-15 text-grey mb-10">
                    {new Date(_order?.createdAt).toDateString()}
                  </div>
                </div>
                {_order?.user?.student && (
                  <div className="col-md-6">
                    <div className="text-15 text-grey-light">User</div>
                    <div className="text-15 text-grey mb-10">
                      {_order?.user?.student
                        ? `${_order?.user?.student.firstName} ${_order?.user?.student.lastName}`
                        : `${_order?.user?.username}`}
                      <br />
                    </div>
                  </div>
                )}
                {_order?.user?.student && (
                  <div className="col-md-6">
                    <div className="text-15 text-grey-light">Contact</div>
                    <div className="text-15 text-grey mb-10">
                      {`${_order?.user?.student.emailAddress}`}
                      <br />
                      <span className="text-14">
                        {_order?.user?.student.phoneNumber}
                      </span>
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="text-15 text-grey-light">Total Cost</div>
                  <div className="text-15 text-grey  mb-10">
                    GHS {_order?.totalCost}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-15 text-grey-light">Paid Oline</div>
                  <div className="text-15 text-grey mb-10">
                    {_order?.paidOnline ? "Yes" : "No"}
                  </div>
                </div>

                {_order?.confirmedAt && (
                  <div className="col-md-6">
                    <div className="text-15 text-grey-light">
                      Date Confirmed
                    </div>
                    <div className="text-15 text-grey  mb-10">
                      {new Date(_order?.confirmedAt).toDateString()}
                    </div>
                  </div>
                )}
                {_order?.address && (
                  <div className="col-md-6">
                    <div className="text-15 text-grey-light">
                      Delivery Address
                    </div>
                    <div className="text-15 text-grey mb-10">
                      {_order?.address}
                    </div>
                  </div>
                )}
                {_order?.note && (
                  <div className="col-md-6">
                    <div className="text-15 text-grey-light">Note</div>
                    <div className="text-15 text-grey mb-10">
                      {_order?.note}
                    </div>
                  </div>
                )}
                {_order?.deliveredAt && (
                  <div className="col-md-6">
                    <div className="text-15 text-grey-light">
                      Date Delivered
                    </div>
                    <div className="text-15 text-grey mb-10">
                      {new Date(_order?.deliveredAt).toDateString()}
                    </div>
                  </div>
                )}
                {_order?.cancelledAt && (
                  <div className="col-md-6">
                    <div className="text-15 text-grey-light">
                      Date Cancelled
                    </div>
                    <div className="text-15 text-grey mb-10">
                      {new Date(_order?.cancelledAt).toDateString()}
                    </div>
                  </div>
                )}
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Book</th>
                    <th>Quantity</th>
                    <th>Unit Cost</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {_order?.orderItems?.map((item) => (
                    <tr>
                      <td>
                        {item.book.title} <br /> {item.book?.tag}
                      </td>
                      <td>{item.quantity}</td>
                      <td>{item.unitCost}</td>
                      <td>{item.totalCost}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          {state.error && (
            <div className="text-danger text-right mb-20">{state?.error}</div>
          )}
          <div className="flex flex-end">
            {!_order?.confirmedAt && !_order?.cancelledAt && (
              <Button
                classes="small danger mx-2"
                loading={state.processing}
                disabled={state.processing || !access?.write}
                onClick={() => processOrder("confirm")}
              >
                Confirm Order
              </Button>
            )}
            {_order?.confirmedAt && !_order?.deliveredAt && (
              <Button
                classes="small danger mx-2 outline"
                loading={state.processing}
                disabled={state.processing || !access?.write}
                onClick={() => processOrder("delivery/confirm")}
              >
                Confirm Delivery
              </Button>
            )}
            {_order?.confirmedAt && !_order?.deliveredAt && (
              <Button
                classes="small danger"
                loading={state.processing}
                disabled={state.processing || !access?.write}
                onClick={() => processOrder("cancel")}
              >
                Cancel Order
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OrderOrderModal;
